<div [hidden]="fromSearch && apartments.length === 0" [ngClass]="{ 'from-search': fromSearch && apartments.length > 0 }">
  <div *ngIf="!condominium" class="page-header page-header-apartments">
    <h2>
      <mat-icon>house</mat-icon>
      {{ 'apartment.header' | translate }}
    </h2>
  </div>
  <div class="card main-card">
    <div *ngIf="!(handsetObserverService.isSmallScreen$ | async)" class="buttons">
      <ng-container *ngTemplateOutlet="buttons"></ng-container>
    </div>

    <mat-accordion *ngIf="handsetObserverService.isSmallScreen$ | async">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'general.actions' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="buttons"></ng-container>
      </mat-expansion-panel>
    </mat-accordion>

    <div class="datatable mt-2">
      <mat-form-field *ngIf="!fromSearch" [floatLabel]="'always'" appearance="outline" class="full-width-search mt-2">
        <mat-label>{{ 'general.search' | translate }}</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input (keyup)="applyFilter($event)" matInput />
      </mat-form-field>

      <ng-template #desktopTable>
        <mat-table [dataSource]="dataSource" [matSortDirection]="'asc'" matSort matSortActive="fullAddress" matSortDisableClear>
          <ng-container matColumnDef="fullAddress">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'apartment.address' | translate }}</mat-header-cell>
            <mat-cell (click)="openers.openApartment(row)" *matCellDef="let row" class="link">{{ row.fullAddress }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="condominium">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'apartment.condominium' | translate }}</mat-header-cell>
            <mat-cell (click)="openers.openCondominium(row.condominium)" *matCellDef="let row" class="link">{{ row.condominium?.name }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="keysFree">
            <mat-header-cell *matHeaderCellDef>{{ 'apartment.keysFree' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ getKeysFree(row.id) }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="open">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell (click)="openers.openApartment(row)" *matCellDef="let row">
              <mat-icon>open_in_new</mat-icon>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsDesktop"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsDesktop"></mat-row>
        </mat-table>
      </ng-template>

      <ng-template #mobileTable>
        <mat-table [dataSource]="dataSource" [matSortDirection]="'asc'" matSort matSortActive="fullAddress" matSortDisableClear multiTemplateDataRows>
          <ng-container matColumnDef="arrow">
            <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
            <mat-cell *matCellDef="let row">
              <mat-icon>{{ expandedElement && expandedElement.id === row.id ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}</mat-icon>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="fullAddress">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'apartment.address' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row.fullAddress }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="open">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell (click)="openers.openApartment(row)" *matCellDef="let row">
              <mat-icon>open_in_new</mat-icon>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td *matCellDef="let element" [attr.colspan]="displayedColumnsMobile.length" mat-cell>
              <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'" class="element-detail">
                <xkey-basic-info-field (click)="openers.openCondominium(element.condominium)" [isClickable]="true" [title]="'apartment.condominium'" [value]="element.condominium?.name"></xkey-basic-info-field>
              </div>
            </td>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsMobile"></mat-header-row>
          <mat-row (click)="expandedElement = expandedElement === element ? null : element" *matRowDef="let element; columns: displayedColumnsMobile" [class.expanded-row]="expandedElement === element" class="element-row"></mat-row>

          <tr
            *matRowDef="let row; columns: ['expandedDetail']"
            [ngClass]="{
              'expanded-detail': expandedElement && expandedElement.id == row.id,
              'closed-detail': !(expandedElement && expandedElement.id == row.id)
            }"
            class="detail-row"
            mat-row
          ></tr>
        </mat-table>
      </ng-template>

      <ng-container *ngIf="handsetObserverService.isHandSet$ | async">
        <ng-template [ngTemplateOutlet]="mobileTable"></ng-template>
      </ng-container>

      <ng-container *ngIf="!(handsetObserverService.isHandSet$ | async)">
        <ng-template [ngTemplateOutlet]="desktopTable"></ng-template>
      </ng-container>

      <div *ngIf="apartments.length === 0" class="no-results">
        {{ 'general.noResults' | translate }}
      </div>

      <mat-paginator #paginator [length]="dataSource.data.length" [pageIndex]="0" [pageSizeOptions]="[25, 50, 100, 250]" [pageSize]="pageSize"> </mat-paginator>
    </div>
  </div>
</div>

<ng-template #buttons>
  <button (click)="openCreateModal(false)" [disabled]="!loginService.userHasPermissions('Apartment_Create')" class="block-on-mobile" color="primary" mat-flat-button>
    <mat-icon>note_add</mat-icon>
    {{ 'apartment.create' | translate }}
  </button>
  <button (click)="openCreateModal(true)" [disabled]="!loginService.userHasPermissions('Apartment_Create')" class="block-on-mobile" color="primary" mat-flat-button>
    <mat-icon>note_add</mat-icon>
    {{ 'apartment.createMultiple' | translate }}
  </button>
</ng-template>
