import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTabsModule } from '@angular/material/tabs';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { HttpClientModule } from '@angular/common/http';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { PaginatorIntlService } from './services/PaginatorIntlService';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import { DynamicScriptLoaderService } from './services/dynamic-script-loader.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSnackBarModule,
    NgxMatSelectSearchModule,
    MatPaginatorModule,
    MatTableModule,
    MatListModule,
    MatSortModule,
    MatTooltipModule,
    MatIconModule,
    MatMomentDateModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatGridListModule,
    MatDatepickerModule,
    MatButtonModule,
    MatExpansionModule,
    HttpClientModule,
    AngularSignaturePadModule,
    LeafletModule,
    NgxFileDropModule,
    MatSlideToggleModule,
    MatDialogModule,
  ],
  providers: [
    DynamicScriptLoaderService,
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { position: { top: '10%' } } },
    { provide: MAT_DATE_LOCALE, useValue: 'fi-FI' },
    {
      provide: MatPaginatorIntl,
      useFactory: (translate) => {
        const service = new PaginatorIntlService();
        service.injectTranslateService(translate);
        return service;
      },
      deps: [TranslateService],
    },
  ],
})
export class SharedModule {}
