import { AbstractControl, ValidatorFn } from '@angular/forms';
import { PhoneNumber, PhoneNumberUtil } from 'google-libphonenumber';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

export function PhoneNumberValidator(regionCode: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null;
    }

    let validNumber = false;
    try {
      const phoneNumber: PhoneNumber = phoneNumberUtil.parseAndKeepRawInput(control.value, regionCode);
      validNumber = phoneNumberUtil.isValidNumber(phoneNumber);
    } catch (error) {}

    return validNumber ? null : { wrongNumber: true };
  };
}
