import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  private changesThatShouldLeadToDashboard = ['condominia>apartments', 'companies>users'];
  private previousUrls: string[] = [];

  constructor(private router: Router) {
    router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.previousUrls.push(event.url);

      // Remove duplicates to prevent looping
      this.previousUrls = [...new Set(this.previousUrls.reverse())].reverse();
    });
  }

  public getPreviousUrl(defaultUrl: string = '') {
    const currentUrl = this.previousUrls.slice(-1)[0];
    const previousUrl = this.previousUrls.slice(-2)[0];

    if (!previousUrl) {
      return defaultUrl || '/etusivu';
    }

    this.previousUrls = this.previousUrls.filter((p) => p !== currentUrl);

    if (this.changesThatShouldLeadToDashboard.includes(`${previousUrl.split('/')[1]}>${currentUrl.split('/')[1]}`)) {
      return '/etusivu';
    }

    return previousUrl;
  }
}

export function initRouterService(routerService: RouterService) {
  return async () => {};
}
