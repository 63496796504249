<ng-container>
  <div class="modal-header">
    <h2 class="modal-title" mat-dialog-title>{{ 'key.changeDueDate' | translate }}</h2>
  </div>
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="formGroup">
      <mat-form-field appearance="fill">
        <input [placeholder]="'key.dueDate' | translate" formControlName="dueDate" id="dueDate" matInput type="datetime-local" />
        <mat-error *ngIf="f.dueDate.errors && f.dueDate.errors.required">{{ 'key.dueDateIsRequired' | translate }}</mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button [mat-dialog-close]="false" color="primary" mat-flat-button>{{ 'general.close' | translate }}</button>
    <button (click)="changeDueDate()" color="accent" mat-flat-button>{{ 'general.save' | translate }}</button>
  </mat-dialog-actions>
</ng-container>
