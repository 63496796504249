import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '../services/loading.service';
import { timer } from 'rxjs';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private static readonly SKIP_LOADING_FLAG = 'X-Skip-Loading';
  private totalRequests = 0;

  constructor(private loadingService: LoadingService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const skipLoading = request.headers.has(LoadingInterceptor.SKIP_LOADING_FLAG);
    if (skipLoading) {
      return next.handle(request);
    }

    this.totalRequests++;
    this.loadingService.setLoading(true);

    return next.handle(request).pipe(
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests === 0) {
          timer(300).subscribe(() => this.loadingService.setLoading(false));
        }
      })
    );
  }
}
