<div class="datatable my-2">
  <h2>{{ user?.fullName }}</h2>

  <mat-form-field [floatLabel]="'always'" appearance="outline" class="full-width-search mt-2">
    <mat-label>{{ 'general.search' | translate }}</mat-label>
    <mat-icon matPrefix>search</mat-icon>
    <input (keyup)="applyFilter($event)" matInput />
  </mat-form-field>

  <ng-template #desktopTable>
    <mat-table [dataSource]="dataSource" [matSortDirection]="'desc'" matSort matSortActive="date" matSortDisableClear>
      <ng-container matColumnDef="apartmentOrCondominium">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'key.apartmentOrCondominium' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span (click)="openers.openApartment(row.key.apartment)" *ngIf="row.key.apartment" class="link"> {{ row.key.apartment.fullAddress }}</span>
          <span (click)="openers.openCondominium(row.key.condominium)" *ngIf="row.key.condominium" class="link"> {{ row.key.condominium.name }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'keyHistory.time' | translate }}</mat-header-cell>
        <mat-cell (click)="openers.openKeyHistory(row)" *matCellDef="let row" class="link">{{ row.date | date: 'dd.MM.y HH:mm:ss' }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="keyId">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'keyHistory.key' | translate }}</mat-header-cell>
        <mat-cell (click)="openers.openKey(row.key)" *matCellDef="let row" class="link">{{ row.key.id }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="keyStamp">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'keyHistory.stamp' | translate }}</mat-header-cell>
        <mat-cell (click)="openers.openKey(row.key)" *matCellDef="let row" class="link">{{ row.key.stamp }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="event">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'keyHistory.event' | translate }}</mat-header-cell>
        <mat-cell (click)="openers.openKeyHistory(row)" *matCellDef="let row" class="link">{{ 'key.' + getEvent(row.event) | translate }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="holder">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'keyHistory.holder' | translate }}</mat-header-cell>
        <mat-cell (click)="openers.openKeyHistory(row)" *matCellDef="let row" class="link">
          <span (click)="openers.openResident(row.resident)" *ngIf="row.resident" class="link"> {{ row.resident.fullName }}</span>
          <span (click)="openers.openUser(row.user)" *ngIf="row.user && row.user" class="link"> {{ row.user.fullName }}</span>
          <span *ngIf="row.other"> {{ row.other.firstName }} {{ row.other.lastName }}<br *ngIf="row.other.email" />{{ row.other.email }}<br *ngIf="row.other.phone" />{{ row.other.phone }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="open">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell (click)="openers.openKeyHistory(row)" *matCellDef="let row">
          <mat-icon>open_in_new</mat-icon>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumnsDesktop"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumnsDesktop"></mat-row>
    </mat-table>
  </ng-template>

  <ng-template #mobileTable>
    <mat-table [dataSource]="dataSource" [matSortDirection]="'desc'" matSort matSortActive="date" matSortDisableClear multiTemplateDataRows>
      <ng-container matColumnDef="arrow">
        <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-icon>{{ expandedElement && expandedElement.id === row.id ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}</mat-icon>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'keyHistory.time' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.date | date: 'dd.MM.y HH:mm:ss' }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="open">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell (click)="openers.openKeyHistory(row)" *matCellDef="let row">
          <mat-icon>open_in_new</mat-icon>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td *matCellDef="let element" [attr.colspan]="displayedColumnsMobile.length" mat-cell>
          <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'" class="element-detail">
            <xkey-basic-info-field [title]="'keyHistory.id'" [value]="element.id"></xkey-basic-info-field>
            <xkey-basic-info-field [title]="'keyHistory.event'" [value]="getEvent(element.event?.toString())"></xkey-basic-info-field>
            <xkey-basic-info-field [title]="'keyHistory.stamp'" [value]="element.stamp?.toString()"></xkey-basic-info-field>
          </div>
        </td>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumnsMobile"></mat-header-row>
      <mat-row (click)="expandedElement = expandedElement === element ? null : element" *matRowDef="let element; columns: displayedColumnsMobile" [class.expanded-row]="expandedElement === element" class="element-row"></mat-row>

      <tr
        *matRowDef="let row; columns: ['expandedDetail']"
        [ngClass]="{ 'expanded-detail': expandedElement && expandedElement.id == row.id, 'closed-detail': !(expandedElement && expandedElement.id == row.id) }"
        class="detail-row"
        mat-row
      ></tr>
    </mat-table>
  </ng-template>

  <ng-container *ngIf="handsetObserverService.isHandSet$ | async">
    <ng-template [ngTemplateOutlet]="mobileTable"></ng-template>
  </ng-container>

  <ng-container *ngIf="!(handsetObserverService.isHandSet$ | async)">
    <ng-template [ngTemplateOutlet]="desktopTable"></ng-template>
  </ng-container>

  <div *ngIf="keyHistories.length === 0" class="no-results">
    {{ 'general.noResults' | translate }}
  </div>

  <mat-paginator #paginator [length]="dataSource.data.length" [pageIndex]="0" [pageSizeOptions]="[10, 25, 50, 100, 250]" [pageSize]="10"> </mat-paginator>
</div>
