import { Injectable } from '@angular/core';
import { GeneralService } from '../../../services/general.service';
import { map, Observable } from 'rxjs';
import { KeyHistory } from '../../../models/keyhistory';

@Injectable({
  providedIn: 'root',
})
export class KeyHistoryService {
  constructor(private general: GeneralService) {}

  getKeyHistories(): Observable<KeyHistory[]> {
    return this.general.get<KeyHistory[]>('key-histories').pipe(map((keyHistories) => keyHistories.map((kh) => new KeyHistory(kh))));
  }

  getKeyHistory(id): Observable<KeyHistory> {
    return this.general.get<KeyHistory>('key-histories/' + id).pipe(map((kh) => new KeyHistory(kh)));
  }
}
