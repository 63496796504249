<ng-container>
  <div class="modal-header">
    <h2 class="modal-title" mat-dialog-title>{{ data.apartment.id ? ('apartment.edit' | translate) : data.multiple ? ('apartment.createMultiple' | translate) : ('apartment.create' | translate) }}</h2>
  </div>
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="form">
      <mat-form-field [matTooltip]="data.multiple ? ('apartment.addressForMultiple' | translate) : ''" appearance="fill">
        <mat-label>{{ 'apartment.address' | translate }}</mat-label>
        <input autocomplete="off" formControlName="addressStreet" matInput placeholder="{{ 'apartment.address' | translate }}" />
        <mat-error *ngIf="f.addressStreet.errors && f.addressStreet.errors.required">{{ 'apartment.addressIsRequired' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="data.multiple" appearance="fill">
        <mat-label>{{ 'apartment.firstNumber' | translate }}</mat-label>
        <input (change)="updateValidity()" autocomplete="off" formControlName="firstNumber" matInput min="1" placeholder="{{ 'apartment.firstNumber' | translate }}" type="number" />
        <mat-error *ngIf="f.firstNumber.errors && f.firstNumber.errors.required">{{ 'apartment.firstNumberIsRequired' | translate }}</mat-error>
        <mat-error *ngIf="f.firstNumber.errors && f.firstNumber.errors.min">{{ 'apartment.minIsOne' | translate }}</mat-error>
        <mat-error *ngIf="f.firstNumber.errors && f.firstNumber.errors.greaterThan">{{ 'apartment.cannotBeGreaterThanLast' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="data.multiple" appearance="fill">
        <mat-label>{{ 'apartment.lastNumber' | translate }}</mat-label>
        <input (change)="updateValidity()" autocomplete="off" formControlName="lastNumber" matInput min="1" placeholder="{{ 'apartment.lastNumber' | translate }}" type="number" />
        <mat-error *ngIf="f.lastNumber.errors && f.lastNumber.errors.required">{{ 'apartment.lastNumberIsRequired' | translate }}</mat-error>
        <mat-error *ngIf="f.lastNumber.errors && f.lastNumber.errors.min">{{ 'apartment.minIsOne' | translate }}</mat-error>
        <mat-error *ngIf="f.lastNumber.errors && f.lastNumber.errors.lessThan">{{ 'apartment.cannotBeSmallerThanFirst' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'apartment.postalCode' | translate }}</mat-label>
        <input autocomplete="off" formControlName="addressPostal" matInput placeholder="{{ 'apartment.postalCode' | translate }}" />
        <mat-error *ngIf="f.addressPostal.errors && f.addressPostal.errors.required">{{ 'apartment.postalCodeIsRequired' | translate }}</mat-error>
        <mat-error *ngIf="f.addressPostal.errors && f.addressPostal.errors.pattern">{{ 'apartment.postalCodeIsInWrongFormat' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'apartment.city' | translate }}</mat-label>
        <input autocomplete="off" formControlName="addressCity" matInput placeholder="{{ 'apartment.city' | translate }}" />
        <mat-error *ngIf="f.addressCity.errors && f.addressCity.errors.required">{{ 'apartment.cityIsRequired' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'apartment.condominium' | translate }}</mat-label>
        <mat-select [formControl]="condominiumCtrl" placeholder="{{ 'apartment.condominium' | translate }}">
          <mat-option>
            <ngx-mat-select-search [formControl]="condominiumFilterCtrl" [noEntriesFoundLabel]="'general.noEntriesFound' | translate" [placeholderLabel]="'general.selectSearch' | translate"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let condominium of filteredCondominia | async" [value]="condominium.id">
            {{ condominium.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="f.condominium.errors && f.condominium.errors.required">{{ 'condominium.condominiumIsRequired' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'apartment.owner' | translate }}</mat-label>
        <mat-select [formControl]="ownerCtrl" placeholder="{{ 'apartment.owner' | translate }}">
          <mat-option>
            <ngx-mat-select-search [formControl]="ownerFilterCtrl" [noEntriesFoundLabel]="'general.noEntriesFound' | translate" [placeholderLabel]="'general.selectSearch' | translate"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let owner of filteredOwners | async" [value]="owner.id">
            {{ owner.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button [mat-dialog-close]="false" color="primary" mat-flat-button>{{ 'general.close' | translate }}</button>
    <button (click)="save()" color="accent" mat-flat-button>{{ 'general.save' | translate }}</button>
  </mat-dialog-actions>
</ng-container>
