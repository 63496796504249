<div>
  <div class="search-bar-container">
    <div class="search-bar">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <mat-icon class="form-control-prefix">search</mat-icon>
          </span>
        </div>
        <input #searchInput (keyup)="updateSearch()" [(ngModel)]="searchPhrase" [placeholder]="'general.search' | translate" class="form-control" type="text" />
      </div>
    </div>
  </div>

  <div *ngIf="searchPageIsOpen" class="search-overlay">
    <div class="search-overlay-content">
      <div class="container">
        <xkey-key [fromSearch]="true" [keys]="keys"></xkey-key>
        <xkey-condominium [condominia]="condominia" [fromSearch]="true"></xkey-condominium>
        <xkey-apartment [apartments]="apartments" [fromSearch]="true"></xkey-apartment>
        <xkey-resident [fromSearch]="true" [residents]="residents"></xkey-resident>
        <xkey-company [companies]="companies" [fromSearch]="true"></xkey-company>
        <xkey-user [fromSearch]="true" [users]="users"></xkey-user>
      </div>
    </div>
  </div>
</div>
