export const environment = {
  production: true,
  serverUrls: {
    'xkey.fi': 'https://xkey-backend-prod-afvpvcd5qa-lz.a.run.app/api/',
    'xkey-frontend-lauri-afvpvcd5qa-lz.a.run.app': 'https://xkey-backend-lauri-afvpvcd5qa-lz.a.run.app/api/',
  },
  domain: 'https://xkey.fi',
  useAnalytics: true,
  googleAnalyticsId: 'G-Z07XC4RMBN',
  logo: 'https://xkey.fi/assets/xkey-clean-small.png',
};
