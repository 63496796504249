import { Component, OnInit, ViewChild } from '@angular/core';
import { LockTypeService } from '../../services/lock-type.service';
import { LockType } from '../../../../models/lockType';
import { LockTypeModalComponent } from '../locktype-modal/lock-type-modal.component';
import { LoginService } from '../../../auth/services/login.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { HandsetObserverService } from '../../../ui/services/handset-observer.service';
import { AnimationTriggerMetadata } from '../../../../helpers/AnimationTriggerMetadata';
import { ConfirmModalComponent } from '../../../ui/components/confirm-modal/confirm-modal.component';
import { SnackService } from '../../../ui/services/snack.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '../../../ui/services/dialog.service';

@Component({
  selector: 'xkey-lock-type',
  templateUrl: './lock-type.component.html',
  styleUrls: ['./lock-type.component.scss'],
  animations: [AnimationTriggerMetadata()],
})
export class LockTypeComponent implements OnInit {
  lockTypes: LockType[] = [];
  dataSource = new MatTableDataSource<LockType>([]);
  displayedColumnsDesktop = ['name', 'actions'];
  displayedColumnsMobile = ['arrow', 'name'];
  expandedElement: LockType | null;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private lockTypeService: LockTypeService,
    public handsetObserverService: HandsetObserverService,
    public loginService: LoginService,
    private translate: TranslateService,
    private snackService: SnackService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.getLockTypes();
  }

  openCreateModifyModal(lockType?: LockType) {
    this.dialogService.showDialog(LockTypeModalComponent, { lockType }, () => {
      this.getLockTypes();
    });
  }

  openDeleteModal(lockTypeId: number) {
    this.dialogService.showDialog(ConfirmModalComponent, { modalClass: 'modal-control-panel', title: this.translate.instant('lockType.deleteLockType') }, () => {
      this.lockTypeService.deleteLockType(lockTypeId).subscribe({
        next: () => {
          this.getLockTypes();
        },
        error: (error) => {
          console.error(error);
          this.snackService.showGeneralError();
        },
      });
    });
  }

  applyFilter($event: KeyboardEvent) {
    const filterValue = ($event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  userHasAccess(lockType: LockType) {
    const userHasRightCompany = lockType.ownerId ? this.loginService.userHasRightCompany(lockType.ownerId) : false;
    const userIsSuperAdmin = this.loginService.userHasPermissions('Super_Admin');

    return userHasRightCompany || userIsSuperAdmin;
  }

  private getLockTypes() {
    this.lockTypeService.getLockTypes().subscribe((result: [LockType]) => {
      this.lockTypes = result;

      this.dataSource = new MatTableDataSource(this.lockTypes);
      this.dataSource.filter = '';
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
}
