<ng-container>
  <div class="modal-header">
    <h2 class="modal-title" mat-dialog-title>{{ data.resident.id ? ('resident.edit' | translate) : ('resident.create' | translate) }}</h2>
  </div>
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="form">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'resident.firstName' | translate }}</mat-label>
        <input formControlName="firstName" matInput placeholder="{{ 'resident.firstName' | translate }}" />
        <mat-error *ngIf="f.firstName.errors && f.firstName.errors.required">{{ 'resident.firstNameIsRequired' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ 'resident.lastName' | translate }}</mat-label>
        <input formControlName="lastName" matInput placeholder="{{ 'resident.lastName' | translate }}" />
        <mat-error *ngIf="f.lastName.errors && f.lastName.errors.required">{{ 'resident.lastNameIsRequired' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ 'resident.email' | translate }}</mat-label>
        <input formControlName="email" matInput placeholder="{{ 'resident.email' | translate }}" type="email" />
        <mat-error *ngIf="f.email.errors && f.email.errors.required">{{ 'resident.emailIsRequired' | translate }}</mat-error>
        <mat-error *ngIf="f.email.errors && f.email.errors.email">{{ 'resident.emailInWrongFormat' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ 'resident.phone' | translate }}</mat-label>
        <input formControlName="phone" matInput placeholder="{{ 'resident.phone' | translate }}" type="text" />
        <mat-error *ngIf="f.phone.errors && f.phone.errors.required">{{ 'resident.phoneIsRequired' | translate }}</mat-error>
        <mat-error *ngIf="f.phone.errors && f.phone.errors.wrongNumber">{{ 'resident.phoneInWrongFormat' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ 'resident.apartment' | translate }}</mat-label>
        <mat-select [formControl]="apartmentCtrl" placeholder="{{ 'resident.apartment' | translate }}">
          <mat-option>
            <ngx-mat-select-search [formControl]="apartmentFilterCtrl" [noEntriesFoundLabel]="'general.noEntriesFound' | translate" [placeholderLabel]="'general.selectSearch' | translate"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let apartment of filteredApartments | async" [value]="apartment.id">
            {{ apartment.fullAddress }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="f.apartment.errors && f.apartment.errors.required">{{ 'resident.apartmentIsRequired' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ 'resident.owner' | translate }}</mat-label>
        <mat-select [formControl]="ownerCtrl" placeholder="{{ 'resident.owner' | translate }}">
          <mat-option>
            <ngx-mat-select-search [formControl]="ownerFilterCtrl" [noEntriesFoundLabel]="'general.noEntriesFound' | translate" [placeholderLabel]="'general.selectSearch' | translate"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let owner of filteredOwners | async" [value]="owner.id">
            {{ owner.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button [mat-dialog-close]="false" color="primary" mat-flat-button>{{ 'general.close' | translate }}</button>
    <button (click)="save()" color="accent" mat-flat-button>{{ 'general.save' | translate }}</button>
  </mat-dialog-actions>
</ng-container>
