import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CookieModule } from 'ngx-cookie';
import { XTranslateModule } from './modules/x-translate/translate.module';
import { SharedModule } from './modules/shared/shared.module';
import { AnnouncementModule } from './modules/announcement/announcement.module';
import { ApartmentModule } from './modules/apartment/apartment.module';
import { CondominiumModule } from './modules/condominium/condominium.module';
import { ResidentModule } from './modules/resident/resident.module';
import { CompanyModule } from './modules/company/company.module';
import { KeyModule } from './modules/key/key.module';
import { InvitationModule } from './modules/invitation/invitation.module';
import { AuditModule } from './modules/audit/audit.module';
import { ControlPanelModule } from './modules/control-panel/control-panel.module';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { UserModule } from './modules/user/user.module';
import { UiModule } from './modules/ui/ui.module';
import { RoutingModule } from './modules/routing/routing.module';
import { AuthModule } from './modules/auth/auth.module';
import { SearchBarModule } from './modules/search-bar/search-bar.module';
import { LoadSpinnerModule } from './modules/load-spinner/load-spinner.module';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { CustomisationsModule } from './modules/customisations/customisations.module';

@NgModule({
  declarations: [AppComponent, PrivacyPolicyComponent],
  imports: [
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    CookieModule.withOptions(),
    SharedModule,
    XTranslateModule,
    AnnouncementModule,
    ApartmentModule,
    CondominiumModule,
    ResidentModule,
    CompanyModule,
    KeyModule,
    InvitationModule,
    AuditModule,
    ControlPanelModule,
    DashboardModule,
    UserModule,
    UiModule,
    RoutingModule,
    AuthModule,
    SearchBarModule,
    LoadSpinnerModule,
    CustomisationsModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
