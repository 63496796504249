import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from './services/user.service';
import { UserComponent } from './components/user/user.component';
import { UserSingleComponent } from './components/user-single/user-single.component';
import { XTranslateModule } from '../x-translate/translate.module';
import { SharedModule } from '../shared/shared.module';
import { MyPageComponent } from './components/my-page/my-page.component';
import { ChangePasswordModalComponent } from './components/change-password-modal/change-password-modal.component';
import { ModifyUserModalComponent } from './components/modify-user-modal/modify-user-modal.component';
import { UiModule } from '../ui/ui.module';
import { CondominiumModule } from '../condominium/condominium.module';
import { KeyModule } from '../key/key.module';

@NgModule({
  declarations: [UserComponent, UserSingleComponent, MyPageComponent, ChangePasswordModalComponent, ModifyUserModalComponent],
  imports: [CommonModule, XTranslateModule, SharedModule, UiModule, CondominiumModule, KeyModule],
  providers: [UserService],
  exports: [UserComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UserModule {}
