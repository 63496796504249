import { Injectable } from '@angular/core';
import { GeneralService } from '../../../services/general.service';
import { LockType } from '../../../models/lockType';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LockTypeService {
  constructor(private general: GeneralService) {}

  getLockTypes(): Observable<LockType[]> {
    return this.general.get('lock-types');
  }

  deleteLockType(id): Observable<void> {
    return this.general.delete('lock-types/' + id);
  }

  updateLockType(lockType: LockType): Observable<LockType> {
    return this.general.update('lock-types', lockType);
  }

  createLockType(lockType: LockType): Observable<LockType> {
    return this.general.post('lock-types', lockType);
  }

  upsertLockType(lockType: LockType): Observable<LockType> {
    return lockType.id ? this.updateLockType(lockType) : this.createLockType(lockType);
  }
}
