import { Injectable } from '@angular/core';
import { GeneralService } from '../../../services/general.service';
import { Resident } from '../../../models/resident';
import { debounceTime, filter, Observable, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResidentService {
  constructor(private general: GeneralService) {}

  getResidents(): Observable<Resident[]> {
    return this.general.get('residents');
  }

  getResident(id): Observable<Resident> {
    return this.general.get('residents/' + id);
  }

  deleteResident(id): Observable<void> {
    return this.general.delete('residents/' + id);
  }

  updateResident(resident: Resident): Observable<Resident> {
    return this.general.update('residents', resident);
  }

  createResident(resident: Resident): Observable<Resident> {
    return this.general.post('residents', resident);
  }

  upsertResident(resident: Resident): Observable<Resident> {
    return resident.id ? this.updateResident(resident) : this.createResident(resident);
  }

  search(terms: Observable<string>) {
    return terms.pipe(
      filter(Boolean),
      debounceTime(500),
      switchMap((term: string) => this.general.post('residents/search', { searchPhrase: term }))
    );
  }
}
