import { Component, Input, ViewChild } from '@angular/core';
import { EventType, KeyHistory } from '../../../../models/keyhistory';
import { User } from '../../../../models/user';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { OpenersService } from '../../../ui/services/openers.service';
import { HandsetObserverService } from '../../../ui/services/handset-observer.service';
import { DefaultFilterPredicate } from '../../../../helpers/DefaultFilterPredicate';
import { AnimationTriggerMetadata } from '../../../../helpers/AnimationTriggerMetadata';
import { DefaultSortingDataAccessor } from '../../../../helpers/DefaultSortingDataAccessor';

@Component({
  selector: 'xkey-audit-single',
  templateUrl: './audit-single.component.html',
  styleUrls: ['./audit-single.component.scss'],
  animations: [AnimationTriggerMetadata()],
})
export class AuditSingleComponent {
  @Input() user: User;
  dataSource = new MatTableDataSource<KeyHistory>([]);
  displayedColumnsDesktop = ['apartmentOrCondominium', 'date', 'keyId', 'keyStamp', 'event', 'holder', 'open'];
  displayedColumnsMobile = ['arrow', 'date', 'open'];
  expandedElement: KeyHistory | null;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public handsetObserverService: HandsetObserverService,
    public openers: OpenersService
  ) {}

  private _keyHistories: KeyHistory[] = [];

  get keyHistories(): KeyHistory[] {
    return this._keyHistories;
  }

  @Input()
  set keyHistories(val: KeyHistory[]) {
    this._keyHistories = val;
    this.dataSource = new MatTableDataSource(this.keyHistories);
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = DefaultFilterPredicate();
    this.dataSource.sortingDataAccessor = DefaultSortingDataAccessor();
    this.dataSource.sort = this.sort;
  }

  applyFilter($event: KeyboardEvent) {
    const filterValue = ($event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getEvent(event: number) {
    return EventType[event];
  }
}
