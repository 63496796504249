<div class="login-container">
  <div class="login-logo">
    <img [src]="environment.logo" alt="logo" height="50" width="90" />
  </div>
  <div class="login-form">
    <div class="main-div">
      <div class="panel">
        <h2>{{ 'register.header' | translate }}</h2>
      </div>
      <form (ngSubmit)="register()" [formGroup]="form" id="login">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'register.code' | translate }}</mat-label>
          <input formControlName="code" matInput placeholder="{{ 'register.code' | translate }}" />
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>{{ 'register.firstName' | translate }}</mat-label>
          <input autocomplete="given-name" formControlName="firstName" matInput placeholder="{{ 'register.firstName' | translate }}" />
          <mat-error *ngIf="f.firstName.errors">{{ 'register.firstNameIsRequired' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>{{ 'register.lastName' | translate }}</mat-label>
          <input autocomplete="family-name" formControlName="lastName" matInput placeholder="{{ 'register.lastName' | translate }}" />
          <mat-error *ngIf="f.lastName.errors">{{ 'register.lastNameIsRequired' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>{{ 'register.email' | translate }}</mat-label>
          <input autocomplete="email" formControlName="email" matInput placeholder="{{ 'register.email' | translate }}" type="email" />
          <mat-error *ngIf="f.email.errors">{{ 'register.emailInWrongFormat' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>{{ 'register.phone' | translate }}</mat-label>
          <input autocomplete="phone" formControlName="phone" matInput placeholder="{{ 'register.phone' | translate }}" />
          <mat-error *ngIf="f.phone.errors && (f.phone.errors.wrongNumber || f.phone.errors.required)">{{ 'register.phoneInWrongFormat' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>{{ 'register.password' | translate }}</mat-label>
          <input [matTooltip]="'register.passwordHint' | translate" autocomplete="new-password" formControlName="password" matInput placeholder="{{ 'register.password' | translate }}" type="password" />
          <mat-error *ngIf="f.password.errors">{{ 'user.passwordHint' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>{{ 'register.passwordAgain' | translate }}</mat-label>
          <input autocomplete="new-password" formControlName="passwordAgain" matInput placeholder="{{ 'register.passwordAgain' | translate }}" type="password" />
          <mat-error *ngIf="f.passwordAgain.errors">{{ 'register.passwordsMustBeSame' | translate }}</mat-error>
        </mat-form-field>

        <button color="primary" mat-flat-button type="submit">{{ 'register.register' | translate }}</button>
      </form>
    </div>
  </div>
</div>
