{
  "name": "xkey-frontend",
  "version": "2.3.31",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e"
  },
  "private": true,
  "dependencies": {
    "@almothafar/angular-signature-pad": "^5.0.1",
    "@angular/animations": "^18.2.9",
    "@angular/cdk": "^18.2.10",
    "@angular/common": "^18.2.9",
    "@angular/compiler": "^18.2.9",
    "@angular/core": "^18.2.9",
    "@angular/forms": "^18.2.9",
    "@angular/localize": "^18.2.9",
    "@angular/material": "^18.2.10",
    "@angular/material-moment-adapter": "^18.2.10",
    "@angular/platform-browser": "^18.2.9",
    "@angular/platform-browser-dynamic": "^18.2.9",
    "@angular/router": "^18.2.9",
    "@angular/service-worker": "^18.2.9",
    "@asymmetrik/ngx-leaflet": "^18.0.1",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "axios": "^1.7.7",
    "bootstrap": "^5.3.3",
    "cookieconsent": "^3.1.1",
    "core-js": "^3.38.1",
    "file-saver": "^2.0.5",
    "google-libphonenumber": "^3.2.38",
    "leaflet": "^1.9.4",
    "marked": "^14.1.3",
    "masonry-layout": "^4.2.2",
    "moment": "^2.30.1",
    "ngx-cookie": "^6.0.1",
    "ngx-cookieconsent": "^6.0.0",
    "ngx-file-drop": "^16.0.0",
    "ngx-markdown": "^18.1.0",
    "ngx-masonry": "^14.0.1",
    "ngx-mat-select-search": "^7.0.8",
    "rxjs": "^7.8.1",
    "showdown": "^2.1.0",
    "signature_pad": "^5.0.4",
    "tslib": "^2.8.0",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular/build": "^18.2.10",
    "@angular/cli": "^18.2.10",
    "@angular/compiler-cli": "^18.2.9",
    "@angular/language-service": "^18.2.9",
    "@types/file-saver": "^2.0.7",
    "@types/google-libphonenumber": "^7.4.30",
    "@types/leaflet": "^1.9.14",
    "@types/node": "^22.8.2",
    "codelyzer": "^6.0.2",
    "prettier": "^3.3.3",
    "protractor": "~7.0.0",
    "sass": "^1.80.4",
    "ts-node": "^10.9.2",
    "tslint": "~6.1.3",
    "typescript": "~5.5.4"
  }
}
