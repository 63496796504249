import { Component, OnInit } from '@angular/core';
import { AnnouncementService } from '../../services/announcement.service';
import { Announcement } from '../../../../models/announcement';
import { LoginService } from '../../../auth/services/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmModalComponent } from '../../../ui/components/confirm-modal/confirm-modal.component';
import { DialogService } from '../../../ui/services/dialog.service';
import { AnnouncementModalComponent } from '../announcement-modal/announcement-modal.component';
import { HandsetObserverService } from '../../../ui/services/handset-observer.service';

@Component({
  selector: 'xkey-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss'],
})
export class AnnouncementComponent implements OnInit {
  announcements: Announcement[] = [];
  fromDashboard = false;

  constructor(
    private announcementService: AnnouncementService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    public loginService: LoginService,
    private dialogService: DialogService,
    public handsetObserverService: HandsetObserverService
  ) {
    this.route.params.subscribe(() => {
      if (this.router.url.indexOf('/etusivu') === 0) {
        this.fromDashboard = true;
      }
    });
  }

  ngOnInit() {
    this.getAnnouncements();
  }

  openCreateModifyModal(announcement?: Announcement) {
    this.dialogService.showDialog(AnnouncementModalComponent, { announcement }, () => this.getAnnouncements());
  }

  openDeleteModal(announcementId: number) {
    this.dialogService.showDialog(ConfirmModalComponent, { modalClass: 'modal-announcement', title: this.translate.instant('announcement.deleteAnnouncement') }, () => {
      this.announcementService.deleteAnnouncement(announcementId).subscribe(() => {
        this.getAnnouncements();
      });
    });
  }

  async goToAnnouncements() {
    await this.router.navigate(['/ilmoitukset']);
  }

  private getAnnouncements() {
    this.announcementService.getAnnouncements().subscribe((result) => {
      this.announcements = result;
    });
  }
}
