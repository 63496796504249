<div *ngIf="selectedCompany">
  <div class="page-header">
    <h2>
      <mat-icon>groups</mat-icon>
      {{ 'company.headerSingle' | translate }} {{ selectedCompany.name }}
    </h2>
  </div>
  <div class="card main-card">
    <div *ngIf="!(handsetObserverService.isSmallScreen$ | async)" class="buttons">
      <ng-container *ngTemplateOutlet="buttons"></ng-container>
    </div>

    <mat-accordion *ngIf="handsetObserverService.isSmallScreen$ | async">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'general.actions' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="buttons"></ng-container>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-tab-group (selectedTabChange)="tabChanged($event)" [(selectedIndex)]="tabIndex" mat-stretch-tabs>
      <mat-tab *ngIf="loginService.userHasPermissions('Company_Read')" [label]="'general.basicInformation' | translate">
        <ng-template matTabContent>
          <div class="card basic-info">
            <div class="row">
              <div class="col-sm-6">
                <xkey-basic-info-field [title]="'company.name'" [value]="selectedCompany.name"></xkey-basic-info-field>
                <xkey-basic-info-field [title]="'company.address'" [value]="selectedCompany.addressStreet + ', ' + selectedCompany.addressPostalCode + ' ' + selectedCompany.addressCity"></xkey-basic-info-field>
                <xkey-basic-info-field (click)="openers.openCompany(selectedCompany.parent)" [isClickable]="true" [title]="'company.parent'" [value]="selectedCompany.parent?.name"></xkey-basic-info-field>
              </div>
              <div class="col-sm-6">
                <xkey-map [addressCity]="selectedCompany.addressCity" [addressPostalCode]="selectedCompany.addressPostalCode" [addressStreet]="selectedCompany.addressStreet"></xkey-map>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab *ngIf="loginService.userHasPermissions('Invitation_Read')" [label]="'invitation.header' | translate">
        <xkey-invitation [company]="selectedCompany"></xkey-invitation>
      </mat-tab>
      <mat-tab *ngIf="loginService.userHasPermissions('User_Read')" [label]="'user.header' | translate">
        <xkey-user [company]="selectedCompany"></xkey-user>
      </mat-tab>
      <mat-tab *ngIf="loginService.userHasPermissions('Company_Read')" [label]="'key.keyAgreement' | translate">
        <div class="card main-card p-4">
          <div class="buttons">
            <button (click)="copyKeyAgreementToSubCompanies()" [disabled]="!loginService.userHasPermissions('Company_Update')" class="block-on-mobile" color="primary" mat-flat-button>
              <mat-icon>sync_alt</mat-icon>
              {{ 'company.copyKeyAgreementToSubCompanies' | translate }}
            </button>
          </div>
          <markdown [data]="selectedCompany.keyAgreement"></markdown>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<ng-template #buttons>
  <xkey-go-back-button defaultUrl="/yritykset"></xkey-go-back-button>
  <button (click)="openModifyModal(selectedCompany)" [disabled]="!loginService.userHasPermissions('Company_Update')" class="block-on-mobile" color="accent" mat-flat-button>
    <mat-icon>edit</mat-icon>
    {{ 'general.edit' | translate }}
  </button>
  <button (click)="openDeleteModal(selectedCompany.id)" [disabled]="!loginService.userHasPermissions('Company_Delete') || loginService.getCompany() === selectedCompany.id" class="block-on-mobile" color="warn" mat-flat-button>
    <mat-icon>delete</mat-icon>
    {{ 'general.delete' | translate }}
  </button>
</ng-template>
