<mat-card [ngClass]="{ clickable: isClickable }" appearance="outlined" class="mat-elevation-z1 m-1">
  <mat-card-header>
    <mat-card-title>{{ title | translate }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p>
      <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
      {{ value ? value : '-' }}
      <mat-icon *ngIf="isClickable && value">open_in_new</mat-icon>
    </p>
  </mat-card-content>
</mat-card>
