import { Injectable } from '@angular/core';
import { GeneralService } from '../../../services/general.service';
import { Company } from '../../../models/company';
import { debounceTime, filter, Observable, switchMap } from 'rxjs';
import { User } from '../../../models/user';
import { Invitation } from '../../../models/invitation';
import { KeyAgreementResponse } from '../../../models/keyAgreementResponse';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private general: GeneralService) {}

  getCompanies(): Observable<Company[]> {
    return this.general.get('companies');
  }

  getCompany(id): Observable<Company> {
    return this.general.get(`companies/${id}`);
  }

  getKeyAgreement(id): Observable<KeyAgreementResponse> {
    return this.general.get(`companies/${id}/key-agreement`);
  }

  getCompanyUsers(id): Observable<User[]> {
    return this.general.get(`companies/${id}/users`);
  }

  getCompanyInvitations(id): Observable<Invitation[]> {
    return this.general.get(`companies/${id}/invitations`);
  }

  deleteCompany(id): Observable<void> {
    return this.general.delete(`companies/${id}`);
  }

  updateCompany(company: Company): Observable<Company> {
    return this.general.update('companies', company);
  }

  createCompany(company: Company): Observable<Company> {
    return this.general.post('companies', company);
  }

  upsertCompany(company: Company): Observable<Company> {
    return company.id ? this.updateCompany(company) : this.createCompany(company);
  }

  search(terms: Observable<string>) {
    return terms.pipe(
      filter(Boolean),
      debounceTime(500),
      switchMap((term: string) => this.general.post('companies/search', { searchPhrase: term }))
    );
  }

  copyKeyAgreementToSubCompanies(id: number): Observable<void> {
    return this.general.get(`companies/${id}/copy-key-agreement-to-sub-companies`);
  }
}
