<ng-container>
  <div class="modal-header">
    <h2 class="modal-title" mat-dialog-title>{{ data.company.id ? ('company.edit' | translate) : ('company.create' | translate) }}</h2>
  </div>
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="form">
      <mat-accordion>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'general.basicInformation' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="p-2">
            <mat-form-field appearance="fill">
              <mat-label>{{ 'company.name' | translate }}</mat-label>
              <input formControlName="name" matInput placeholder="{{ 'company.name' | translate }}" />
              <mat-error *ngIf="f.name.errors && f.name.errors.required">{{ 'company.nameIsRequired' | translate }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>{{ 'company.address' | translate }}</mat-label>
              <input formControlName="addressStreet" matInput placeholder="{{ 'company.address' | translate }}" />
              <mat-error *ngIf="f.addressStreet.errors && f.addressStreet.errors.required">{{ 'company.addressIsRequired' | translate }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>{{ 'company.postalCode' | translate }}</mat-label>
              <input formControlName="addressPostal" matInput placeholder="{{ 'company.postalCode' | translate }}" />
              <mat-error *ngIf="f.addressPostal.errors && f.addressPostal.errors.required">{{ 'company.postalCodeIsRequired' | translate }}</mat-error>
              <mat-error *ngIf="f.addressPostal.errors && f.addressPostal.errors.pattern">{{ 'company.postalCodeIsInWrongFormat' | translate }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>{{ 'company.city' | translate }}</mat-label>
              <input formControlName="addressCity" matInput placeholder="{{ 'company.city' | translate }}" />
              <mat-error *ngIf="f.addressCity.errors && f.addressCity.errors.required">{{ 'company.cityIsRequired' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'key.keyAgreement' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="p-2">
            <mat-tab-group>
              <mat-tab [label]="'general.edit' | translate">
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'key.keyAgreement' | translate }}</mat-label>
                  <textarea formControlName="keyAgreement" matInput rows="16"></textarea>
                </mat-form-field>
              </mat-tab>
              <mat-tab [label]="'general.preview' | translate">
                <markdown [data]="f.keyAgreement.value"></markdown>
              </mat-tab>
            </mat-tab-group>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button [mat-dialog-close]="false" color="primary" mat-flat-button>{{ 'general.close' | translate }}</button>
    <button (click)="save()" color="accent" mat-flat-button>{{ 'general.save' | translate }}</button>
  </mat-dialog-actions>
</ng-container>
