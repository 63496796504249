import { Condominium } from './condominium';
import { Apartment } from './apartment';
import { KeyType } from './keyType';

export class CondominiumKey {
  id: number;
  condominium: Condominium;
  apartment: Apartment;
  type: KeyType;
  keyCount: number;

  getApartmentOrCondominiumToString(): string {
    return this.condominium?.name || this.apartment?.fullAddress || '';
  }
}
