<div>
  <div *ngIf="!company" class="page-header">
    <h2>
      <mat-icon>send</mat-icon>
      {{ 'invitation.header' | translate }}
    </h2>
  </div>
  <div class="card main-card">
    <div *ngIf="!(handsetObserverService.isSmallScreen$ | async)" class="buttons">
      <ng-container *ngTemplateOutlet="buttons"></ng-container>
    </div>

    <mat-accordion *ngIf="handsetObserverService.isSmallScreen$ | async">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'general.actions' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="buttons"></ng-container>
      </mat-expansion-panel>
    </mat-accordion>

    <div class="datatable">
      <mat-form-field [floatLabel]="'always'" appearance="outline" class="full-width-search mt-2">
        <mat-label>{{ 'general.search' | translate }}</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input (keyup)="applyFilter($event)" matInput />
      </mat-form-field>

      <ng-template #desktopTable>
        <mat-table [dataSource]="dataSource" [matSortDirection]="'asc'" matSort matSortActive="code" matSortDisableClear>
          <ng-container matColumnDef="code">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'invitation.code' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row.code }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="role">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'invitation.role' | translate }}</mat-header-cell>
            <mat-cell (click)="openRole(row.role)" *matCellDef="let row" class="link">{{ row.role?.name }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'invitation.email' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row.email }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="used">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'invitation.used' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ (row.used ? 'general.yes' : 'general.no') | translate }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>{{ 'general.actions' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button (click)="sendLink(row)" [disabled]="row.used" [matTooltip]="'invitation.send' | translate" class="mx-1" color="primary" mat-flat-button>
                <mat-icon class="mx-0">send</mat-icon>
              </button>
              <button (click)="copyLink(row)" [disabled]="row.used" [matTooltip]="'invitation.copy' | translate" class="mx-1" color="primary" mat-flat-button>
                <mat-icon class="mx-0">copy_all</mat-icon>
              </button>
              <button (click)="openCreateModifyModal(row)" [disabled]="row.used || !loginService.userHasPermissions('Invitation_Update')" [matTooltip]="'general.edit' | translate" class="mx-1" color="accent" mat-flat-button>
                <mat-icon class="mx-0">edit</mat-icon>
              </button>
              <button (click)="openDeleteModal(row.id)" [disabled]="!loginService.userHasPermissions('Invitation_Delete')" [matTooltip]="'general.delete' | translate" class="mx-1" color="warn" mat-flat-button>
                <mat-icon class="mx-0">delete</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsDesktop"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsDesktop"></mat-row>
        </mat-table>
      </ng-template>

      <ng-template #mobileTable>
        <mat-table [dataSource]="dataSource" [matSortDirection]="'asc'" matSort matSortActive="name" matSortDisableClear multiTemplateDataRows>
          <ng-container matColumnDef="arrow">
            <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
            <mat-cell *matCellDef="let row">
              <mat-icon>{{ expandedElement && expandedElement.id === row.id ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}</mat-icon>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="code">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'invitation.code' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row.code }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td *matCellDef="let element" [attr.colspan]="displayedColumnsMobile.length" mat-cell>
              <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'" class="element-detail">
                <div class="detail-buttons">
                  <button (click)="sendLink(element)" class="block-on-mobile" color="primary" mat-flat-button>
                    <mat-icon>copy_all</mat-icon>
                    {{ 'invitation.send' | translate }}
                  </button>
                  <button (click)="copyLink(element)" class="block-on-mobile" color="primary" mat-flat-button>
                    <mat-icon>copy_all</mat-icon>
                    {{ 'invitation.copy' | translate }}
                  </button>
                  <button (click)="openCreateModifyModal(element)" [disabled]="!loginService.userHasPermissions('Invitation_Update')" class="block-on-mobile" color="accent" mat-flat-button>
                    <mat-icon>edit</mat-icon>
                    {{ 'general.edit' | translate }}
                  </button>
                  <button (click)="openDeleteModal(element.id)" [disabled]="!loginService.userHasPermissions('Invitation_Delete')" class="block-on-mobile" color="warn" mat-flat-button>
                    <mat-icon>delete</mat-icon>
                    {{ 'general.delete' | translate }}
                  </button>
                </div>
              </div>
            </td>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsMobile"></mat-header-row>
          <mat-row (click)="expandedElement = expandedElement === element ? null : element" *matRowDef="let element; columns: displayedColumnsMobile" [class.expanded-row]="expandedElement === element" class="element-row"></mat-row>

          <tr
            *matRowDef="let row; columns: ['expandedDetail']"
            [ngClass]="{
              'expanded-detail': expandedElement && expandedElement.id == row.id,
              'closed-detail': !(expandedElement && expandedElement.id == row.id)
            }"
            class="detail-row"
            mat-row
          ></tr>
        </mat-table>
      </ng-template>

      <ng-container *ngIf="handsetObserverService.isHandSet$ | async">
        <ng-template [ngTemplateOutlet]="mobileTable"></ng-template>
      </ng-container>

      <ng-container *ngIf="!(handsetObserverService.isHandSet$ | async)">
        <ng-template [ngTemplateOutlet]="desktopTable"></ng-template>
      </ng-container>

      <div *ngIf="invitations.length === 0" class="no-results">
        {{ 'general.noResults' | translate }}
      </div>

      <mat-paginator #paginator [length]="dataSource.data.length" [pageIndex]="0" [pageSizeOptions]="[25, 50, 100, 250]" [pageSize]="50"> </mat-paginator>
    </div>
  </div>
</div>

<ng-template #buttons>
  <button (click)="openCreateModifyModal()" [disabled]="!loginService.userHasPermissions('Invitation_Create')" class="block-on-mobile" color="primary" mat-flat-button>
    <mat-icon>note_add</mat-icon>
    {{ 'invitation.create' | translate }}
  </button>
</ng-template>
