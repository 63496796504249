<ngx-masonry [options]="masonryOptions">
  <div *ngIf="loginService.userHasPermissions('Announcement_Read')" class="masonry-item" ngxMasonryItem>
    <xkey-announcement></xkey-announcement>
  </div>
  <div *ngIf="userSettings.showMyKeys && loginService.userHasPermissions('Key_Read')" class="masonry-item" ngxMasonryItem>
    <xkey-key (elementExpansionChanged)="reloadLayout()" [fromDashboard]="true" [title]="'dashboard.myKeys' | translate"></xkey-key>
  </div>
  <div *ngIf="userSettings.showLateKeys && loginService.userHasPermissions('Key_Read')" class="masonry-item" ngxMasonryItem>
    <xkey-key (elementExpansionChanged)="reloadLayout()" [fromDashboard]="true" [title]="'dashboard.lateKeys' | translate" showLateOnly="true"></xkey-key>
  </div>
  <div *ngIf="userSettings.showMyCondominia && loginService.userHasPermissions('Condominium_Read')" class="masonry-item" ngxMasonryItem>
    <xkey-condominium></xkey-condominium>
  </div>
  <div *ngIf="userSettings.showMyCompanysKeys && loginService.userHasPermissions('Key_Read')" class="masonry-item" ngxMasonryItem>
    <xkey-key (elementExpansionChanged)="reloadLayout()" [fromDashboard]="true" [title]="'dashboard.companyKeys' | translate"></xkey-key>
  </div>
  <div *ngIf="userSettings.showMissingKeys && loginService.userHasPermissions('Key_Read')" class="masonry-item" ngxMasonryItem>
    <xkey-key (elementExpansionChanged)="reloadLayout()" [fromDashboard]="true" [title]="'dashboard.missingKeys' | translate" showLostOnly="true"></xkey-key>
  </div>
</ngx-masonry>
