import { User } from './user';
import { Company } from './company';
import { LockType } from './lockType';
import { KeyType } from './keyType';

export class Condominium {
  id: number;
  name: string;
  number: string;
  addressStreet: string;
  addressPostalCode: string;
  addressCity: string;
  businessId: string;
  superIntendentId: number;
  superIntendent: User;
  lockTypeId: number;
  lockType: LockType;
  keyTypeId: number;
  keyType: KeyType;
  apartmentKeyTypeId: number;
  apartmentKeyType: KeyType;
  owner: Company;
  ownerId: number;
  fullAddress: string;
}
