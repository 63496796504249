<div *ngIf="selectedKeyHistory">
  <div class="page-header">
    <h2>
      <mat-icon>vpn_key</mat-icon>
      {{ 'keyHistory.headerSingle' | translate }} {{ selectedKeyHistory.id }}
    </h2>
  </div>
  <div class="card">
    <div *ngIf="!(handsetObserverService.isSmallScreen$ | async)" class="buttons">
      <ng-container *ngTemplateOutlet="buttons"></ng-container>
    </div>

    <mat-accordion *ngIf="handsetObserverService.isSmallScreen$ | async">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'general.actions' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="buttons"></ng-container>
      </mat-expansion-panel>
    </mat-accordion>

    <div class="card main-card basic-info">
      <div class="row">
        <div class="col-sm-6">
          <xkey-basic-info-field (click)="openers.openKey(selectedKeyHistory.key)" [isClickable]="true" [title]="'keyHistory.key'" [value]="selectedKeyHistory.key.id"></xkey-basic-info-field>
          <xkey-basic-info-field [title]="'keyHistory.time'" [value]="selectedKeyHistory.date | date: 'dd.MM.y HH:mm:ss'"></xkey-basic-info-field>
          <xkey-basic-info-field [title]="'keyHistory.dueDate'" [value]="selectedKeyHistory.dueDate | date: 'dd.MM.y HH:mm:ss'"></xkey-basic-info-field>
          <xkey-basic-info-field [title]="'keyHistory.event'" [value]="'key.' + getEventType(selectedKeyHistory.event) | translate"></xkey-basic-info-field>
          <xkey-basic-info-field [title]="'keyHistory.stamp'" [value]="selectedKeyHistory.key?.stamp"></xkey-basic-info-field>
          <xkey-basic-info-field
            (click)="openCurrentHolder(selectedKeyHistory)"
            [isClickable]="!!selectedKeyHistory.user || !!selectedKeyHistory.resident"
            [title]="'key.holder'"
            [value]="getCurrentHolderValue(selectedKeyHistory)"
          ></xkey-basic-info-field>
          <xkey-basic-info-field (click)="openCreator(selectedKeyHistory)" [isClickable]="!!selectedKeyHistory.createdBy" [title]="'key.createdBy'" [value]="selectedKeyHistory.createdBy.fullName"></xkey-basic-info-field>
        </div>
        <div class="col-sm-6">
          <mat-card appearance="outlined" class="mat-elevation-z1 m-1">
            <mat-card-header>
              <mat-card-title>{{ 'keyHistory.signature' | translate }}</mat-card-title>
            </mat-card-header>
            <mat-card-content class="pt-1">
              <img *ngIf="selectedKeyHistory.signature" [src]="selectedKeyHistory.signature" alt="signature" class="signature" />
            </mat-card-content>
          </mat-card>
          <mat-card *ngIf="selectedKeyHistory.mandate" appearance="outlined" class="mat-elevation-z1 m-1">
            <mat-card-header>
              <mat-card-title>{{ 'keyHistory.mandate' | translate }}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <button (click)="openMandate()" *ngIf="selectedKeyHistory.mandate" color="primary" mat-flat-button>{{ 'keyHistory.openMandate' | translate }}</button>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #buttons>
  <xkey-go-back-button [defaultUrl]="'/avaimet/' + selectedKeyHistory.key.id"></xkey-go-back-button>
  <button (click)="downloadAgreementAsPdf()" [disabled]="!selectedKeyHistory.keyAgreement" class="block-on-mobile" color="primary" mat-flat-button>
    <mat-icon>download</mat-icon>
    {{ 'key.downloadKeyAgreement' | translate }}
  </button>
</ng-template>
