import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Company } from '../../../../models/company';
import { CompanyService } from '../../services/company.service';
import { CompanyModalComponent } from '../company-modal/company-modal.component';
import { LoginService } from '../../../auth/services/login.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { OpenersService } from '../../../ui/services/openers.service';
import { HandsetObserverService } from '../../../ui/services/handset-observer.service';
import { AnimationTriggerMetadata } from '../../../../helpers/AnimationTriggerMetadata';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { DialogService } from '../../../ui/services/dialog.service';
import { DefaultSortingDataAccessor } from '../../../../helpers/DefaultSortingDataAccessor';

@Component({
  selector: 'xkey-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
  animations: [AnimationTriggerMetadata()],
})
export class CompanyComponent implements OnInit {
  dataSource = new MatTableDataSource<Company>([]);
  displayedColumnsDesktop = ['name', 'fullAddress', 'open'];
  displayedColumnsMobile = ['arrow', 'name', 'open'];
  expandedElement: Company | null;
  pageSize = 50;

  @Input() fromSearch = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private companyService: CompanyService,
    public handsetObserverService: HandsetObserverService,
    public loginService: LoginService,
    public openers: OpenersService,
    private dialogService: DialogService
  ) {}

  private _companies: Company[] = [];

  get companies(): Company[] {
    return this._companies;
  }

  @Input()
  set companies(val: Company[]) {
    this._companies = val;

    if (this.fromSearch) {
      this.setupComponent().then();
    }
  }

  async ngOnInit() {
    if (this.fromSearch) {
      this.pageSize = 5;
    }

    await this.setupComponent();
  }

  async setupComponent() {
    await this.getCompanies();
  }

  async openCreateModal() {
    const company = await firstValueFrom(this.companyService.getCompany(this.loginService.getCompany()));
    this.dialogService.showDialog(CompanyModalComponent, { keyAgreement: company.keyAgreement }, async (result: { company: Company; isNew: boolean }) => {
      await this.openers.openCompany(result.company);
    });
  }

  applyFilter($event: KeyboardEvent) {
    const filterValue = ($event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  private async getCompanies() {
    if (!this.fromSearch) {
      this.companies = await lastValueFrom(this.companyService.getCompanies());
    }

    this.dataSource = new MatTableDataSource(this.companies);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = DefaultSortingDataAccessor();
    this.dataSource.sort = this.sort;
  }
}
