import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../user/services/user.service';
import { User } from '../../../../models/user';
import { KeyHistory } from '../../../../models/keyhistory';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FilterByField } from '../../../../helpers/DropdownFilters';
import { ReplaySubject } from 'rxjs';
import { AuditData } from '../../../../models/auditData';

@Component({
  selector: 'xkey-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss'],
})
export class AuditComponent implements OnInit {
  users: User[] = [];
  keyHistories: KeyHistory[] = [];
  form: FormGroup;
  userCtrl: FormControl = new FormControl();
  userFilterCtrl: FormControl = new FormControl();
  filteredUsers: ReplaySubject<User[]> = new ReplaySubject<[]>(1);
  selectedKeyHistories: KeyHistory[] = [];

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder
  ) {}

  get f() {
    return this.form.controls;
  }

  get user() {
    return this.users.find((u) => u.id === this.f.user.value);
  }

  ngOnInit(): void {
    this.userService.getAuditData().subscribe((result: AuditData) => {
      this.users = result.users;
      this.users.sort((a, b) => (a.firstName + ' ' + a.lastName > b.firstName + ' ' + b.lastName ? 1 : -1));
      this.keyHistories = result.keyHistories.map((kh) => new KeyHistory(kh));

      this.userFilterCtrl.valueChanges.subscribe(() => {
        FilterByField()(this.filteredUsers, this.users, 'fullName', this.userFilterCtrl.value?.toLowerCase());
      });

      this.filteredUsers.next(this.users.slice());

      this.form = this.formBuilder.group({
        user: this.userCtrl,
      });
    });
  }

  getKeyHistoriesForUser() {
    this.selectedKeyHistories = this.keyHistories.filter((value) => value.createdBy.id === this.f.user.value);
  }
}
