<div *ngIf="selectedResident">
  <div class="page-header">
    <h2>
      <mat-icon>person</mat-icon>
      {{ 'resident.headerSingle' | translate }} {{ selectedResident.fullName }}
    </h2>
  </div>
  <div class="card main-card">
    <div *ngIf="!(handsetObserverService.isSmallScreen$ | async)" class="buttons">
      <ng-container *ngTemplateOutlet="buttons"></ng-container>
    </div>

    <mat-accordion *ngIf="handsetObserverService.isSmallScreen$ | async">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'general.actions' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="buttons"></ng-container>
      </mat-expansion-panel>
    </mat-accordion>

    <div class="card basic-info">
      <div class="row">
        <div class="col-sm-6">
          <xkey-basic-info-field [title]="'resident.name'" [value]="selectedResident.firstName + ' ' + selectedResident.lastName"></xkey-basic-info-field>
          <xkey-basic-info-field [title]="'resident.email'" [value]="selectedResident.email"></xkey-basic-info-field>
          <xkey-basic-info-field [title]="'resident.phone'" [value]="selectedResident.phone"></xkey-basic-info-field>
        </div>
        <div class="col-sm-6">
          <xkey-basic-info-field
            (click)="openers.openApartment(selectedResident.apartment)"
            [isClickable]="true"
            [title]="'resident.apartment'"
            [value]="selectedResident.apartment ? selectedResident.apartment?.addressStreet + ', ' + selectedResident.apartment?.addressPostalCode + ' ' + selectedResident.apartment?.addressCity : undefined"
          ></xkey-basic-info-field>
          <xkey-basic-info-field (click)="openers.openCompany(selectedResident.owner)" [isClickable]="true" [title]="'resident.owner'" [value]="selectedResident.owner?.name"></xkey-basic-info-field>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #buttons>
  <xkey-go-back-button [defaultUrl]="'/asunnot/' + selectedResident.apartmentId"></xkey-go-back-button>
  <button (click)="openUpdateModal(selectedResident)" [disabled]="!loginService.userHasPermissions('Resident_Update')" class="block-on-mobile" color="accent" mat-flat-button>
    <mat-icon>edit</mat-icon>
    {{ 'general.edit' | translate }}
  </button>
  <button (click)="openDeleteModal(selectedResident.id)" [disabled]="!loginService.userHasPermissions('Resident_Delete')" class="block-on-mobile" color="warn" mat-flat-button>
    <mat-icon>delete</mat-icon>
    {{ 'general.delete' | translate }}
  </button>
</ng-template>
