import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'xkey-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { modalClass: string; title: string; message?: string }
  ) {
    if (!data.message) {
      data.message = 'Oletko varma, että haluat poistaa?';
    }
  }

  ngOnInit() {}

  public accept() {
    this.dialogRef.close(true);
  }

  getModalClass() {
    return this.data.modalClass;
  }
}
