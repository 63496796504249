import { ReplaySubject } from 'rxjs';

export function FilterByField() {
  return (filteredItems: ReplaySubject<any[]>, items: any[], field: string, search: string) => {
    if (!items) {
      filteredItems.next([]);
      return;
    }

    const filtered = items.filter((item) => {
      const fieldValue = item[field];
      return fieldValue && fieldValue.toLowerCase().includes(search.toLowerCase());
    });
    filteredItems.next(filtered);
  };
}
