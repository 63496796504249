<div [hidden]="fromSearch && keys.length === 0" [ngClass]="{ 'from-search': fromSearch && keys.length > 0 }">
  <div *ngIf="!condominium && !apartment && !user && !fromDashboard" class="page-header page-header-keys">
    <h2>
      <mat-icon>vpn_key</mat-icon>
      {{ 'key.header' | translate }}
    </h2>
  </div>

  <div *ngIf="fromDashboard" class="page-header dashboard-header">
    <h2>
      <mat-icon>vpn_key</mat-icon>
      {{ title }}
    </h2>
  </div>

  <div class="card main-card">
    <div *ngIf="!fromDashboard && !(handsetObserverService.isSmallScreen$ | async)" class="buttons">
      <ng-container *ngTemplateOutlet="buttons"></ng-container>
    </div>

    <mat-accordion *ngIf="!fromDashboard && (handsetObserverService.isSmallScreen$ | async)">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'general.actions' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="buttons"></ng-container>
      </mat-expansion-panel>
    </mat-accordion>

    <div class="datatable">
      <mat-form-field *ngIf="!fromSearch && !fromDashboard" [floatLabel]="'always'" appearance="outline" class="full-width-search mt-2">
        <mat-label>{{ 'general.search' | translate }}</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input (keyup)="applyFilter($event)" matInput />
      </mat-form-field>

      <ng-template #desktopTable>
        <mat-table [dataSource]="dataSource" matSort matSortDisableClear>
          <ng-container matColumnDef="select">
            <th *matHeaderCellDef mat-header-cell>
              <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
            </th>
            <td *matCellDef="let row" mat-cell>
              <mat-checkbox (change)="$event ? selection.toggle(row) : null" (click)="$event.stopPropagation()" [checked]="selection.isSelected(row)"></mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'key.id' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row" [ngClass]="getRowClass(row)">{{ row.id }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="apartmentOrCondominium">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'key.apartmentOrCondominium' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row" [ngClass]="getRowClass(row)">
              <span (click)="openers.openApartment(row.apartment, row.owner?.id)" *ngIf="row.apartment" class="link"> {{ row.apartment?.fullAddress }}</span>
              <span (click)="openers.openCondominium(row.condominium, row.owner?.id)" *ngIf="row.condominium && row.condominium" class="link"> {{ row.condominium.name }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'key.type' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row" [ngClass]="getRowClass(row)">{{ row.type?.name }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="keyLockType">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'key.lockType' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row" [ngClass]="getRowClass(row)">{{ row.lockType?.name }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="stamp">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'key.stamp' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row" [ngClass]="getRowClass(row)">{{ row.stamp }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="holder">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'key.holder' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row" [ngClass]="getRowClass(row)">
              <span (click)="openers.openResident(row.currentHolder)" *ngIf="row.currentHolder" [ngClass]="{ link: loginService.userHasPermissions('Resident_Read') }">
                {{ row.currentHolder.fullName }}
              </span>
              <span (click)="openers.openUser(row.currentHolderInternal)" *ngIf="row.currentHolderInternal && row.currentHolderInternal" [ngClass]="{ link: loginService.userHasPermissions('User_Read') }">
                {{ row.currentHolderInternal.fullName }}
              </span>

              <span *ngIf="row.currentHolderOther" class="align-vertically-center">
                {{ row.currentHolderOther.firstName }} {{ row.currentHolderOther.lastName }}<br *ngIf="row.currentHolderOther.email" />{{ row.currentHolderOther.email }}<br *ngIf="row.currentHolderOther.phone" />{{
                  row.currentHolderOther.phone
                }}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="originalHolder">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'key.originalHolder' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row" [ngClass]="getRowClass(row)">
              <span (click)="openers.openResident(row.originalHolder)" *ngIf="row.originalHolder" [ngClass]="{ link: loginService.userHasPermissions('Resident_Read') }">
                {{ row.originalHolder.fullName }}
              </span>

              <span (click)="openers.openUser(row.originalHolderInternal)" *ngIf="row.originalHolderInternal" [ngClass]="{ link: loginService.userHasPermissions('User_Read') }">
                {{ row.originalHolderInternal.fullName }}
              </span>

              <span *ngIf="row.originalHolderOther" class="align-vertically-center">
                {{ row.originalHolderOther.firstName }} {{ row.originalHolderOther.lastName }}<br *ngIf="row.originalHolderOther.email" />{{ row.originalHolderOther.email }}<br *ngIf="row.originalHolderOther.phone" />{{
                  row.originalHolderOther.phone
                }}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="open">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell (click)="openers.openKey(row)" *matCellDef="let row" [ngClass]="getRowClass(row)">
              <mat-icon>open_in_new</mat-icon>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsDesktop"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsDesktop" class="key-row"></mat-row>
        </mat-table>
      </ng-template>

      <ng-template #mobileTable>
        <mat-table [dataSource]="dataSource" [matSortDirection]="'asc'" matSort matSortActive="fullAddress" matSortDisableClear multiTemplateDataRows>
          <ng-container matColumnDef="arrow">
            <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
            <mat-cell *matCellDef="let row">
              <mat-icon>{{ expandedElement && expandedElement.id === row.id ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}</mat-icon>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="select">
            <th *matHeaderCellDef mat-header-cell>
              <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
            </th>
            <td *matCellDef="let row" mat-cell>
              <mat-checkbox (change)="$event ? selection.toggle(row) : null" (click)="$event.stopPropagation()" [checked]="selection.isSelected(row)"></mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="apartmentOrCondominium">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'key.apartmentOrCondominium' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row" [ngClass]="getRowClass(row)">
              <span (click)="openers.openApartment(row.apartment, row.owner?.id)" *ngIf="row.apartment" class="link"> {{ row.apartment?.fullAddress }}</span>
              <span (click)="openers.openCondominium(row.condominium, row.owner?.id)" *ngIf="row.condominium && row.condominium" class="link"> {{ row.condominium.name }}</span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="open">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell (click)="openers.openKey(row)" *matCellDef="let row">
              <mat-icon>open_in_new</mat-icon>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td *matCellDef="let element" [attr.colspan]="displayedColumnsMobile.length" mat-cell>
              <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'" class="element-detail">
                <xkey-basic-info-field [title]="'key.id'" [value]="element.id"></xkey-basic-info-field>
                <xkey-basic-info-field
                  (click)="openers.openApartment(element.apartment, element.owner?.id)"
                  *ngIf="element.apartment"
                  [isClickable]="true"
                  [title]="'key.apartment'"
                  [value]="element.apartment?.addressStreet + ', ' + element.apartment?.addressPostalCode + ' ' + element.apartment?.addressCity"
                ></xkey-basic-info-field>
                <xkey-basic-info-field
                  (click)="openers.openCondominium(element.condominium, element.owner.id)"
                  *ngIf="element.condominium"
                  [isClickable]="true"
                  [title]="'key.condominium'"
                  [value]="element.condominium?.name"
                ></xkey-basic-info-field>
                <xkey-basic-info-field (click)="openCurrentHolder(element)" [isClickable]="!!element.currentHolderInternal || !!element.currentHolder" [title]="'key.holder'" [value]="getCurrentHolderValue(element)"></xkey-basic-info-field>
                <xkey-basic-info-field [title]="'key.stamp'" [value]="element.stamp?.toString()"></xkey-basic-info-field>

                <div class="detail-buttons">
                  <button (click)="openGiveKeysModal([element.id])" *ngIf="keyCanBeGiven(element)" [disabled]="!loginService.userHasPermissions('Key_GiveReturn')" class="block-on-mobile" color="primary" mat-flat-button>
                    <mat-icon>vpn_key</mat-icon>
                    {{ 'key.give' | translate }}
                  </button>
                  <button
                    (click)="openReturnKeysModal([element.id])"
                    *ngIf="element.currentHolder || element.currentHolderInternal || element.currentHolderOther"
                    [disabled]="!loginService.userHasPermissions('Key_GiveReturn')"
                    class="block-on-mobile"
                    color="primary"
                    mat-flat-button
                  >
                    <mat-icon>vpn_key</mat-icon>
                    {{ 'key.return' | translate }}
                  </button>
                </div>
              </div>
            </td>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsMobile"></mat-header-row>
          <mat-row (click)="toggleExpansion(element)" *matRowDef="let element; columns: displayedColumnsMobile" [class.expanded-row]="expandedElement === element" class="element-row"></mat-row>

          <tr
            *matRowDef="let row; columns: ['expandedDetail']"
            [ngClass]="{
              'expanded-detail': expandedElement && expandedElement.id == row.id,
              'closed-detail': !(expandedElement && expandedElement.id == row.id)
            }"
            class="detail-row"
            mat-row
          ></tr>
        </mat-table>
      </ng-template>

      <ng-container *ngIf="handsetObserverService.isHandSet$ | async">
        <ng-template [ngTemplateOutlet]="mobileTable"></ng-template>
      </ng-container>

      <ng-container *ngIf="!(handsetObserverService.isHandSet$ | async)">
        <ng-template [ngTemplateOutlet]="desktopTable"></ng-template>
      </ng-container>

      <div *ngIf="keys.length === 0" class="no-results">
        {{ 'general.noResults' | translate }}
      </div>

      <mat-paginator #paginator [length]="dataSource.data.length" [pageIndex]="0" [pageSizeOptions]="fromDashboard ? [3] : [5, 25, 50, 100, 250]" [pageSize]="pageSize"> </mat-paginator>
    </div>
  </div>
</div>

<ng-template #buttons>
  <button (click)="openCreateModal()" [disabled]="!loginService.userHasPermissions('Key_Create')" class="block-on-mobile" color="primary" mat-flat-button>
    <mat-icon>note_add</mat-icon>
    {{ 'key.create' | translate }}
  </button>
  <button (click)="deleteSelected()" *ngIf="!fromSearch" [disabled]="!loginService.userHasPermissions('Key_Delete') || !somethingIsSelected()" class="block-on-mobile" color="warn" mat-flat-button>
    <mat-icon>delete</mat-icon>
    {{ 'key.deleteSelected' | translate }}
  </button>
  <button (click)="giveSelected()" [disabled]="!loginService.userHasPermissions('Key_GiveReturn') || !isSelectedAvailable()" class="block-on-mobile" color="primary" mat-flat-button>{{ 'key.giveSelected' | translate }}</button>
  <button (click)="returnSelected()" [disabled]="!loginService.userHasPermissions('Key_GiveReturn') || !isSelectedGiven()" class="block-on-mobile" color="primary" mat-flat-button>{{ 'key.returnSelected' | translate }}</button>
  <button (click)="toggleShowLostKeys()" class="block-on-mobile" color="primary" mat-flat-button>{{ showLostKeys ? ('key.showAllKeys' | translate) : ('key.showLostKeys' | translate) }}</button>
  <button (click)="toggleShowLateKeys()" class="block-on-mobile" color="primary" mat-flat-button>{{ showLateKeys ? ('key.showAllKeys' | translate) : ('key.showLateKeys' | translate) }}</button>
</ng-template>
