<div *ngIf="selectedCondominium">
  <div class="page-header">
    <h2>
      <mat-icon>apartment</mat-icon>
      {{ 'condominium.headerSingle' | translate }} {{ selectedCondominium.name }}
    </h2>
  </div>
  <div class="card main-card">
    <div *ngIf="!(handsetObserverService.isSmallScreen$ | async)" class="buttons">
      <ng-container *ngTemplateOutlet="buttons"></ng-container>
    </div>

    <mat-accordion *ngIf="handsetObserverService.isSmallScreen$ | async">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'general.actions' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="buttons"></ng-container>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-tab-group (selectedTabChange)="tabChanged($event)" [(selectedIndex)]="tabIndex" mat-stretch-tabs>
      <mat-tab *ngIf="loginService.userHasPermissions('Condominium_Read')" [label]="'general.basicInformation' | translate">
        <ng-template matTabContent>
          <div class="card basic-info">
            <div class="row">
              <div class="col-sm-6">
                <xkey-basic-info-field [title]="'condominium.name'" [value]="selectedCondominium.name"></xkey-basic-info-field>
                <xkey-basic-info-field [title]="'condominium.number'" [value]="selectedCondominium.number"></xkey-basic-info-field>
                <xkey-basic-info-field [title]="'condominium.address'" [value]="selectedCondominium.addressStreet + ', ' + selectedCondominium.addressPostalCode + ' ' + selectedCondominium.addressCity"></xkey-basic-info-field>
                <xkey-basic-info-field [title]="'condominium.businessId'" [value]="selectedCondominium.businessId"></xkey-basic-info-field>
                <xkey-basic-info-field
                  (click)="openers.openUser(selectedCondominium.superIntendent)"
                  [isClickable]="true"
                  [title]="'condominium.superIntendent'"
                  [value]="selectedCondominium.superIntendent?.fullName"
                ></xkey-basic-info-field>
                <xkey-basic-info-field [title]="'condominium.lockType'" [value]="selectedCondominium.lockType?.name"></xkey-basic-info-field>
                <xkey-basic-info-field [title]="'condominium.keyType'" [value]="selectedCondominium.keyType?.name"></xkey-basic-info-field>
                <xkey-basic-info-field [title]="'condominium.apartmentKeyType'" [value]="selectedCondominium.apartmentKeyType?.name"></xkey-basic-info-field>
                <xkey-basic-info-field (click)="openers.openCompany(selectedCondominium.owner)" [isClickable]="true" [title]="'condominium.owner'" [value]="selectedCondominium.owner?.name"></xkey-basic-info-field>
              </div>
              <div class="col-sm-6">
                <xkey-map [addressCity]="selectedCondominium.addressCity" [addressPostalCode]="selectedCondominium.addressPostalCode" [addressStreet]="selectedCondominium.addressStreet"></xkey-map>
              </div>
            </div>

            <xkey-condominium-files [selectedCondominium]="selectedCondominium"></xkey-condominium-files>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab *ngIf="loginService.userHasPermissions('Apartment_Read')" [label]="'apartment.header' | translate">
        <xkey-apartment [condominium]="selectedCondominium"></xkey-apartment>
      </mat-tab>
      <mat-tab *ngIf="loginService.userHasPermissions('Key_Read')" [label]="'condominium.generalKeys' | translate">
        <xkey-key [condominium]="selectedCondominium"></xkey-key>
      </mat-tab>
      <mat-tab *ngIf="loginService.userHasPermissions('Key_Read')" [label]="'condominium.allKeys' | translate">
        <xkey-condominium-keys [condominium]="selectedCondominium"></xkey-condominium-keys>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<ng-template #buttons>
  <xkey-go-back-button [defaultUrl]="'/kiinteistot'"></xkey-go-back-button>
  <button (click)="openModifyModal(selectedCondominium)" [disabled]="!loginService.userHasPermissions('Condominium_Update')" class="block-on-mobile" color="accent" mat-flat-button>
    <mat-icon>edit</mat-icon>
    {{ 'general.edit' | translate }}
  </button>
  <button (click)="openTransferModal(selectedCondominium)" [disabled]="!loginService.userHasPermissions('Condominium_Update', 'Apartment_Update', 'Resident_Update', 'Key_Update')" class="block-on-mobile" color="accent" mat-flat-button>
    <mat-icon>swap_horiz</mat-icon>
    {{ 'general.transfer' | translate }}
  </button>
  <button (click)="openDeleteModal(selectedCondominium.id)" [disabled]="!loginService.userHasPermissions('Condominium_Delete')" class="block-on-mobile" color="warn" mat-flat-button>
    <mat-icon>delete</mat-icon>
    {{ 'general.delete' | translate }}
  </button>
</ng-template>
