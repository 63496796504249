import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LockTypeService } from '../../services/lock-type.service';
import { LockType } from '../../../../models/lockType';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackService } from '../../../ui/services/snack.service';

@Component({
  selector: 'xkey-lock-type-modal',
  templateUrl: './lock-type-modal.component.html',
  styleUrls: ['./lock-type-modal.component.scss'],
})
export class LockTypeModalComponent implements OnInit {
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private lockTypeService: LockTypeService,
    private snackService: SnackService,
    public dialogRef: MatDialogRef<LockTypeModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      lockType?: LockType;
    }
  ) {
    data.lockType = data.lockType ?? new LockType();
  }

  get f() {
    return this.form.controls;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [this.data.lockType.name, Validators.required],
    });
  }

  save() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.data.lockType.name = this.f.name.value;
    this.saveLockType(this.data.lockType);
  }

  close() {
    this.dialogRef.close();
  }

  saveLockType(lockType: LockType) {
    const isNew = !lockType.id;

    this.lockTypeService.upsertLockType(lockType).subscribe({
      next: (result) => {
        this.snackService.showMessage(isNew ? 'lockType.created' : 'lockType.updated');
        this.dialogRef.close(result);
      },
      error: (error) => {
        console.error(error);
        this.snackService.showGeneralError();
      },
    });
  }
}
