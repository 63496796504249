<ng-container>
  <div class="page-header page-header-audit">
    <h2>
      <mat-icon>shield</mat-icon>
      {{ 'audit.header' | translate }}
    </h2>
  </div>
  <div *ngIf="form" class="card main-card">
    <form [formGroup]="form">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'audit.user' | translate }}</mat-label>
        <mat-select (selectionChange)="getKeyHistoriesForUser()" [formControl]="userCtrl" placeholder="{{ 'key.user' | translate }}">
          <mat-option>
            <ngx-mat-select-search [formControl]="userFilterCtrl" [noEntriesFoundLabel]="'general.noEntriesFound' | translate" [placeholderLabel]="'general.selectSearch' | translate"></ngx-mat-select-search>
          </mat-option>
          <mat-option></mat-option>
          <mat-option *ngFor="let user of filteredUsers | async" [value]="user.id">
            {{ user.fullName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>

    <div [hidden]="!user">
      <xkey-audit-single [keyHistories]="selectedKeyHistories" [user]="user"></xkey-audit-single>
    </div>
  </div>
</ng-container>
