import { Injectable } from '@angular/core';
import { GeneralService } from '../../../services/general.service';
import { Invitation } from '../../../models/invitation';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InvitationService {
  constructor(private general: GeneralService) {}

  getInvitations(): Observable<Invitation[]> {
    return this.general.get('invitations');
  }

  deleteInvitation(id): Observable<void> {
    return this.general.delete('invitations/' + id);
  }

  updateInvitation(invitation: Invitation): Observable<Invitation> {
    return this.general.update('invitations', invitation);
  }

  createInvitation(invitation: Invitation): Observable<Invitation> {
    return this.general.post('invitations', invitation);
  }

  sendInvitation(emailAddress: string, invitation: number): Observable<void> {
    return this.general.post('invitations/send-invitation', { email: emailAddress, invitation });
  }

  upsertInvitation(invitation: Invitation): Observable<Invitation> {
    return invitation.id ? this.updateInvitation(invitation) : this.createInvitation(invitation);
  }
}
