import { Injectable } from '@angular/core';
import { GeneralService } from '../../../services/general.service';
import { KeyType } from '../../../models/keyType';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KeyTypeService {
  constructor(private general: GeneralService) {}

  getKeyTypes(): Observable<KeyType[]> {
    return this.general.get('key-types');
  }

  deleteKeyType(id): Observable<void> {
    return this.general.delete('key-types/' + id);
  }

  updateKeyType(keyType: KeyType): Observable<KeyType> {
    return this.general.update('key-types', keyType);
  }

  createKeyType(keyType: KeyType): Observable<KeyType> {
    return this.general.post('key-types', keyType);
  }

  upsertKeyType(keyType: KeyType): Observable<KeyType> {
    return keyType.id ? this.updateKeyType(keyType) : this.createKeyType(keyType);
  }
}
