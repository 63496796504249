import { Component, Inject, OnInit } from '@angular/core';
import { ResidentService } from '../../services/resident.service';
import { Resident } from '../../../../models/resident';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Apartment } from '../../../../models/apartment';
import { PhoneNumberValidator } from '../../../../helpers/PhoneNumberValidator';
import { ReplaySubject } from 'rxjs';
import { FilterByField } from '../../../../helpers/DropdownFilters';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackService } from '../../../ui/services/snack.service';
import { LoginService } from '../../../auth/services/login.service';
import { Company } from '../../../../models/company';

@Component({
  selector: 'xkey-resident-modal',
  templateUrl: './resident-modal.component.html',
  styleUrls: ['./resident-modal.component.scss'],
})
export class ResidentModalComponent implements OnInit {
  public apartmentCtrl: FormControl = new FormControl();
  public apartmentFilterCtrl: FormControl = new FormControl();
  public filteredApartments: ReplaySubject<Apartment[]> = new ReplaySubject<Apartment[]>(1);

  public ownerCtrl: FormControl = new FormControl();
  public ownerFilterCtrl: FormControl = new FormControl();
  public filteredOwners: ReplaySubject<Company[]> = new ReplaySubject<Company[]>(1);

  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private residentService: ResidentService,
    private snackService: SnackService,
    private loginService: LoginService,
    public dialogRef: MatDialogRef<ResidentModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      resident?: Resident;
      apartments?: Apartment[];
      presetApartment?: Apartment;
      owners: Company[];
    }
  ) {
    data.resident = data.resident ?? new Resident();
    data.apartments = data.apartments ?? [];
    data.owners = data.owners ?? [];
  }

  get f() {
    return this.form.controls;
  }

  ngOnInit() {
    this.apartmentCtrl.setValue(this.data.presetApartment?.id ?? (this.data.resident.apartment as Apartment)?.id ?? undefined);

    this.ownerCtrl.setValidators(Validators.required);
    this.ownerCtrl.setValue((this.data.presetApartment?.owner as Company)?.id ?? this.data.resident.owner?.id ?? this.loginService.getCompany());

    this.form = this.formBuilder.group({
      firstName: [this.data.resident.firstName, Validators.required],
      lastName: [this.data.resident.lastName, Validators.required],
      email: [this.data.resident.email, [Validators.email]],
      phone: [this.data.resident.phone, [PhoneNumberValidator('FI')]],
      apartment: this.apartmentCtrl,
      owner: this.ownerCtrl,
    });

    this.filteredApartments.next(this.data.apartments.slice());
    this.filteredOwners.next(this.data.owners.slice());

    this.apartmentFilterCtrl.valueChanges.subscribe(() => {
      FilterByField()(this.filteredApartments, this.data.apartments, 'fullAddress', this.apartmentFilterCtrl.value?.toLowerCase());
    });

    this.ownerFilterCtrl.valueChanges.subscribe(() => {
      FilterByField()(this.filteredOwners, this.data.owners, 'name', this.ownerFilterCtrl.value?.toLowerCase());
    });
  }

  save() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.data.resident.firstName = this.f.firstName.value;
    this.data.resident.lastName = this.f.lastName.value;
    this.data.resident.email = this.f.email.value;
    this.data.resident.phone = this.f.phone.value;
    this.data.resident.apartmentId = this.f.apartment.value;
    this.data.resident.ownerId = this.f.owner.value;
    this.saveResident(this.data.resident);
  }

  saveResident(resident: Resident) {
    const isNew = !resident.id;

    this.residentService.upsertResident(resident).subscribe({
      next: (result) => {
        this.snackService.showMessage(isNew ? 'resident.created' : 'resident.updated');
        this.dialogRef.close(result);
      },
      error: (error) => {
        this.snackService.showGeneralError();
        console.error(error);
      },
    });
  }
}
