const agreement = `<html>
   <head>
      <style>
        body {
         font-family: Arial, sans-serif;
         font-size: 12px;
         line-height: 1.5;
         padding: 15px;
        }
        h1 {
         font-size: 20px;
         margin-bottom: 20px;
        }
        h2 {
         font-size: 16px;
         margin-top: 20px;
         margin-bottom: 5px;
        }
        p {
         margin: 0;
        }
        div {
         width: 100%;
        }
        img {
         width: auto;
         height: 100px;
        }
        @page {
         size: A4;
         margin: 0;
        }
        html, body, #container {
         width: 210mm;
         height: 297mm;
        }
      </style>
   </head>
   <body>
      <div id="container">[CONTENT]</div>
   </body>
</html>`;

export default agreement;
