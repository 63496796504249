import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnnouncementService } from './services/announcement.service';
import { AnnouncementComponent } from './components/announcement/announcement.component';
import { AnnouncementModalComponent } from './components/announcement-modal/announcement-modal.component';
import { XTranslateModule } from '../x-translate/translate.module';
import { SharedModule } from '../shared/shared.module';
import { UiModule } from '../ui/ui.module';

@NgModule({
  declarations: [AnnouncementComponent, AnnouncementModalComponent],
  imports: [CommonModule, XTranslateModule, SharedModule, UiModule],
  providers: [AnnouncementService],
  exports: [AnnouncementComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AnnouncementModule {}
