import { Component, Input } from '@angular/core';
import { RouterService } from '../../../routing/services/router.service';
import { Router } from '@angular/router';

@Component({
  selector: 'xkey-go-back-button',
  templateUrl: './go-back-button.component.html',
  styleUrls: ['./go-back-button.component.scss'],
})
export class GoBackButtonComponent {
  @Input()
  defaultUrl: string;

  constructor(
    private routerService: RouterService,
    private router: Router
  ) {}

  goBack() {
    const previousUrl = this.routerService.getPreviousUrl(this.defaultUrl);
    this.router.navigateByUrl(previousUrl).then(() => {});
  }
}
