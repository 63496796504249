import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { KeyService } from '../../services/key.service';
import { Key } from '../../../../models/key';
import { KeyModalComponent } from '../key-modal/key-modal.component';
import { Apartment } from '../../../../models/apartment';
import { ApartmentService } from '../../../apartment/services/apartment.service';
import { Condominium } from '../../../../models/condominium';
import { CondominiumService } from '../../../condominium/services/condominium.service';
import { UserService } from '../../../user/services/user.service';
import { User } from '../../../../models/user';
import { GiveKeyModalComponent } from '../give-key-modal/give-key-modal.component';
import { LoginService } from '../../../auth/services/login.service';
import { LockTypeService } from '../../../control-panel/services/lock-type.service';
import { LockType } from '../../../../models/lockType';
import { KeyType } from '../../../../models/keyType';
import { ConfirmModalComponent } from '../../../ui/components/confirm-modal/confirm-modal.component';
import { ReturnKeyModalComponent } from '../return-key-modal/return-key-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { OpenersService } from '../../../ui/services/openers.service';
import { KeyTypeService } from '../../../control-panel/services/key-type.service';
import { HandsetObserverService } from '../../../ui/services/handset-observer.service';
import { DefaultFilterPredicate } from '../../../../helpers/DefaultFilterPredicate';
import { AnimationTriggerMetadata } from '../../../../helpers/AnimationTriggerMetadata';
import { DialogService } from '../../../ui/services/dialog.service';
import { DefaultSortingDataAccessor } from '../../../../helpers/DefaultSortingDataAccessor';
import { CompanyService } from '../../../company/services/company.service';
import { Company } from '../../../../models/company';

@Component({
  selector: 'xkey-key',
  templateUrl: './key.component.html',
  styleUrls: ['./key.component.scss'],
  animations: [AnimationTriggerMetadata()],
})
export class KeyComponent implements OnInit, OnChanges {
  showLostKeys = false;
  showLateKeys = false;
  dataSource = new MatTableDataSource<Key>([]);
  displayedColumnsDesktop = ['select', 'id', 'apartmentOrCondominium', 'type', 'keyLockType', 'stamp', 'holder', 'originalHolder', 'open'];
  displayedColumnsMobile = ['arrow', 'select', 'apartmentOrCondominium', 'open'];
  expandedElement: Key | null;
  selection = new SelectionModel<Key>(true, []);
  pageSize = 50;

  @Input() fromDashboard = false;
  @Input() apartment: Apartment;
  @Input() user: User;
  @Input() condominium: Condominium;
  @Input() showLateOnly = false;
  @Input() showLostOnly = false;
  @Input() title = '';
  @Input() fromSearch = false;
  @Output() elementExpansionChanged = new EventEmitter();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private keyService: KeyService,
    private apartmentService: ApartmentService,
    private condominiumService: CondominiumService,
    private userService: UserService,
    private lockTypeService: LockTypeService,
    private keyTypeService: KeyTypeService,
    public handsetObserverService: HandsetObserverService,
    public loginService: LoginService,
    public openers: OpenersService,
    private translate: TranslateService,
    private dialogService: DialogService,
    private companyService: CompanyService
  ) {}

  private _keys: Key[] = [];

  get keys(): Key[] {
    return this._keys;
  }

  @Input()
  set keys(val: Key[]) {
    this._keys = val;

    if (this.fromSearch) {
      this.setupComponent().then();
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if ((changes.condominium && !changes.condominium.firstChange) || (changes.apartment && !changes.apartment.firstChange) || (changes.user && !changes.user.firstChange)) {
      await this.setupComponent();
    }
  }

  async ngOnInit(): Promise<void> {
    if (this.fromDashboard) {
      this.pageSize = 3;
      this.displayedColumnsDesktop = ['apartmentOrCondominium', 'holder', 'stamp', 'open'];
      this.displayedColumnsMobile = ['arrow', 'apartmentOrCondominium', 'open'];
    }

    if (this.fromSearch) {
      this.pageSize = 5;
    }

    await this.setupComponent();
  }

  async setupComponent() {
    await this.initTable();
  }

  async fetchKeys() {
    switch (true) {
      case !!this.apartment:
        this.keys = await lastValueFrom(this.apartmentService.getApartmentKeys(this.apartment?.id));
        break;
      case !!this.user:
        this.keys = await lastValueFrom(this.userService.getUserKeys(this.user?.id));
        break;
      case !!this.condominium:
        this.keys = await lastValueFrom(this.condominiumService.getCondominiumKeys(this.condominium?.id, false));
        break;
      case this.fromDashboard && !this.showLostOnly && !this.showLateOnly:
        this.keys = await lastValueFrom(this.userService.getUserKeys(this.loginService.getCurrentUserId()));
        break;
      default:
        this.keys = await lastValueFrom(this.keyService.getKeys());
        break;
    }

    if (this.showLateOnly || this.showLostOnly) {
      this.filterOnlyLateOrOnlyLost();
    }
  }

  filterOnlyLateOrOnlyLost() {
    this.keys = this.keys.filter((key) => {
      if (this.showLateOnly) {
        return key.dueDate ? new Date(key.dueDate) < new Date() : false;
      } else if (this.showLostOnly) {
        return key.lost === true;
      }

      return true;
    });
  }

  async initTable() {
    if (!this.fromSearch) {
      await this.fetchKeys();
    }

    this.dataSource = new MatTableDataSource(this.keys);
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = DefaultFilterPredicate();
    this.dataSource.sortingDataAccessor = DefaultSortingDataAccessor();
    this.dataSource.sort = this.sort;
    this.selection.clear();
  }

  openGiveKeysModal(keys: number[]) {
    this.dialogService.showDialog(GiveKeyModalComponent, { apartment: this.apartment, keys }, async () => {
      this.selection.clear();
      await this.setupComponent();
    });
  }

  openReturnKeysModal(keys: number[]) {
    this.dialogService.showDialog(ReturnKeyModalComponent, { keys }, async () => {
      this.selection.clear();
      await this.setupComponent();
    });
  }

  openCreateModal = async () => {
    const lockTypes: LockType[] = await lastValueFrom(this.lockTypeService.getLockTypes());
    const keyTypes: KeyType[] = await lastValueFrom(this.keyTypeService.getKeyTypes());
    const apartments: Apartment[] = await lastValueFrom(this.apartmentService.getApartments());
    const condominia: Condominium[] = await lastValueFrom(this.condominiumService.getCondominia());
    const owners: Company[] = await lastValueFrom(this.companyService.getCompanies());

    this.dialogService.showDialog(
      KeyModalComponent,
      {
        lockTypes,
        keyTypes,
        apartments,
        condominia,
        owners,
        presetCondominium: this.condominium ?? undefined,
        presetApartment: this.apartment ?? undefined,
        presetLockType: this.condominium ? this.condominium.lockType : this.apartment?.condominium?.lockType ? lockTypes.find((lt) => lt.id === this.apartment?.condominium?.lockType?.id) : undefined,
        presetKeyType: this.condominium ? this.condominium.keyType : this.apartment?.condominium?.apartmentKeyType,
      },
      async () => {
        await this.setupComponent();
      }
    );
  };

  deleteSelected() {
    this.dialogService.showDialog(ConfirmModalComponent, { modalClass: 'modal-key', title: this.translate.instant('key.deleteKey') }, async () => {
      const deletePromises = this.selection.selected.map((key) => firstValueFrom(this.keyService.deleteKey(key.id)));
      await Promise.all(deletePromises);
      await this.setupComponent();
    });
  }

  giveSelected() {
    const ids = this.selection.selected.map((s) => s.id);
    this.openGiveKeysModal(ids);
  }

  returnSelected() {
    const suitableKeys = this.selection.selected.filter((key: Key) => {
      return key.currentHolder || key.currentHolderInternal || key.currentHolderOther;
    });

    const ids = suitableKeys.map((k) => k.id);
    this.openReturnKeysModal(ids);
  }

  isSelectedGiven() {
    const selected = this.selection.selected;

    if (!selected.length) {
      return false;
    }

    const suitable = selected.filter((key) => key.currentHolder || key.currentHolderInternal || key.currentHolderOther);
    return selected.length === suitable.length;
  }

  isSelectedAvailable() {
    const selected = this.selection.selected;

    if (!selected.length) {
      return false;
    }

    const suitable = selected.filter((key) => this.keyCanBeGiven(key));
    const areAllAvailable = selected.length === suitable.length;

    if (areAllAvailable) {
      const neededCondominium = suitable[0].condominium ? suitable[0].condominium : (suitable[0].apartment as Apartment).condominium;
      return suitable.every((key) => {
        const condominium = key.condominium ? key.condominium : (key.apartment as Apartment).condominium;
        return condominium.id === neededCondominium.id;
      });
    }

    return false;
  }

  getRowClass = (row) => {
    return {
      'text-danger': row.lost,
      'text-warning': row.dueDate && new Date(row.dueDate) < new Date(),
    };
  };

  toggleShowLostKeys() {
    this.showLostKeys = !this.showLostKeys;
    this.showLateKeys = false;
    this.dataSource.data = this.showLostKeys ? this.keys.filter((key) => key.lost) : this.keys;
  }

  toggleShowLateKeys() {
    this.showLateKeys = !this.showLateKeys;
    this.showLostKeys = false;
    this.dataSource.data = this.showLateKeys ? this.keys.filter((key) => key.dueDate && new Date(key.dueDate) < new Date()) : this.keys;
  }

  keyCanBeGiven(selectedKey: Key) {
    const keyIsAlreadyGiven = !!selectedKey.currentHolder || !!selectedKey.currentHolderInternal || !!selectedKey.currentHolderOther;
    const keyCanBeReGiven = keyIsAlreadyGiven && selectedKey.canBeReGiven && this.loginService.userHasPermissions('Can_Regive_Keys') && selectedKey.originalHolder.id === this.loginService.getCurrentUserId();
    const userCanReGiveAnyKey = this.loginService.userHasPermissions('Can_Regive_Any_Key');
    return !keyIsAlreadyGiven || keyCanBeReGiven || userCanReGiveAnyKey;
  }

  applyFilter($event: KeyboardEvent) {
    const filterValue = ($event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  isAllSelected() {
    return this.selection.selected.length === this.dataSource.filteredData.length;
  }

  somethingIsSelected(): boolean {
    return this.selection.selected.length > 0;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.filteredData.forEach((row) => this.selection.select(row));
  }

  toggleExpansion(element) {
    this.expandedElement = this.expandedElement === element ? null : element;
    this.elementExpansionChanged.emit();
  }

  getCurrentHolderValue(selectedKey: Key) {
    return selectedKey.getHolderToString();
  }

  async openCurrentHolder(selectedKey: Key) {
    if (selectedKey.currentHolder) {
      await this.openers.openResident(selectedKey.currentHolder);
    } else if (selectedKey.currentHolderInternal) {
      await this.openers.openUser(selectedKey.currentHolderInternal);
    }
  }
}
