<div class="login-container">
  <div class="login-logo">
    <img [src]="environment.logo" alt="logo" height="50" width="90" />
  </div>
  <div class="login-form">
    <div class="main-div">
      <div class="panel">
        <h2>{{ 'forgot.header' | translate }}</h2>
      </div>
      <div *ngIf="codeWrong">
        {{ 'forgot.linkInvalid' | translate }}
      </div>
      <form (ngSubmit)="resetPassword()" *ngIf="!code || !codeWrong" [formGroup]="form" id="forgot">
        <mat-form-field *ngIf="!code" appearance="fill">
          <mat-label>{{ 'forgot.email' | translate }}</mat-label>
          <input formControlName="email" matInput placeholder="{{ 'forgot.email' | translate }}" type="email" />
        </mat-form-field>

        <mat-form-field *ngIf="code" appearance="fill">
          <mat-label>{{ 'forgot.password' | translate }}</mat-label>
          <input [matTooltip]="'forgot.passwordHint' | translate" autocomplete="new-password" formControlName="password" matInput placeholder="{{ 'forgot.password' | translate }}" type="password" />
        </mat-form-field>

        <mat-form-field *ngIf="code" appearance="fill">
          <mat-label>{{ 'forgot.passwordAgain' | translate }}</mat-label>
          <input autocomplete="new-password" formControlName="passwordAgain" matInput placeholder="{{ 'forgot.passwordAgain' | translate }}" type="password" />
          <mat-error *ngIf="f.passwordAgain.errors">{{ 'forgot.passwordsMustBeSame' | translate }}</mat-error>
        </mat-form-field>

        <button color="primary" mat-flat-button type="submit">{{ 'forgot.forgot' | translate }}</button>
      </form>

      <div class="back-to-login mt-4">
        <a routerLink="/login">{{ 'general.backToLogin' | translate }}</a>
      </div>
    </div>
  </div>
</div>
