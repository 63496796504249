import { Component, OnInit, ViewChild } from '@angular/core';
import { KeyTypeService } from '../../services/key-type.service';
import { KeyType } from '../../../../models/keyType';
import { KeyTypeModalComponent } from '../keytype-modal/key-type-modal.component';
import { LoginService } from '../../../auth/services/login.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { HandsetObserverService } from '../../../ui/services/handset-observer.service';
import { AnimationTriggerMetadata } from '../../../../helpers/AnimationTriggerMetadata';
import { ConfirmModalComponent } from '../../../ui/components/confirm-modal/confirm-modal.component';
import { SnackService } from '../../../ui/services/snack.service';
import { DialogService } from '../../../ui/services/dialog.service';

@Component({
  selector: 'xkey-key-type',
  templateUrl: './key-type.component.html',
  styleUrls: ['./key-type.component.scss'],
  animations: [AnimationTriggerMetadata()],
})
export class KeyTypeComponent implements OnInit {
  keyTypes: KeyType[] = [];
  dataSource = new MatTableDataSource<KeyType>([]);
  displayedColumnsDesktop = ['name', 'actions'];
  displayedColumnsMobile = ['arrow', 'name'];
  expandedElement: KeyType | null;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private keyTypeService: KeyTypeService,
    public handsetObserverService: HandsetObserverService,
    private translate: TranslateService,
    private snackService: SnackService,
    public loginService: LoginService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.getKeyTypes();
  }

  openCreateModifyModal(keyType?: KeyType) {
    this.dialogService.showDialog(KeyTypeModalComponent, { keyType }, () => {
      this.getKeyTypes();
    });
  }

  openDeleteModal(keyTypeId: number) {
    this.dialogService.showDialog(ConfirmModalComponent, { modalClass: 'modal-control-panel', title: this.translate.instant('keyType.deleteKeyType') }, () => {
      this.keyTypeService.deleteKeyType(keyTypeId).subscribe({
        next: () => {
          this.getKeyTypes();
        },
        error: (error) => {
          console.error(error);
          this.snackService.showGeneralError();
        },
      });
    });
  }

  applyFilter($event: KeyboardEvent) {
    const filterValue = ($event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  userHasAccess(keyType: KeyType) {
    const userHasRightCompany = keyType.ownerId ? this.loginService.userHasRightCompany(keyType.ownerId) : false;
    const userIsSuperAdmin = this.loginService.userHasPermissions('Super_Admin');

    return userHasRightCompany || userIsSuperAdmin;
  }

  private getKeyTypes() {
    this.keyTypeService.getKeyTypes().subscribe((result: [KeyType]) => {
      this.keyTypes = result;

      this.dataSource = new MatTableDataSource(this.keyTypes);
      this.dataSource.filter = '';
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
}
