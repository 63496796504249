import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { lastValueFrom, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) {}

  public setCookie(name: string, value: string, expireDays: number) {
    const d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    this.cookieService.put(name, value, { expires });
  }

  async setUserInfo(data) {
    this.setCookie('token', data.token, 7);
    this.setCookie('refreshToken', data.refreshToken, 7);
    localStorage.setItem('fullName', data.user.firstName + ' ' + data.user.lastName);
    localStorage.setItem('userId', data.user.id);
    localStorage.setItem('companyId', data.user.company.id);
    localStorage.setItem('usersCompanyAndSubCompanies', data.usersCompanyAndSubCompanies);

    const permissions = <string[]>await lastValueFrom(this.http.get(environment.serverUrls[window.location.hostname] + 'roles/' + data.user.role.id + '/permissions'));
    localStorage.setItem('permissions', permissions.join(','));
  }

  login(credentials) {
    return this.http.post(environment.serverUrls[window.location.hostname] + 'users/login', credentials).pipe(
      tap(async (result: any) => {
        await this.setUserInfo(result);
      })
    );
  }

  public refresh(refreshToken: string, token: string) {
    return this.http.post(environment.serverUrls[window.location.hostname] + 'users/refresh', { refreshToken, token }).pipe(
      tap(async (result: any) => {
        await this.setUserInfo(result);
      })
    );
  }

  register(user, invitationCode) {
    return this.http.post(environment.serverUrls[window.location.hostname] + 'users/register/' + invitationCode, user).pipe(
      tap(async (result: any) => {
        await this.setUserInfo(result);
      })
    );
  }

  logout() {
    this.cookieService.remove('token');
    this.cookieService.remove('refreshToken');
    localStorage.removeItem('permissions');
    localStorage.removeItem('fullName');
    localStorage.removeItem('userId');
    localStorage.removeItem('companyId');
    localStorage.removeItem('usersCompanyAndSubCompanies');
  }

  getCurrentUserId(): number {
    return parseInt(localStorage.getItem('userId'), 10);
  }

  getToken() {
    return this.cookieService.get('token');
  }

  getRefreshToken(): string {
    return this.cookieService.get('refreshToken');
  }

  getCompany(): number | undefined {
    return localStorage.getItem('companyId') ? parseInt(localStorage.getItem('companyId'), 10) : undefined;
  }

  userHasPermissions(...permission: string[]) {
    return localStorage.getItem('permissions') && permission.every((p) => localStorage.getItem('permissions').indexOf(p) > -1);
  }

  userHasSomeOfThePermissions(...permission: string[]) {
    return localStorage.getItem('permissions') && permission.some((p) => localStorage.getItem('permissions').indexOf(p) > -1);
  }

  userHasRightCompany(companyId: number) {
    return localStorage.getItem('usersCompanyAndSubCompanies').split(',').indexOf(companyId.toString()) > -1;
  }

  getResetPassword(code: string) {
    return this.http.get(environment.serverUrls[window.location.hostname] + 'users/reset-password/' + code);
  }

  resetPassword(values: any) {
    return this.http.post(environment.serverUrls[window.location.hostname] + 'users/reset-password', values);
  }

  isLoggedIn(): Observable<boolean> {
    return of(!!this.getToken());
  }
}
