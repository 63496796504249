import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { XTranslateModule } from '../x-translate/translate.module';
import { SharedModule } from '../shared/shared.module';
import { CondominiumService } from './services/condominium.service';
import { CondominiumComponent } from './components/condominium/condominium.component';
import { CondominiumKeysComponent } from './components/condominiumKeys/condominiumKeys.component';
import { CondominiumModalComponent } from './components/condominium-modal/condominium-modal.component';
import { CondominiumSingleComponent } from './components/condominium-single/condominium-single.component';
import { CondominiumFilesComponent } from './components/condominium-files/condominium-files.component';
import { UiModule } from '../ui/ui.module';
import { ApartmentModule } from '../apartment/apartment.module';
import { KeyModule } from '../key/key.module';
import { CondominiumTransferModalComponent } from './components/condominium-transfer-modal/condominium-transfer-modal.component';
import { ResidentModule } from '../resident/resident.module';
import { ResidentialPropertyComponent } from './components/residential-property/residential-property.component';

@NgModule({
  declarations: [CondominiumComponent, CondominiumKeysComponent, CondominiumModalComponent, CondominiumSingleComponent, CondominiumFilesComponent, CondominiumTransferModalComponent, ResidentialPropertyComponent],
  imports: [CommonModule, XTranslateModule, SharedModule, UiModule, ApartmentModule, KeyModule, ResidentModule],
  providers: [CondominiumService],
  exports: [CondominiumComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CondominiumModule {}
