import { Component } from '@angular/core';
import { AnimationTriggerMetadata } from '../../../../helpers/AnimationTriggerMetadata';
import { LoginService } from '../../../auth/services/login.service';
import { Location } from '@angular/common';

@Component({
  selector: 'xkey-residential-property',
  templateUrl: './residential-property.component.html',
  styleUrls: ['./residential-property.component.scss'],
  animations: [AnimationTriggerMetadata()],
})
export class ResidentialPropertyComponent {
  tabIndex = 0;

  constructor(
    public loginService: LoginService,
    private location: Location
  ) {}

  tabChanged(event) {
    this.tabIndex = event.index;
    this.location.go('/kohteet/' + this.tabIndex);
  }
}
