import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { LoginService } from '../services/login.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private loginService: LoginService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.loginService.getToken();

    if (token) {
      request = request.clone({
        setHeaders: {
          authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status === 401 && !request.url.endsWith('/login')) {
          return this.handle401Error(request, next);
        } else if (error.status === 403 && request.url.endsWith('/refresh')) {
          this.loginService.logout();
          this.router.navigate(['/login']).then();
        }

        return throwError(() => error);
      })
    );
  }

  handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const refreshToken = this.loginService.getRefreshToken();

    if (!refreshToken) {
      this.loginService.logout();
      this.router.navigate(['/login']).then();
      return throwError(() => 'Refresh token not found');
    }

    return this.loginService.refresh(refreshToken, this.loginService.getToken()).pipe(
      switchMap((response) => {
        this.loginService.setCookie('token', response.token, 7);

        request = request.clone({
          setHeaders: {
            authorization: `Bearer ${response.token}`,
          },
        });

        return next.handle(request);
      }),
      catchError((error) => {
        this.loginService.logout();
        this.router.navigate(['/login']).then();
        return throwError(() => error);
      })
    );
  }
}
