import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KeyService } from './services/key.service';
import { KeyComponent } from './components/key/key.component';
import { KeyModalComponent } from './components/key-modal/key-modal.component';
import { XTranslateModule } from '../x-translate/translate.module';
import { SharedModule } from '../shared/shared.module';
import { ChangeDueDateModalComponent } from './components/change-due-date-modal/change-due-date-modal.component';
import { GiveKeyModalComponent } from './components/give-key-modal/give-key-modal.component';
import { ReturnKeyModalComponent } from './components/return-key-modal/return-key-modal.component';
import { KeySingleComponent } from './components/key-single/key-single.component';
import { KeyHistoryService } from './services/keyhistory.service';
import { KeyHistoryComponent } from './components/keyhistory/keyhistory.component';
import { KeyHistorySingleComponent } from './components/key-history-single/key-history-single.component';
import { UiModule } from '../ui/ui.module';
import { MarkdownModule } from 'ngx-markdown';

@NgModule({
  declarations: [KeyComponent, KeyModalComponent, KeySingleComponent, ChangeDueDateModalComponent, GiveKeyModalComponent, ReturnKeyModalComponent, KeyHistoryComponent, KeyHistorySingleComponent],
  imports: [CommonModule, XTranslateModule, SharedModule, UiModule, MarkdownModule.forRoot()],
  providers: [KeyService, KeyHistoryService],
  exports: [KeyComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class KeyModule {}
