import { AbstractControl, ValidatorFn } from '@angular/forms';

export function FieldGreaterThanValidator(fieldName: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const group = control.parent;
    const fieldToCompare = group?.get(fieldName);

    if (!fieldToCompare) {
      return null;
    }

    const isGreaterThan = Number(fieldToCompare.value) > Number(control.value);
    return isGreaterThan ? { lessThan: { field: fieldName, value: control.value } } : null;
  };
}
