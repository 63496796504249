<ng-container>
  <div class="modal-header">
    <h2 class="modal-title" mat-dialog-title>{{ data.invitation.id ? ('invitation.edit' | translate) : ('invitation.create' | translate) }}</h2>
  </div>
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="form">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'invitation.role' | translate }}</mat-label>
        <mat-select [formControl]="roleCtrl" placeholder="{{ 'invitation.role' | translate }}">
          <mat-option>
            <ngx-mat-select-search [formControl]="roleFilterCtrl" [noEntriesFoundLabel]="'general.noEntriesFound' | translate" [placeholderLabel]="'general.selectSearch' | translate"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let role of filteredRoles | async" [value]="role.id">
            {{ role.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="f.role.errors && f.role.errors.required">{{ 'role.roleIsRequired' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'invitation.company' | translate }}</mat-label>
        <mat-select [formControl]="companyCtrl" placeholder="{{ 'invitation.company' | translate }}">
          <mat-option>
            <ngx-mat-select-search [formControl]="companyFilterCtrl" [noEntriesFoundLabel]="'general.noEntriesFound' | translate" [placeholderLabel]="'general.selectSearch' | translate"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let company of filteredCompanies | async" [value]="company.id">
            {{ company.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="f.company.errors && f.company.errors.required">{{ 'company.companyIsRequired' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'invitation.email' | translate }}</mat-label>
        <input autocomplete="email" formControlName="email" matInput placeholder="{{ 'invitation.email' | translate }}" type="email" />
        <mat-error *ngIf="f.email.errors">{{ 'invitation.emailInWrongFormat' | translate }}</mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button [mat-dialog-close]="false" color="primary" mat-flat-button>{{ 'general.close' | translate }}</button>
    <button (click)="save()" color="accent" mat-flat-button>{{ 'general.save' | translate }}</button>
  </mat-dialog-actions>
</ng-container>
