import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { XTranslateModule } from '../x-translate/translate.module';
import { SharedModule } from '../shared/shared.module';
import { ResidentService } from './services/resident.service';
import { ResidentComponent } from './components/resident/resident.component';
import { ResidentModalComponent } from './components/resident-modal/resident-modal.component';
import { ResidentSingleComponent } from './components/resident-single/resident-single.component';
import { UiModule } from '../ui/ui.module';

@NgModule({
  declarations: [ResidentComponent, ResidentModalComponent, ResidentSingleComponent],
  imports: [CommonModule, XTranslateModule, SharedModule, UiModule],
  providers: [ResidentService],
  exports: [ResidentComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ResidentModule {}
