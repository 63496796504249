import { Injectable } from '@angular/core';
import { GeneralService } from '../../../services/general.service';
import { Role } from '../../../models/role';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(private general: GeneralService) {}

  getRoles(): Observable<Role[]> {
    return this.general.get('roles');
  }

  deleteRole(id): Observable<void> {
    return this.general.delete('roles/' + id);
  }

  updateRole(role: Role): Observable<Role> {
    return this.general.update('roles', role);
  }

  createRole(role: Role): Observable<Role> {
    return this.general.post('roles', role);
  }

  upsertRole(role: Role): Observable<Role> {
    return role.id ? this.updateRole(role) : this.createRole(role);
  }
}
