<xkey-progress-spinner [displayProgressSpinner]="loadingService.isLoading$ | async" />

<ng-container *ngIf="isLoggedIn">
  <xkey-search-bar></xkey-search-bar>
  <xkey-navbar></xkey-navbar>
</ng-container>

<main>
  <article class="main-container">
    <div *ngIf="isLoggedIn || isInLogin || isInRegister || isInForgotPassword || isInPrivacyPolicy" [ngClass]="{ 'login-element': !isLoggedIn && (isInLogin || isInRegister), 'main-element': isLoggedIn }" class="container">
      <router-outlet></router-outlet>
    </div>
  </article>
</main>
