import { Injectable } from '@angular/core';
import { GeneralService } from '../../../services/general.service';
import { Permission } from '../../../models/permission';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(private general: GeneralService) {}

  getPermissions(): Observable<Permission[]> {
    return this.general.get('permissions');
  }

  deletePermission(id): Observable<void> {
    return this.general.delete('permissions/' + id);
  }

  updatePermission(permission: Permission): Observable<Permission> {
    return this.general.update('permissions', permission);
  }

  createPermission(permission: Permission): Observable<Permission> {
    return this.general.post('permissions', permission);
  }

  upsertPermission(permission: Permission): Observable<Permission> {
    return permission.id ? this.updatePermission(permission) : this.createPermission(permission);
  }
}
