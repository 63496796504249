import { Component, OnInit, ViewChild } from '@angular/core';
import { PermissionService } from '../../services/permission.service';
import { Permission } from '../../../../models/permission';
import { PermissionModalComponent } from '../permission-modal/permission-modal.component';
import { LoginService } from '../../../auth/services/login.service';
import { ConfirmModalComponent } from '../../../ui/components/confirm-modal/confirm-modal.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { HandsetObserverService } from '../../../ui/services/handset-observer.service';
import { AnimationTriggerMetadata } from '../../../../helpers/AnimationTriggerMetadata';
import { DialogService } from '../../../ui/services/dialog.service';

@Component({
  selector: 'xkey-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.scss'],
  animations: [AnimationTriggerMetadata()],
})
export class PermissionComponent implements OnInit {
  permissions: Permission[] = [];
  dataSource = new MatTableDataSource<Permission>([]);
  displayedColumnsDesktop = ['name', 'actions'];
  displayedColumnsMobile = ['arrow', 'name'];
  expandedElement: Permission | null;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private permissionService: PermissionService,
    public handsetObserverService: HandsetObserverService,
    private translate: TranslateService,
    public loginService: LoginService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.getPermissions();
  }

  openCreateModifyModal(permission?: Permission) {
    this.dialogService.showDialog(PermissionModalComponent, { permission }, () => {
      this.getPermissions();
    });
  }

  openDeleteModal(permissionId: number) {
    this.dialogService.showDialog(ConfirmModalComponent, { modalClass: 'modal-control-panel', title: this.translate.instant('permission.deletePermission') }, () => {
      this.permissionService.deletePermission(permissionId).subscribe(() => {
        this.getPermissions();
      });
    });
  }

  applyFilter($event: KeyboardEvent) {
    const filterValue = ($event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  private getPermissions() {
    this.permissionService.getPermissions().subscribe((result: [Permission]) => {
      this.permissions = result;
      this.dataSource = new MatTableDataSource(this.permissions);
      this.dataSource.filter = '';
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
}
