import { Component, Input, OnInit } from '@angular/core';
import { ApartmentService } from '../../services/apartment.service';
import { CondominiumService } from '../../../condominium/services/condominium.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { OpenersService } from '../../../ui/services/openers.service';
import { LoginService } from '../../../auth/services/login.service';
import { Apartment } from '../../../../models/apartment';
import { Condominium } from '../../../../models/condominium';
import { ApartmentModalComponent } from '../apartment-modal/apartment-modal.component';
import { ConfirmModalComponent } from '../../../ui/components/confirm-modal/confirm-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { DialogService } from '../../../ui/services/dialog.service';
import { HandsetObserverService } from '../../../ui/services/handset-observer.service';
import { CompanyService } from '../../../company/services/company.service';
import { Company } from '../../../../models/company';

@Component({
  selector: 'xkey-apartment-single',
  templateUrl: './apartment-single.component.html',
  styleUrls: ['./apartment-single.component.scss'],
})
export class ApartmentSingleComponent implements OnInit {
  @Input()
  selectedApartment?: Apartment;

  tabIndex = 2;

  constructor(
    private apartmentService: ApartmentService,
    private condominiumService: CondominiumService,
    private companyService: CompanyService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private translate: TranslateService,
    public openers: OpenersService,
    public loginService: LoginService,
    private dialogService: DialogService,
    public handsetObserverService: HandsetObserverService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(async (params) => {
      await this.setupComponent(params['id']);
    });

    const tab = this.route.snapshot.paramMap.get('tab');
    if (tab) {
      this.tabIndex = parseInt(tab, 10);
    }
  }

  async setupComponent(id) {
    this.apartmentService.getApartment(id).subscribe((apartment: Apartment) => {
      this.selectedApartment = apartment;
    });
  }

  async openModifyModal(apartment: Apartment) {
    const condominia: Condominium[] = await lastValueFrom(this.condominiumService.getCondominia());
    const owners: Company[] = await lastValueFrom(this.companyService.getCompanies());

    this.dialogService.showDialog(ApartmentModalComponent, { apartment: { ...apartment }, condominia, owners }, () => {
      this.apartmentService.getApartment(this.selectedApartment.id).subscribe((updatedApartment: Apartment) => {
        this.selectedApartment = updatedApartment;
      });
    });
  }

  openDeleteModal(apartmentId: number) {
    this.dialogService.showDialog(ConfirmModalComponent, { modalClass: 'modal-apartment', title: this.translate.instant('apartment.deleteApartment') }, async () => {
      this.apartmentService.deleteApartment(apartmentId).subscribe(() => {
        this.location.back();
      });
    });
  }

  tabChanged(event) {
    this.tabIndex = event.index;
    this.location.go('/asunnot/' + this.selectedApartment.id + '/' + this.tabIndex);
  }
}
