<div *ngIf="selectedApartment">
  <div class="page-header">
    <h2>
      <mat-icon class="mobile-hidden">apartment</mat-icon>
      <span (click)="openers.openCondominium(selectedApartment.condominium)" class="clickable mobile-hidden">{{ selectedApartment.condominium.name }}</span>
      <mat-icon class="ms-2 mobile-hidden">chevron_right</mat-icon>
      <mat-icon>house</mat-icon>
      {{ 'apartment.headerSingle' | translate }} {{ selectedApartment.fullAddress }}
    </h2>
  </div>

  <div class="card main-card">
    <div *ngIf="!(handsetObserverService.isSmallScreen$ | async)" class="buttons">
      <ng-container *ngTemplateOutlet="buttons"></ng-container>
    </div>

    <mat-accordion *ngIf="handsetObserverService.isSmallScreen$ | async">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'general.actions' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="buttons"></ng-container>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-tab-group (selectedTabChange)="tabChanged($event)" [(selectedIndex)]="tabIndex" mat-stretch-tabs>
      <mat-tab *ngIf="loginService.userHasPermissions('Apartment_Read')" [label]="'general.basicInformation' | translate">
        <ng-template matTabContent>
          <div class="card basic-info">
            <div class="row">
              <div class="col-sm-6">
                <xkey-basic-info-field [title]="'apartment.address'" [value]="selectedApartment.addressStreet + ', ' + selectedApartment.addressPostalCode + ' ' + selectedApartment.addressCity"></xkey-basic-info-field>
                <xkey-basic-info-field (click)="openers.openCondominium(selectedApartment.condominium)" [isClickable]="true" [title]="'apartment.condominium'" [value]="selectedApartment.condominium?.name"></xkey-basic-info-field>
                <xkey-basic-info-field (click)="openers.openCompany(selectedApartment.owner)" [isClickable]="true" [title]="'apartment.owner'" [value]="selectedApartment.owner?.name"></xkey-basic-info-field>
              </div>
              <div class="col-sm-6">
                <xkey-map [addressCity]="selectedApartment.addressCity" [addressPostalCode]="selectedApartment.addressPostalCode" [addressStreet]="selectedApartment.addressStreet"></xkey-map>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab *ngIf="loginService.userHasPermissions('Resident_Read')" [label]="'resident.header' | translate">
        <xkey-resident [apartment]="selectedApartment"></xkey-resident>
      </mat-tab>
      <mat-tab *ngIf="loginService.userHasPermissions('Key_Read')" [label]="'key.header' | translate">
        <xkey-key [apartment]="selectedApartment"></xkey-key>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<ng-template #buttons>
  <xkey-go-back-button [defaultUrl]="'/kiinteistot/' + selectedApartment.condominiumId"></xkey-go-back-button>
  <button (click)="openModifyModal(selectedApartment)" [disabled]="!loginService.userHasPermissions('Apartment_Update')" class="block-on-mobile" color="accent" mat-flat-button>
    <mat-icon>edit</mat-icon>
    {{ 'general.edit' | translate }}
  </button>
  <button (click)="openDeleteModal(selectedApartment.id)" [disabled]="!loginService.userHasPermissions('Apartment_Delete')" class="block-on-mobile" color="warn" mat-flat-button>
    <mat-icon>delete</mat-icon>
    {{ 'general.delete' | translate }}
  </button>
</ng-template>
