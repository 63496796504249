<nav #navcontainer class="navbar">
  <div (click)="scrollLeft()" *ngIf="showArrowLeft" class="arrow-left">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </div>
  <ul class="navbar-nav">
    <li class="logo">
      <a class="nav-link" href="#">
        <span class="link-text logo-text">
          <img [src]="environment.logo" alt="logo" height="50" width="90" />
        </span>
        <mat-icon>double_arrow</mat-icon>
      </a>
    </li>

    <li *ngIf="loginService.userHasPermissions('Dashboard')" class="nav-item">
      <a [routerLinkActive]="['active-dashboard', 'active-link']" class="nav-link" routerLink="/etusivu">
        <mat-icon>dashboard</mat-icon>
        <span class="link-text">{{ 'dashboard.header' | translate }}</span>
      </a>
    </li>
    <li *ngIf="loginService.userHasPermissions('Company_Read')" class="nav-item">
      <a [routerLinkActive]="['active-companies', 'active-link']" class="nav-link" routerLink="/yritykset">
        <mat-icon>account_box</mat-icon>
        <span class="link-text">{{ 'user.sideHeader' | translate }}</span>
      </a>
    </li>
    <li *ngIf="loginService.userHasSomeOfThePermissions('Condominium_Read', 'Apartment_Read', 'Resident_Read')" class="nav-item">
      <a [routerLinkActive]="['active-condominia', 'active-link']" class="nav-link" routerLink="/kohteet">
        <mat-icon>apartment</mat-icon>
        <span class="link-text">{{ 'residentialProperty.header' | translate }}</span>
      </a>
    </li>
    <li *ngIf="loginService.userHasPermissions('Audit')" class="nav-item">
      <a [routerLinkActive]="['active-audit', 'active-link']" class="nav-link" routerLink="/seuranta">
        <mat-icon>topic</mat-icon>
        <span class="link-text">{{ 'audit.header' | translate }}</span>
      </a>
    </li>
    <li class="nav-item">
      <a [routerLinkActive]="['active-my-page', 'active-link']" class="nav-link" routerLink="/oma">
        <mat-icon>contact_page</mat-icon>
        <span class="link-text">{{ 'general.myPage' | translate }}</span>
      </a>
    </li>
    <li *ngIf="loginService.userHasPermissions('Control_Panel')" class="nav-item">
      <a [routerLinkActive]="['active-control-panel', 'active-link']" class="nav-link" routerLink="/hallinta">
        <mat-icon>settings</mat-icon>
        <span class="link-text">{{ 'controlPanel.header' | translate }}</span>
      </a>
    </li>
    <li class="nav-item">
      <a (click)="logout()" class="nav-link">
        <mat-icon>logout</mat-icon>
        <span class="link-text">{{ 'general.logout' | translate }}</span>
      </a>
    </li>
    <li *ngIf="handsetObserverService.isHandSet$ | async" class="nav-item">
      <a class="nav-link" href="https://tawk.to/chat/665837c7981b6c5647766164/1hv49jpcp" target="_blank">
        <mat-icon>help</mat-icon>
        <span class="link-text">{{ 'customerService.header' | translate }}</span>
      </a>
    </li>
    <li class="nav-item">
      <a [routerLinkActive]="['active-control-panel', 'active-link']" class="nav-link" routerLink="/tietosuojaseloste">
        <mat-icon>info</mat-icon>
        <span class="link-text">{{ 'privacyPolicy.header' | translate }}</span>
      </a>
    </li>
    <li class="nav-item version-info">
      <span>&copy; xkey.fi - v.{{ appVersion }} (v.{{ backendVersion }})</span>
    </li>
  </ul>
  <div (click)="scrollRight()" *ngIf="showArrowRight" class="arrow-right">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </div>
</nav>
