import { Condominium } from './condominium';
import { Company } from './company';

export class Apartment {
  id: number;
  addressStreet: string;
  addressPostalCode: string;
  addressCity: string;
  condominiumId: number;
  condominium: Condominium;
  owner: Company;
  ownerId: number;
  firstNumber: number;
  lastNumber: number;
  fullAddress: string;
}
