import { Component, OnInit } from '@angular/core';
import { KeyHistoryService } from '../../services/keyhistory.service';
import { ResidentService } from '../../../resident/services/resident.service';
import { UserService } from '../../../user/services/user.service';
import { KeyService } from '../../services/key.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OpenersService } from '../../../ui/services/openers.service';
import { EventType, KeyHistory } from '../../../../models/keyhistory';
import { HandsetObserverService } from '../../../ui/services/handset-observer.service';

import showdown from 'showdown';
import agreement from './agreement';

@Component({
  selector: 'xkey-key-history-single',
  templateUrl: './key-history-single.component.html',
  styleUrls: ['./key-history-single.component.scss'],
})
export class KeyHistorySingleComponent implements OnInit {
  selectedKeyHistory: KeyHistory;

  constructor(
    private keyHistoryService: KeyHistoryService,
    private residentService: ResidentService,
    private userService: UserService,
    private keyService: KeyService,
    private router: Router,
    public handsetObserverService: HandsetObserverService,
    private route: ActivatedRoute,
    public openers: OpenersService
  ) {
    this.route.params.subscribe((params) => {
      this.setupComponent(params['id']);
    });
  }

  ngOnInit(): void {}

  setupComponent(id) {
    this.keyHistoryService.getKeyHistory(id).subscribe((keyHistory: KeyHistory) => {
      this.selectedKeyHistory = keyHistory;
    });
  }

  openMandate() {
    const image = new Image();
    image.src = this.selectedKeyHistory.mandate;

    const w = window.open('');
    w.document.write(image.outerHTML);
  }

  getEventType(event: number) {
    return EventType[event];
  }

  getCurrentHolderValue(selectedKeyHistory: KeyHistory): string {
    return selectedKeyHistory.getHolderToString();
  }

  async openCurrentHolder(selectedKeyHistory: KeyHistory) {
    if (selectedKeyHistory.resident) {
      await this.openers.openResident(selectedKeyHistory.resident);
    } else if (selectedKeyHistory.user) {
      await this.openers.openUser(selectedKeyHistory.user);
    }
  }

  async openCreator(selectedKeyHistory: KeyHistory) {
    await this.openers.openUser(selectedKeyHistory.createdBy);
  }

  async downloadAgreementAsPdf() {
    const converter = new showdown.Converter();

    const lender = this.selectedKeyHistory.resident || this.selectedKeyHistory.user || this.selectedKeyHistory.other;
    const residentialProperty = this.selectedKeyHistory.key.apartment || this.selectedKeyHistory.key.condominium;

    let convertedMarkdown = converter.makeHtml(this.selectedKeyHistory.keyAgreement);
    convertedMarkdown = agreement
      .replace('[CONTENT]', convertedMarkdown)
      .replace('[LENDER]', lender?.firstName + ' ' + lender?.lastName)
      .replace('[DATE]', new Date(this.selectedKeyHistory.date).toISOString().slice(0, 10))
      .replace('[DUE_DATE]', new Date(this.selectedKeyHistory.dueDate).toISOString().slice(0, 10))
      .replace('[RESIDENTIAL_PROPERTY]', residentialProperty?.fullAddress)
      .replace('[KEY_ID]', this.selectedKeyHistory.key.id.toString())
      .replace('[STAMP]', this.selectedKeyHistory.key.stamp || '-');
    const winUrl = URL.createObjectURL(new Blob(['\ufeff', convertedMarkdown], { type: 'text/html' }));
    window.open(winUrl);
  }
}
