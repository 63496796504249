<div class="card main-card">
  <div *ngIf="!(handsetObserverService.isSmallScreen$ | async)" class="buttons">
    <ng-container *ngTemplateOutlet="buttons"></ng-container>
  </div>

  <mat-accordion *ngIf="handsetObserverService.isSmallScreen$ | async">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'general.actions' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="buttons"></ng-container>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="datatable">
    <mat-form-field [floatLabel]="'always'" appearance="outline" class="full-width-search mt-2">
      <mat-label>{{ 'general.search' | translate }}</mat-label>
      <mat-icon matPrefix>search</mat-icon>
      <input (keyup)="applyFilter($event)" matInput />
    </mat-form-field>

    <ng-template #desktopTable>
      <mat-table [dataSource]="dataSource" [matSortDirection]="'asc'" matSort matSortActive="name" matSortDisableClear>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'permission.name' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.name }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>{{ 'general.actions' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button (click)="openCreateModifyModal(row)" [disabled]="!loginService.userHasPermissions('Control_Panel')" [matTooltip]="'general.edit' | translate" class="mx-1" color="accent" mat-flat-button>
              <mat-icon class="mx-0">edit</mat-icon>
            </button>
            <button (click)="openDeleteModal(row.id)" [disabled]="!loginService.userHasPermissions('Control_Panel')" [matTooltip]="'general.delete' | translate" class="mx-1" color="warn" mat-flat-button>
              <mat-icon class="mx-0">delete</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsDesktop"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsDesktop"></mat-row>
      </mat-table>
    </ng-template>

    <ng-template #mobileTable>
      <mat-table [dataSource]="dataSource" [matSortDirection]="'asc'" matSort matSortActive="name" matSortDisableClear multiTemplateDataRows>
        <ng-container matColumnDef="arrow">
          <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-icon>{{ expandedElement && expandedElement.id === row.id ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}</mat-icon>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'permission.name' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.name }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td *matCellDef="let element" [attr.colspan]="displayedColumnsMobile.length" mat-cell>
            <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'" class="element-detail">
              <div class="detail-buttons">
                <button (click)="openCreateModifyModal(element)" [disabled]="!loginService.userHasPermissions('Control_Panel')" class="block-on-mobile" color="accent" mat-flat-button>
                  <mat-icon>edit</mat-icon>
                  {{ 'general.edit' | translate }}
                </button>
                <button (click)="openDeleteModal(element.id)" [disabled]="!loginService.userHasPermissions('Control_Panel')" class="block-on-mobile" color="warn" mat-flat-button>
                  <mat-icon>delete</mat-icon>
                  {{ 'general.delete' | translate }}
                </button>
              </div>
            </div>
          </td>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsMobile"></mat-header-row>
        <mat-row (click)="expandedElement = expandedElement === element ? null : element" *matRowDef="let element; columns: displayedColumnsMobile" [class.expanded-row]="expandedElement === element" class="element-row"></mat-row>

        <tr
          *matRowDef="let row; columns: ['expandedDetail']"
          [ngClass]="{
            'expanded-detail': expandedElement && expandedElement.id == row.id,
            'closed-detail': !(expandedElement && expandedElement.id == row.id)
          }"
          class="detail-row"
          mat-row
        ></tr>
      </mat-table>
    </ng-template>

    <ng-container *ngIf="handsetObserverService.isHandSet$ | async">
      <ng-template [ngTemplateOutlet]="mobileTable"></ng-template>
    </ng-container>

    <ng-container *ngIf="!(handsetObserverService.isHandSet$ | async)">
      <ng-template [ngTemplateOutlet]="desktopTable"></ng-template>
    </ng-container>

    <mat-paginator #paginator [length]="dataSource.data.length" [pageIndex]="0" [pageSizeOptions]="[25, 50, 100, 250]" [pageSize]="50"> </mat-paginator>
  </div>
</div>

<ng-template #buttons>
  <button (click)="openCreateModifyModal()" class="block-on-mobile" color="primary" mat-flat-button>{{ 'permission.create' | translate }}</button>
</ng-template>
