import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Invitation } from '../../../../models/invitation';
import { InvitationService } from '../../services/invitation.service';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackService } from '../../../ui/services/snack.service';

@Component({
  selector: 'xkey-send-invitation-modal',
  templateUrl: './send-invitation-modal.component.html',
  styleUrls: ['./send-invitation-modal.component.scss'],
})
export class SendInvitationModalComponent implements OnInit {
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private invitationService: InvitationService,
    private translate: TranslateService,
    private snackService: SnackService,
    public dialogRef: MatDialogRef<SendInvitationModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      invitation?: Invitation;
    }
  ) {
    data.invitation = data.invitation ?? new Invitation();
  }

  get f() {
    return this.form.controls;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: [this.data.invitation.email ?? '', [Validators.required, Validators.email]],
    });
  }

  save() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.saveInvitation(this.f.email.value);
  }

  saveInvitation(emailAddress: string) {
    this.invitationService.sendInvitation(emailAddress, this.data.invitation.id).subscribe({
      next: (result) => {
        this.snackService.showMessage('invitation.sent');
        this.dialogRef.close(result);
      },
      error: (error) => {
        console.error(error);
        this.snackService.showGeneralError();
      },
    });
  }
}
