<div [ngClass]="getModalClass()">
  <div class="modal-header">
    <h2 class="modal-title" mat-dialog-title>{{ data.title }}</h2>
  </div>
  <mat-dialog-content class="mat-typography">
    {{ data.message }}
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button [mat-dialog-close]="false" color="primary" mat-flat-button>{{ 'general.cancel' | translate }}</button>
    <button (click)="accept()" color="accent" mat-flat-button>{{ 'general.ok' | translate }}</button>
  </mat-dialog-actions>
</div>
