import { Injectable } from '@angular/core';
import { GeneralService } from '../../../services/general.service';
import { Announcement } from '../../../models/announcement';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AnnouncementService {
  constructor(private general: GeneralService) {}

  getAnnouncements(): Observable<Announcement[]> {
    return this.general.get('announcements');
  }

  deleteAnnouncement(id): Observable<void> {
    return this.general.delete(`announcements/${id}`);
  }

  upsertAnnouncement(announcement: Announcement): Observable<Announcement> {
    return announcement.id ? this.updateAnnouncement(announcement) : this.createAnnouncement(announcement);
  }

  updateAnnouncement(announcement: Announcement): Observable<Announcement> {
    return this.general.update('announcements', announcement);
  }

  createAnnouncement(announcement: Announcement): Observable<Announcement> {
    return this.general.post('announcements', announcement);
  }
}
