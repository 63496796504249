<div class="login-container">
  <div class="login-logo">
    <img [src]="environment.logo" alt="logo" height="50" width="90" />
  </div>
  <div *ngIf="!user" class="login-form">
    <div class="main-div">
      <div class="panel">
        <h2>{{ 'login.header' | translate }}</h2>
      </div>
      <form (ngSubmit)="login()" *ngIf="!user" [formGroup]="form" id="login">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'login.email' | translate }}</mat-label>
          <input formControlName="email" matInput placeholder="{{ 'login.email' | translate }}" type="email" />
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>{{ 'login.password' | translate }}</mat-label>
          <input formControlName="password" matInput placeholder="{{ 'login.password' | translate }}" type="password" />
        </mat-form-field>

        <button color="primary" mat-flat-button type="submit">{{ 'login.login' | translate }}</button>
      </form>

      <div class="forgot-password mt-4">
        <a routerLink="/unohdin-salasanani">{{ 'general.forgotPassword' | translate }}</a>
      </div>
    </div>
  </div>
</div>
