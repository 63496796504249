import { Apartment } from './apartment';
import { Company } from './company';

export class Resident {
  id: number;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  apartmentId: number;
  apartment: Apartment;
  fullName: string;
  owner: Company;
  ownerId: number;
}
