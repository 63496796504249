import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { XTranslateModule } from '../x-translate/translate.module';
import { SharedModule } from '../shared/shared.module';
import { ApartmentService } from './services/apartment.service';
import { ApartmentComponent } from './components/apartment/apartment.component';
import { ApartmentSingleComponent } from './components/apartment-single/apartment-single.component';
import { ApartmentModalComponent } from './components/apartment-modal/apartment-modal.component';
import { UiModule } from '../ui/ui.module';
import { KeyModule } from '../key/key.module';
import { ResidentModule } from '../resident/resident.module';

@NgModule({
  declarations: [ApartmentComponent, ApartmentSingleComponent, ApartmentModalComponent],
  imports: [CommonModule, XTranslateModule, SharedModule, UiModule, KeyModule, ResidentModule],
  providers: [ApartmentService],
  exports: [ApartmentComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ApartmentModule {}
