import { Resident } from './resident';
import { User } from './user';
import { Key } from './key';
import { ExternalUser } from './externalUser';

export class KeyHistory {
  id: number;
  resident: Resident;
  user: User;
  other: ExternalUser;
  key: Key;
  event: EventType;
  date: Date;
  dueDate: Date;
  signature: string;
  mandate: string;
  createdBy: User;
  keyAgreement: string;

  constructor(data?: any) {
    Object.assign(this, data);
  }

  getApartmentOrCondominiumToString(): string {
    return this.key.condominium?.name ?? this.key.apartment?.fullAddress ?? '';
  }

  getHolderToString(): string {
    const holder = this.getHolderName();
    return holder ? `${holder.firstName} ${holder.lastName}` : '';
  }

  private getHolderName(): Resident | User | ExternalUser {
    return this.user ?? this.resident ?? this.other;
  }
}

export enum EventType {
  'Palautus',
  'Luovutus',
  'Kadonnut',
  'Loytynyt',
}
