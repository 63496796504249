import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuditComponent } from './components/audit/audit.component';
import { AuditSingleComponent } from './components/audit-single/audit-single.component';
import { XTranslateModule } from '../x-translate/translate.module';
import { SharedModule } from '../shared/shared.module';
import { UiModule } from '../ui/ui.module';

@NgModule({
  declarations: [AuditComponent, AuditSingleComponent],
  imports: [CommonModule, XTranslateModule, SharedModule, UiModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AuditModule {}
