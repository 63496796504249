import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Condominium } from '../../../../models/condominium';
import { CondominiumService } from '../../services/condominium.service';
import { User } from '../../../../models/user';
import { LockType } from '../../../../models/lockType';
import { KeyType } from '../../../../models/keyType';
import { ReplaySubject } from 'rxjs';
import { FilterByField } from '../../../../helpers/DropdownFilters';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackService } from '../../../ui/services/snack.service';
import { Company } from '../../../../models/company';
import { LoginService } from '../../../auth/services/login.service';

@Component({
  selector: 'xkey-condominium-modal',
  templateUrl: './condominium-modal.component.html',
  styleUrls: ['./condominium-modal.component.scss'],
})
export class CondominiumModalComponent implements OnInit {
  public superIntendentCtrl: FormControl = new FormControl();
  public superIntendentFilterCtrl: FormControl = new FormControl();
  public filteredSuperIntendents: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);

  public lockTypeCtrl: FormControl = new FormControl();
  public lockTypeFilterCtrl: FormControl = new FormControl();
  public filteredLockTypes: ReplaySubject<LockType[]> = new ReplaySubject<LockType[]>(1);

  public keyTypeCtrl: FormControl = new FormControl();
  public keyTypeFilterCtrl: FormControl = new FormControl();
  public filteredKeyTypes: ReplaySubject<KeyType[]> = new ReplaySubject<KeyType[]>(1);

  public apartmentKeyTypeCtrl: FormControl = new FormControl();
  public apartmentKeyTypeFilterCtrl: FormControl = new FormControl();
  public filteredApartmentKeyTypes: ReplaySubject<KeyType[]> = new ReplaySubject<KeyType[]>(1);

  public ownerCtrl: FormControl = new FormControl();
  public ownerFilterCtrl: FormControl = new FormControl();
  public filteredOwners: ReplaySubject<Company[]> = new ReplaySubject<Company[]>(1);

  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private condominiumService: CondominiumService,
    private snackService: SnackService,
    private loginService: LoginService,
    public dialogRef: MatDialogRef<CondominiumModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      condominium: Condominium;
      superIntendents: User[];
      lockTypes: LockType[];
      keyTypes: KeyType[];
      apartmentKeyTypes: KeyType[];
      presetSuperIntendent: User;
      owners: Company[];
    }
  ) {
    data.condominium = data.condominium ?? new Condominium();
    data.superIntendents = data.superIntendents ?? [];
    data.lockTypes = data.lockTypes ?? [];
    data.keyTypes = data.keyTypes ?? [];
    data.apartmentKeyTypes = data.apartmentKeyTypes ?? [];
    data.owners = data.owners ?? [];
  }

  get f() {
    return this.form.controls;
  }

  ngOnInit() {
    this.superIntendentCtrl.setValue(this.data.presetSuperIntendent?.id ?? (this.data.condominium.superIntendent as User)?.id ?? undefined);
    this.lockTypeCtrl.setValue((this.data.condominium.lockType as LockType)?.id ?? undefined);
    this.keyTypeCtrl.setValue((this.data.condominium.keyType as KeyType)?.id ?? undefined);
    this.apartmentKeyTypeCtrl.setValue((this.data.condominium.apartmentKeyType as KeyType)?.id ?? undefined);
    this.ownerCtrl.setValue((this.data.condominium?.owner as Company)?.id ?? this.loginService.getCompany());

    this.filteredSuperIntendents.next(this.data.superIntendents.slice());
    this.filteredLockTypes.next(this.data.lockTypes.slice());
    this.filteredKeyTypes.next(this.data.keyTypes.slice());
    this.filteredApartmentKeyTypes.next(this.data.apartmentKeyTypes.slice());
    this.filteredOwners.next(this.data.owners.slice());

    this.superIntendentFilterCtrl.valueChanges.subscribe(() => {
      FilterByField()(this.filteredSuperIntendents, this.data.superIntendents, 'fullName', this.superIntendentFilterCtrl.value?.toLowerCase());
    });

    this.lockTypeFilterCtrl.valueChanges.subscribe(() => {
      FilterByField()(this.filteredLockTypes, this.data.lockTypes, 'name', this.lockTypeFilterCtrl.value?.toLowerCase());
    });

    this.keyTypeFilterCtrl.valueChanges.subscribe(() => {
      FilterByField()(this.filteredKeyTypes, this.data.keyTypes, 'name', this.keyTypeFilterCtrl.value?.toLowerCase());
    });

    this.apartmentKeyTypeFilterCtrl.valueChanges.subscribe(() => {
      FilterByField()(this.filteredApartmentKeyTypes, this.data.apartmentKeyTypes, 'name', this.apartmentKeyTypeFilterCtrl.value?.toLowerCase());
    });

    this.ownerFilterCtrl.valueChanges.subscribe(() => {
      FilterByField()(this.filteredOwners, this.data.owners, 'name', this.ownerFilterCtrl.value?.toLowerCase());
    });

    this.form = this.formBuilder.group({
      name: [this.data.condominium.name, Validators.required],
      businessId: [this.data.condominium.businessId, [Validators.required, Validators.pattern('(^[0-9]{6,7})-([0-9])')]],
      number: [this.data.condominium.number],
      addressStreet: [this.data.condominium.addressStreet, Validators.required],
      addressPostal: [this.data.condominium.addressPostalCode, [Validators.required, Validators.pattern('[0-9]{5}')]],
      addressCity: [this.data.condominium.addressCity, Validators.required],
      superIntendent: this.superIntendentCtrl,
      lockType: this.lockTypeCtrl,
      keyType: this.keyTypeCtrl,
      apartmentKeyType: this.apartmentKeyTypeCtrl,
      owner: this.ownerCtrl,
    });
  }

  save() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.data.condominium.name = this.f.name.value;
    this.data.condominium.businessId = this.f.businessId.value;
    this.data.condominium.number = this.f.number.value ?? undefined;
    this.data.condominium.addressStreet = this.f.addressStreet.value;
    this.data.condominium.addressPostalCode = this.f.addressPostal.value;
    this.data.condominium.addressCity = this.f.addressCity.value;
    this.data.condominium.superIntendentId = this.f.superIntendent.value ?? undefined;
    this.data.condominium.lockTypeId = this.f.lockType.value ?? undefined;
    this.data.condominium.keyTypeId = this.f.keyType.value ?? undefined;
    this.data.condominium.apartmentKeyTypeId = this.f.apartmentKeyType.value ?? undefined;
    this.data.condominium.ownerId = this.f.owner.value ?? undefined;
    this.saveCondominium(this.data.condominium);
  }

  close() {
    this.dialogRef.close();
  }

  saveCondominium(condominium: Condominium) {
    const isNew = !condominium.id;

    this.condominiumService.upsertCondominium(condominium).subscribe({
      next: (result) => {
        this.snackService.showMessage(isNew ? 'condominium.created' : 'condominium.updated');
        this.dialogRef.close(result);
      },
      error: (error) => {
        if (error.status === 409) {
          this.snackService.showMessage('condominium.businessIdAlreadyExists');
        } else {
          this.snackService.showGeneralError();
          console.error(error);
        }
      },
    });
  }
}
