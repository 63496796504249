<div [hidden]="fromSearch && residents.length === 0" [ngClass]="{ 'from-search': fromSearch && residents.length > 0 }">
  <div *ngIf="showHeader || (fromSearch && residents.length > 0)" class="page-header page-header-residents">
    <h2>
      <mat-icon>person</mat-icon>
      {{ 'resident.header' | translate }}
    </h2>
  </div>

  <div class="card main-card">
    <div *ngIf="!(handsetObserverService.isSmallScreen$ | async)" class="buttons">
      <ng-container *ngTemplateOutlet="buttons"></ng-container>
    </div>

    <mat-accordion *ngIf="handsetObserverService.isSmallScreen$ | async">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'general.actions' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="buttons"></ng-container>
      </mat-expansion-panel>
    </mat-accordion>

    <div class="datatable">
      <mat-form-field *ngIf="!fromSearch" [floatLabel]="'always'" appearance="outline" class="full-width-search mt-2">
        <mat-label>{{ 'general.search' | translate }}</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input (keyup)="applyFilter($event)" matInput />
      </mat-form-field>

      <ng-template #desktopTable>
        <mat-table [dataSource]="dataSource" [matSortDirection]="'asc'" matSort matSortActive="fullName" matSortDisableClear>
          <ng-container matColumnDef="fullName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'resident.name' | translate }}</mat-header-cell>
            <mat-cell (click)="openers.openResident(row)" *matCellDef="let row" class="link">{{ row.fullName }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="phone">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'resident.phone' | translate }}</mat-header-cell>
            <mat-cell (click)="openers.openResident(row)" *matCellDef="let row" class="link">{{ row.phone }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'resident.email' | translate }}</mat-header-cell>
            <mat-cell (click)="openers.openResident(row)" *matCellDef="let row" class="link">{{ row.email }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="apartment">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'resident.apartment' | translate }}</mat-header-cell>
            <mat-cell (click)="openers.openApartment(row.apartment)" *matCellDef="let row" class="link">{{
              row.apartment ? row.apartment.addressStreet + ', ' + row.apartment.addressPostalCode + ' ' + row.apartment.addressCity : ''
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="open">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell (click)="openers.openResident(row)" *matCellDef="let row">
              <mat-icon>open_in_new</mat-icon>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsDesktop"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsDesktop"></mat-row>
        </mat-table>
      </ng-template>

      <ng-template #mobileTable>
        <mat-table [dataSource]="dataSource" [matSortDirection]="'asc'" matSort matSortActive="name" matSortDisableClear multiTemplateDataRows>
          <ng-container matColumnDef="arrow">
            <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
            <mat-cell *matCellDef="let row">
              <mat-icon>{{ expandedElement && expandedElement.id === row.id ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}</mat-icon>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="fullName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'resident.name' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row.fullName }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="open">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell (click)="openers.openResident(row)" *matCellDef="let row">
              <mat-icon>open_in_new</mat-icon>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td *matCellDef="let element" [attr.colspan]="displayedColumnsMobile.length" mat-cell>
              <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'" class="element-detail">
                <xkey-basic-info-field [title]="'resident.email'" [value]="element.email"></xkey-basic-info-field>
                <xkey-basic-info-field [title]="'resident.phone'" [value]="element.phone"></xkey-basic-info-field>
                <xkey-basic-info-field
                  (click)="openers.openApartment(element.apartment)"
                  [isClickable]="true"
                  [title]="'resident.apartment'"
                  [value]="element.apartment?.addressStreet + ', ' + element.apartment?.addressPostalCode + ' ' + element.apartment?.addressCity"
                >
                </xkey-basic-info-field>
              </div>
            </td>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsMobile"></mat-header-row>
          <mat-row (click)="expandedElement = expandedElement === element ? null : element" *matRowDef="let element; columns: displayedColumnsMobile" [class.expanded-row]="expandedElement === element" class="element-row"></mat-row>

          <tr
            *matRowDef="let row; columns: ['expandedDetail']"
            [ngClass]="{
              'expanded-detail': expandedElement && expandedElement.id == row.id,
              'closed-detail': !(expandedElement && expandedElement.id == row.id)
            }"
            class="detail-row"
            mat-row
          ></tr>
        </mat-table>
      </ng-template>

      <ng-container *ngIf="handsetObserverService.isHandSet$ | async">
        <ng-template [ngTemplateOutlet]="mobileTable"></ng-template>
      </ng-container>

      <ng-container *ngIf="!(handsetObserverService.isHandSet$ | async)">
        <ng-template [ngTemplateOutlet]="desktopTable"></ng-template>
      </ng-container>

      <div *ngIf="residents.length === 0" class="no-results">
        {{ 'general.noResults' | translate }}
      </div>

      <mat-paginator #paginator [length]="dataSource.data.length" [pageIndex]="0" [pageSizeOptions]="[25, 50, 100, 250]" [pageSize]="pageSize"> </mat-paginator>
    </div>
  </div>
</div>

<ng-template #buttons>
  <button (click)="openCreateModal()" [disabled]="!loginService.userHasPermissions('Resident_Create')" class="block-on-mobile" color="primary" mat-flat-button>
    <mat-icon>note_add</mat-icon>
    {{ 'resident.create' | translate }}
  </button>
</ng-template>
