import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlPanelComponent } from './components/control-panel/control-panel.component';
import { XTranslateModule } from '../x-translate/translate.module';
import { SharedModule } from '../shared/shared.module';
import { KeyTypeComponent } from './components/key-type/key-type.component';
import { KeyTypeModalComponent } from './components/keytype-modal/key-type-modal.component';
import { LockTypeComponent } from './components/lock-type/lock-type.component';
import { LockTypeModalComponent } from './components/locktype-modal/lock-type-modal.component';
import { KeyTypeService } from './services/key-type.service';
import { RoleComponent } from './components/role/role.component';
import { RoleModalComponent } from './components/role-modal/role-modal.component';
import { RoleService } from './services/role.service';
import { LockTypeService } from './services/lock-type.service';
import { PermissionComponent } from './components/permission/permission.component';
import { PermissionModalComponent } from './components/permission-modal/permission-modal.component';
import { PermissionService } from './services/permission.service';

@NgModule({
  declarations: [ControlPanelComponent, KeyTypeComponent, KeyTypeModalComponent, LockTypeComponent, LockTypeModalComponent, RoleComponent, RoleModalComponent, PermissionComponent, PermissionModalComponent],
  imports: [CommonModule, XTranslateModule, SharedModule],
  providers: [RoleService, LockTypeService, KeyTypeService, PermissionService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ControlPanelModule {}
