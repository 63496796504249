import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KeyTypeService } from '../../services/key-type.service';
import { KeyType } from '../../../../models/keyType';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackService } from '../../../ui/services/snack.service';

@Component({
  selector: 'xkey-key-type-modal',
  templateUrl: './key-type-modal.component.html',
  styleUrls: ['./key-type-modal.component.scss'],
})
export class KeyTypeModalComponent implements OnInit {
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private keyTypeService: KeyTypeService,
    public dialogRef: MatDialogRef<KeyTypeModalComponent>,
    private snackService: SnackService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      keyType?: KeyType;
    }
  ) {
    data.keyType = data.keyType ?? new KeyType();
  }

  get f() {
    return this.form.controls;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [this.data.keyType.name, Validators.required],
      generalKey: [this.data.keyType.generalKey ?? false],
    });
  }

  save() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.data.keyType.name = this.f.name.value;
    this.data.keyType.generalKey = this.f.generalKey.value;
    this.saveKeyType(this.data.keyType);
  }

  saveKeyType(keyType: KeyType) {
    const isNew = !keyType.id;

    this.keyTypeService.upsertKeyType(keyType).subscribe({
      next: (result) => {
        this.snackService.showMessage(isNew ? 'keyType.created' : 'keyType.updated');
        this.dialogRef.close(result);
      },
      error: (error) => {
        console.error(error);
        this.snackService.showGeneralError();
      },
    });
  }
}
