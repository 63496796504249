import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KeyService } from '../../services/key.service';
import { Key } from '../../../../models/key';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'xkey-delete-key-modal',
  templateUrl: './return-key-modal.component.html',
  styleUrls: ['./return-key-modal.component.scss'],
})
export class ReturnKeyModalComponent implements OnInit {
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private keyService: KeyService,
    public dialogRef: MatDialogRef<ReturnKeyModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      keys: Key[];
    }
  ) {}

  get f() {
    return this.form.controls;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      returnTo: ['owner', Validators.required],
    });
  }

  save() {
    if (this.form.invalid) {
      return;
    }

    this.returnKeys();
  }

  returnKeys() {
    const returnTo = this.f.returnTo.value === 'owner' ? 0 : 1;
    this.keyService.returnKey(this.data.keys, returnTo).subscribe({
      next: () => {
        this.dialogRef.close(true);
      },
      error: (err) => {
        console.error(err);
      },
    });
  }
}
