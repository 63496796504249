<ng-container>
  <div class="modal-header">
    <h2 class="modal-title" mat-dialog-title>{{ 'user.password' | translate }}</h2>
  </div>
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="form">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'user.password' | translate }}</mat-label>
        <input [matTooltip]="'user.passwordHint' | translate" autocomplete="new-password" formControlName="password" matInput placeholder="{{ 'user.password' | translate }}" type="password" />
        <mat-error *ngIf="f.password.errors">{{ 'user.passwordHint' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'user.passwordAgain' | translate }}</mat-label>
        <input autocomplete="new-password" formControlName="passwordAgain" matInput placeholder="{{ 'user.passwordAgain' | translate }}" type="password" />
        <mat-error *ngIf="f.passwordAgain.errors">{{ 'user.passwordsMustBeSame' | translate }}</mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button [mat-dialog-close]="false" color="primary" mat-flat-button>{{ 'general.close' | translate }}</button>
    <button (click)="save()" color="accent" mat-flat-button>{{ 'general.save' | translate }}</button>
  </mat-dialog-actions>
</ng-container>
