import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { XTranslateModule } from '../x-translate/translate.module';
import { SharedModule } from '../shared/shared.module';
import { NgxMasonryModule } from 'ngx-masonry';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CondominiumModule } from '../condominium/condominium.module';
import { KeyModule } from '../key/key.module';
import { AnnouncementModule } from '../announcement/announcement.module';

@NgModule({
  declarations: [DashboardComponent],
  imports: [CommonModule, XTranslateModule, SharedModule, NgxMasonryModule, CondominiumModule, KeyModule, AnnouncementModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DashboardModule {}
