<ng-container>
  <div class="modal-header">
    <h2 class="modal-title" mat-dialog-title>{{ 'key.giveKey' | translate }}</h2>
  </div>
  <mat-dialog-content class="mat-typography">
    <mat-horizontal-stepper #stepper (selectionChange)="resizeCanvas()" *ngIf="firstFormGroup" [linear]="true">
      <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">
          <ng-template matStepLabel>{{ 'key.giveStep1' | translate }}</ng-template>

          <div class="mb-2 py-2">
            <mat-radio-group (change)="changeType($event)" formControlName="selectedType">
              <mat-radio-button value="resident">{{ 'key.resident' | translate }}</mat-radio-button>
              <mat-radio-button class="ms-2" value="user">{{ 'key.user' | translate }}</mat-radio-button>
              <mat-radio-button class="ms-2" value="other">{{ 'key.other' | translate }}</mat-radio-button>
            </mat-radio-group>
          </div>

          <div *ngIf="firstFormGroup.controls.selectedType.value === 'resident'">
            <mat-form-field appearance="fill">
              <mat-label>{{ 'key.resident' | translate }}</mat-label>
              <mat-select [formControl]="residentCtrl" placeholder="{{ 'key.resident' | translate }}">
                <mat-option>
                  <ngx-mat-select-search [formControl]="residentFilterCtrl" [noEntriesFoundLabel]="'general.noEntriesFound' | translate" [placeholderLabel]="'general.selectSearch' | translate"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let resident of filteredResidents | async" [value]="resident.id">
                  {{ resident.fullName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="f.resident.errors && f.resident.errors.required">{{ 'key.residentIsRequired' | translate }}</mat-error>
            </mat-form-field>
          </div>
          <div *ngIf="firstFormGroup.controls.selectedType.value === 'user'">
            <mat-form-field appearance="fill">
              <mat-label>{{ 'key.user' | translate }}</mat-label>
              <mat-select [formControl]="userCtrl" placeholder="{{ 'key.user' | translate }}">
                <mat-option>
                  <ngx-mat-select-search [formControl]="userFilterCtrl" [noEntriesFoundLabel]="'general.noEntriesFound' | translate" [placeholderLabel]="'general.selectSearch' | translate"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let user of filteredUsers | async" [value]="user.id">
                  {{ user.fullName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="f.user.errors && f.user.errors.required">{{ 'key.userIsRequired' | translate }}</mat-error>
            </mat-form-field>
          </div>
          <div *ngIf="firstFormGroup.controls.selectedType.value === 'other'" class="form-group">
            <mat-form-field appearance="fill">
              <mat-label>{{ 'key.firstName' | translate }}</mat-label>
              <input autocomplete="off" formControlName="firstName" matInput placeholder="{{ 'key.firstName' | translate }}" />
              <mat-error *ngIf="f.firstName.errors && f.firstName.errors.required">{{ 'key.firstNameIsRequired' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>{{ 'key.lastName' | translate }}</mat-label>
              <input autocomplete="off" formControlName="lastName" matInput placeholder="{{ 'key.lastName' | translate }}" />
              <mat-error *ngIf="f.lastName.errors && f.lastName.errors.required">{{ 'key.lastNameIsRequired' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>{{ 'key.email' | translate }}</mat-label>
              <input autocomplete="off" formControlName="email" matInput placeholder="{{ 'key.email' | translate }}" type="email" />
              <mat-error *ngIf="f.email.errors && f.email.errors.required">{{ 'key.emailIsRequired' | translate }}</mat-error>
              <mat-error *ngIf="f.email.errors && f.email.errors.email">{{ 'key.emailInWrongFormat' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>{{ 'key.phone' | translate }}</mat-label>
              <input autocomplete="off" formControlName="phone" matInput placeholder="{{ 'key.phone' | translate }}" />
              <mat-error *ngIf="f.phone.errors && f.phone.errors.required">{{ 'key.phoneIsRequired' | translate }}</mat-error>
              <mat-error *ngIf="f.phone.errors && f.phone.errors.wrongNumber">{{ 'key.phoneInWrongFormat' | translate }}</mat-error>
            </mat-form-field>
          </div>

          <div class="mb-3">
            <mat-radio-group (change)="changePeriod($event)" formControlName="selectedPeriodType">
              <mat-radio-button value="openEnded">{{ 'key.forNow' | translate }}</mat-radio-button>
              <mat-radio-button class="ms-2" value="fixed">{{ 'key.fixed' | translate }}</mat-radio-button>
            </mat-radio-group>
          </div>

          <div *ngIf="firstFormGroup.controls.selectedPeriodType.value === 'fixed'" class="form-group pb-4">
            <mat-form-field appearance="fill">
              <input [placeholder]="'key.dueDate' | translate" formControlName="dueDate" id="dueDate" matInput type="datetime-local" />
              <mat-error *ngIf="f.dueDate.errors && f.dueDate.errors.required">{{ 'key.dueDateIsRequired' | translate }}</mat-error>
            </mat-form-field>
          </div>

          <div *ngIf="this.loginService.userHasPermissions('Can_Regive_Keys')" class="form-group pb-4">
            <mat-radio-group formControlName="canBeReGiven">
              <mat-radio-button [value]="true" class="mb-2">{{ 'key.canBeReGiven' | translate }}</mat-radio-button>
              <mat-radio-button [value]="false" class="ms-md-2">{{ 'key.cannotBeReGiven' | translate }}</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="button-row">
            <button (click)="submitStep1()" color="primary" mat-flat-button matStepperNext>{{ 'key.step1Next' | translate }}</button>
          </div>
        </form>
      </mat-step>
      <mat-step [completed]="getHasSignature()">
        <ng-template matStepLabel>{{ 'key.giveStep2' | translate }}</ng-template>
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ 'key.mandate' | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-2">
              <p *ngIf="mandateName">{{ mandateName }}</p>
              <ngx-file-drop
                (onFileDrop)="dropped($event)"
                [browseBtnLabel]="'key.browse' | translate"
                [dropZoneLabel]="'key.fileHint' | translate"
                [multiple]="false"
                [showBrowseBtn]="true"
                accept=".png,.jpg"
                browseBtnClassName="ms-2 btn btn-primary"
              ></ngx-file-drop>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel *ngIf="keyAgreement">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ 'key.keyAgreement' | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-2">
              <markdown [data]="keyAgreement"></markdown>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ 'key.signature' | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-2" id="signaturePad">
              <signature-pad #signature (drawEnd)="drawComplete()" [options]="signaturePadOptions"></signature-pad>
              <button (click)="clear()" class="pull-right clear-btn mt-2" color="warn" mat-flat-button>{{ 'key.empty' | translate }}</button>
            </div>
          </mat-expansion-panel>
        </mat-accordion>

        <div class="button-row mt-2">
          <button class="me-1" color="primary" mat-flat-button matStepperPrevious>{{ 'key.previous' | translate }}</button>
          <button (click)="giveKeys()" color="primary" mat-flat-button>{{ 'key.give' | translate }}</button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button [mat-dialog-close]="false" color="primary" mat-flat-button>{{ 'general.close' | translate }}</button>
  </mat-dialog-actions>
</ng-container>
