import { Component, Inject, OnInit } from '@angular/core';
import { CompanyService } from '../../services/company.service';
import { Company } from '../../../../models/company';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackService } from '../../../ui/services/snack.service';

@Component({
  selector: 'xkey-company-modal',
  templateUrl: './company-modal.component.html',
  styleUrls: ['./company-modal.component.scss'],
})
export class CompanyModalComponent implements OnInit {
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private snackService: SnackService,
    public dialogRef: MatDialogRef<CompanyModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { company?: Company; keyAgreement?: string }
  ) {
    if (!data.company) {
      data.company = new Company();
    }
  }

  get f() {
    return this.form.controls;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [this.data.company.name, Validators.required],
      addressStreet: [this.data.company.addressStreet, Validators.required],
      addressPostal: [this.data.company.addressPostalCode, [Validators.required, Validators.pattern('[0-9]{5,}')]],
      addressCity: [this.data.company.addressCity, Validators.required],
      keyAgreement: [this.data.company.keyAgreement || this.data.keyAgreement],
    });
  }

  save() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.data.company.name = this.f.name.value;
    this.data.company.addressStreet = this.f.addressStreet.value;
    this.data.company.addressPostalCode = this.f.addressPostal.value;
    this.data.company.addressCity = this.f.addressCity.value;
    this.data.company.keyAgreement = this.f.keyAgreement.value;
    this.saveCompany(this.data.company);
  }

  saveCompany(company: Company) {
    const isNew = !company.id;

    this.companyService.upsertCompany(company).subscribe({
      next: (result) => {
        this.snackService.showMessage(isNew ? 'company.created' : 'company.updated');
        this.dialogRef.close({ company: result, isNew: true });
      },
      error: (error) => {
        this.snackService.showGeneralError();
        console.error(error);
      },
    });
  }
}
