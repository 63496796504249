import { User } from './user';
import { Apartment } from './apartment';
import { Condominium } from './condominium';
import { Resident } from './resident';
import { LockType } from './lockType';
import { Company } from './company';
import { KeyType } from './keyType';
import { ExternalUser } from './externalUser';

export class Key {
  id: number;
  stamp: string;
  received: Date;
  returned: Date;
  dueDate: Date;
  typeId: number;
  type: KeyType;
  apartmentId: number;
  apartment: Apartment;
  condominiumId: number;
  condominium: Condominium;
  currentHolder: Resident;
  currentHolderInternal: User;
  currentHolderOther: ExternalUser;
  originalHolder: Resident;
  originalHolderInternal: User;
  originalHolderOther: ExternalUser;
  lockTypeId: number;
  lockType: LockType;
  currentAddress: string;
  lost: boolean;
  canBeReGiven: boolean;
  owner: Company;
  ownerId: number;

  constructor(data?: any) {
    Object.assign(this, data);
  }

  getOriginalHolderToString = (): string => {
    const holder = this.getOriginalHolder();

    if (holder) {
      return holder.firstName + ' ' + holder.lastName;
    }

    return '';
  };

  getApartmentOrCondominiumToString(): string {
    return this.condominium?.name ?? this.apartment?.fullAddress ?? '';
  }

  getHolderToString(): string {
    const holder = this.getHolder();
    return holder ? `${holder.firstName} ${holder.lastName}${holder.email ? `, ${holder.email}` : ''}${holder.phone ? `, ${holder.phone}` : ''}` : '';
  }

  private getHolder(): Resident | User | ExternalUser {
    return this.currentHolder ?? this.currentHolderInternal ?? this.currentHolderOther;
  }

  private getOriginalHolder(): Resident | User | ExternalUser {
    return this.originalHolder ?? this.originalHolderInternal ?? this.originalHolderOther;
  }
}
