import { Injectable } from '@angular/core';
import { GeneralService } from '../../../services/general.service';
import { Key } from '../../../models/key';
import { debounceTime, filter, map, Observable, switchMap } from 'rxjs';
import { KeyHistory } from '../../../models/keyhistory';

@Injectable({
  providedIn: 'root',
})
export class KeyService {
  constructor(private general: GeneralService) {}

  getKeys(): Observable<Key[]> {
    return this.general.get<Key[]>('keys').pipe(map((keys) => keys.map((k) => new Key(k))));
  }

  getKey(id): Observable<Key> {
    return this.general.get('keys/' + id).pipe(map((key) => new Key(key)));
  }

  getKeyKeyHistories(id): Observable<KeyHistory[]> {
    return this.general.get<KeyHistory[]>('keys/' + id + '/key-histories').pipe(map((keyHistories) => keyHistories.map((kh) => new KeyHistory(kh))));
  }

  deleteKey(id): Observable<void> {
    return this.general.delete('keys/' + id);
  }

  updateKey(key: Key): Observable<Key> {
    return this.general.update<Key>('keys', key).pipe(map((k) => new Key(k)));
  }

  createKey(key: Key, amount): Observable<Key> {
    return this.general.post<Key>('keys/' + amount, key).pipe(map((k) => new Key(k)));
  }

  returnKey(keyIds, returnTo: number): Observable<void> {
    return this.general.post('keys/return', { keys: keyIds, returnTo });
  }

  giveKey(objectToSend): Observable<void> {
    return this.general.post('keys/give', objectToSend);
  }

  sendReminder(key: Key): Observable<void> {
    return this.general.get('keys/' + key.id + '/send-reminder');
  }

  markLost(key: Key): Observable<Key> {
    return this.general.get('keys/' + key.id + '/mark-lost');
  }

  markFound(key: Key): Observable<Key> {
    return this.general.get('keys/' + key.id + '/mark-found');
  }

  changeDueDate(keyToUpdate: { dueDate: any; id: number }): Observable<void> {
    return this.general.post('keys/' + keyToUpdate.id + '/change-due-date', keyToUpdate);
  }

  upsertKey(key: Key, amount?: number): Observable<Key> {
    return key.id ? this.updateKey(key) : this.createKey(key, amount);
  }

  search(terms: Observable<string>): Observable<Key[]> {
    return terms.pipe(
      filter(Boolean),
      debounceTime(500),
      switchMap((term: string) => this.general.post<Key[]>('keys/search', { searchPhrase: term }).pipe(map((keys) => keys.map((k) => new Key(k)))))
    );
  }
}
