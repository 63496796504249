<div [hidden]="fromSearch && condominia.length === 0" [ngClass]="{ 'from-search': fromSearch && condominia.length > 0 }">
  <div *ngIf="fromDashboard && !fromSearch" class="page-header dashboard-header">
    <h2>
      <mat-icon>apartment</mat-icon>
      {{ 'condominium.myCondominia' | translate }}
    </h2>
  </div>
  <div *ngIf="(!fromDashboard && !user) || fromSearch" class="page-header page-header-condominia">
    <h2>
      <mat-icon>apartment</mat-icon>
      {{ 'condominium.header' | translate }}
    </h2>
  </div>
  <div class="card main-card">
    <div *ngIf="!(handsetObserverService.isSmallScreen$ | async)" class="buttons">
      <ng-container *ngTemplateOutlet="buttons"></ng-container>
    </div>

    <mat-accordion *ngIf="handsetObserverService.isSmallScreen$ | async">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'general.actions' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="buttons"></ng-container>
      </mat-expansion-panel>
    </mat-accordion>

    <div [ngClass]="{ 'mt-2': fromDashboard }" class="datatable">
      <mat-form-field *ngIf="!fromSearch && !fromDashboard" [floatLabel]="'always'" appearance="outline" class="full-width-search mt-2">
        <mat-label>{{ 'general.search' | translate }}</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input (keyup)="applyFilter($event)" matInput />
      </mat-form-field>

      <ng-template #desktopTable>
        <mat-table [dataSource]="dataSource" [matSortDirection]="'asc'" matSort matSortActive="name" matSortDisableClear>
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'condominium.name' | translate }}</mat-header-cell>
            <mat-cell (click)="openers.openCondominium(row)" *matCellDef="let row" class="link">{{ row.name }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="number">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'condominium.number' | translate }}</mat-header-cell>
            <mat-cell (click)="openers.openCondominium(row)" *matCellDef="let row" class="link">{{ row.number }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="fullAddress">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'condominium.address' | translate }}</mat-header-cell>
            <mat-cell (click)="openers.openCondominium(row)" *matCellDef="let row" class="link">{{ row.fullAddress }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="businessId">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'condominium.businessId' | translate }}</mat-header-cell>
            <mat-cell (click)="openers.openCondominium(row)" *matCellDef="let row" class="link">{{ row.businessId }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="superIntendent">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'condominium.superIntendent' | translate }}</mat-header-cell>
            <mat-cell (click)="openers.openUser(row.superIntendent)" *matCellDef="let row" class="link">{{ row.superIntendent?.fullName }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="open">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell (click)="openers.openCondominium(row)" *matCellDef="let row">
              <mat-icon>open_in_new</mat-icon>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsDesktop"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsDesktop"></mat-row>
        </mat-table>
      </ng-template>

      <ng-template #mobileTable>
        <mat-table [dataSource]="dataSource" [matSortDirection]="'asc'" matSort matSortActive="name" matSortDisableClear multiTemplateDataRows>
          <ng-container matColumnDef="arrow">
            <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
            <mat-cell *matCellDef="let row">
              <mat-icon>{{ expandedElement && expandedElement.id === row.id ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}</mat-icon>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'condominium.name' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row.name }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="open">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell (click)="openers.openCondominium(row)" *matCellDef="let row">
              <mat-icon>open_in_new</mat-icon>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td *matCellDef="let element" [attr.colspan]="displayedColumnsMobile.length" mat-cell>
              <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'" class="element-detail">
                <xkey-basic-info-field [title]="'condominium.address'" [value]="element.fullAddress"></xkey-basic-info-field>
                <xkey-basic-info-field (click)="openers.openUser(element.superIntendent)" [isClickable]="true" [title]="'condominium.superIntendent'" [value]="element.superIntendent?.fullName"></xkey-basic-info-field>
              </div>
            </td>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsMobile"></mat-header-row>
          <mat-row (click)="toggleExpansion(element)" *matRowDef="let element; columns: displayedColumnsMobile" [class.expanded-row]="expandedElement === element" class="element-row"></mat-row>

          <tr
            *matRowDef="let row; columns: ['expandedDetail']"
            [ngClass]="{
              'expanded-detail': expandedElement && expandedElement.id == row.id,
              'closed-detail': !(expandedElement && expandedElement.id == row.id)
            }"
            class="detail-row"
            mat-row
          ></tr>
        </mat-table>
      </ng-template>

      <ng-container *ngIf="handsetObserverService.isHandSet$ | async">
        <ng-template [ngTemplateOutlet]="mobileTable"></ng-template>
      </ng-container>

      <ng-container *ngIf="!(handsetObserverService.isHandSet$ | async)">
        <ng-template [ngTemplateOutlet]="desktopTable"></ng-template>
      </ng-container>

      <div *ngIf="condominia.length === 0" class="no-results">
        {{ 'general.noResults' | translate }}
      </div>

      <mat-paginator #paginator [length]="dataSource.data.length" [pageIndex]="0" [pageSizeOptions]="fromDashboard ? [3] : [5, 25, 50, 100, 250]" [pageSize]="pageSize"> </mat-paginator>
    </div>
  </div>
</div>

<ng-template #buttons>
  <button (click)="openCreateModal()" [disabled]="!loginService.userHasPermissions('Condominium_Create')" class="block-on-mobile" color="primary" mat-flat-button>
    <mat-icon>note_add</mat-icon>
    {{ 'condominium.create' | translate }}
  </button>
</ng-template>
