<div class="card main-card">
  <mat-tab-group (selectedTabChange)="tabChanged($event)" [(selectedIndex)]="tabIndex" mat-stretch-tabs>
    <mat-tab *ngIf="loginService.userHasPermissions('Condominium_Read')" [label]="'condominium.header' | translate" class="mt-2">
      <xkey-condominium />
    </mat-tab>
    <mat-tab *ngIf="loginService.userHasPermissions('Apartment_Read')" [label]="'apartment.header' | translate" class="mt-2">
      <xkey-apartment />
    </mat-tab>
    <mat-tab *ngIf="loginService.userHasPermissions('Resident_Read')" [label]="'resident.header' | translate" class="mt-2">
      <xkey-resident showHeader="true" />
    </mat-tab>
  </mat-tab-group>
</div>
