import { AbstractControl, ValidatorFn } from '@angular/forms';

export function FieldLessThanValidator(fieldName: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const group = control.parent;
    const fieldToCompare = group?.get(fieldName);

    if (!fieldToCompare) {
      return null;
    }

    const isLessThan = Number(fieldToCompare.value) < Number(control.value);
    return isLessThan ? { greaterThan: { field: fieldName, value: control.value } } : null;
  };
}
