<ng-container>
  <div class="modal-header">
    <h2 class="modal-title" mat-dialog-title>{{ 'general.transfer' | translate }}</h2>
  </div>
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="form">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'condominium.name' | translate }}</mat-label>
        <input autocomplete="off" formControlName="name" matInput placeholder="{{ 'condominium.name' | translate }}" />
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'condominium.owner' | translate }}</mat-label>
        <mat-select [formControl]="ownerCtrl" placeholder="{{ 'condominium.owner' | translate }}">
          <mat-option>
            <ngx-mat-select-search [formControl]="ownerFilterCtrl" [noEntriesFoundLabel]="'general.noEntriesFound' | translate" [placeholderLabel]="'general.selectSearch' | translate"></ngx-mat-select-search>
          </mat-option>
          <mat-option></mat-option>
          <mat-option *ngFor="let owner of filteredOwners | async" [value]="owner.id">
            {{ owner.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button [mat-dialog-close]="false" color="primary" mat-flat-button>{{ 'general.close' | translate }}</button>
    <button (click)="save()" color="accent" mat-flat-button>{{ 'general.save' | translate }}</button>
  </mat-dialog-actions>
</ng-container>
