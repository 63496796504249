import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { XTranslateModule } from '../x-translate/translate.module';
import { SharedModule } from '../shared/shared.module';
import { CompanyService } from './services/company.service';
import { CompanyComponent } from './components/company/company.component';
import { CompanyModalComponent } from './components/company-modal/company-modal.component';
import { CompanySingleComponent } from './components/company-single/company-single.component';
import { UiModule } from '../ui/ui.module';
import { InvitationModule } from '../invitation/invitation.module';
import { UserModule } from '../user/user.module';
import { MarkdownModule } from 'ngx-markdown';

@NgModule({
  declarations: [CompanyComponent, CompanyModalComponent, CompanySingleComponent],
  imports: [CommonModule, XTranslateModule, SharedModule, UiModule, InvitationModule, UserModule, MarkdownModule],
  providers: [CompanyService],
  exports: [CompanyComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CompanyModule {}
