<ng-container>
  <div class="modal-header">
    <h2 class="modal-title" mat-dialog-title>{{ 'user.edit' | translate }}</h2>
  </div>
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="form">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'user.firstName' | translate }}</mat-label>
        <input autocomplete="given-name" formControlName="firstName" matInput placeholder="{{ 'user.firstName' | translate }}" />
        <mat-error *ngIf="form.get('firstName').errors">{{ 'user.firstNameIsRequired' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ 'user.lastName' | translate }}</mat-label>
        <input autocomplete="family-name" formControlName="lastName" matInput placeholder="{{ 'user.lastName' | translate }}" />
        <mat-error *ngIf="form.get('lastName').errors">{{ 'user.lastNameIsRequired' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'user.email' | translate }}</mat-label>
        <input autocomplete="email" formControlName="email" matInput placeholder="{{ 'user.email' | translate }}" type="email" />
        <mat-error *ngIf="f.email.errors">{{ 'user.emailInWrongFormat' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'user.phone' | translate }}</mat-label>
        <input autocomplete="phone" formControlName="phone" matInput placeholder="{{ 'user.phone' | translate }}" />
        <mat-error *ngIf="f.phone.errors && (f.phone.errors.wrongNumber || f.phone.errors.required)">{{ 'user.phoneInWrongFormat' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'user.company' | translate }}</mat-label>
        <mat-select [formControl]="companyCtrl" placeholder="{{ 'user.company' | translate }}">
          <mat-option>
            <ngx-mat-select-search [formControl]="companyFilterCtrl" [noEntriesFoundLabel]="'general.noEntriesFound' | translate" [placeholderLabel]="'general.selectSearch' | translate"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let company of filteredCompanies | async" [value]="company.id">
            {{ company.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'user.role' | translate }}</mat-label>
        <mat-select [formControl]="roleCtrl" placeholder="{{ 'user.role' | translate }}">
          <mat-option>
            <ngx-mat-select-search [formControl]="roleFilterCtrl" [noEntriesFoundLabel]="'general.noEntriesFound' | translate" [placeholderLabel]="'general.selectSearch' | translate"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let role of filteredRoles | async" [value]="role.id">
            {{ role.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button [mat-dialog-close]="false" color="primary" mat-flat-button>{{ 'general.close' | translate }}</button>
    <button (click)="save()" color="accent" mat-flat-button>{{ 'general.save' | translate }}</button>
  </mat-dialog-actions>
</ng-container>
