<ng-container>
  <div class="modal-header">
    <h2 class="modal-title" mat-dialog-title>{{ 'invitation.send' | translate }}</h2>
  </div>
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="form">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'user.email' | translate }}</mat-label>
        <input formControlName="email" matInput placeholder="{{ 'user.email' | translate }}" type="email" />
        <mat-error *ngIf="f.email.errors && f.email.errors.required">{{ 'user.emailIsRequired' | translate }}</mat-error>
        <mat-error *ngIf="f.email.errors && f.email.errors.email">{{ 'user.emailInWrongFormat' | translate }}</mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button [mat-dialog-close]="false" color="primary" mat-flat-button>{{ 'general.close' | translate }}</button>
    <button (click)="save()" color="accent" mat-flat-button>{{ 'invitation.send' | translate }}</button>
  </mat-dialog-actions>
</ng-container>
