import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ApartmentService } from '../../services/apartment.service';
import { Apartment } from '../../../../models/apartment';
import { CondominiumService } from '../../../condominium/services/condominium.service';
import { Condominium } from '../../../../models/condominium';
import { ApartmentModalComponent } from '../apartment-modal/apartment-modal.component';
import { LoginService } from '../../../auth/services/login.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { lastValueFrom } from 'rxjs';
import { OpenersService } from '../../../ui/services/openers.service';
import { HandsetObserverService } from '../../../ui/services/handset-observer.service';
import { DefaultSortingDataAccessor } from '../../../../helpers/DefaultSortingDataAccessor';
import { DefaultFilterPredicate } from '../../../../helpers/DefaultFilterPredicate';
import { AnimationTriggerMetadata } from '../../../../helpers/AnimationTriggerMetadata';
import { DialogService } from '../../../ui/services/dialog.service';
import { CompanyService } from '../../../company/services/company.service';
import { Company } from '../../../../models/company';

@Component({
  selector: 'xkey-apartment',
  templateUrl: './apartment.component.html',
  styleUrls: ['./apartment.component.scss'],
  animations: [AnimationTriggerMetadata()],
})
export class ApartmentComponent implements OnInit, OnChanges {
  dataSource = new MatTableDataSource<Apartment>([]);
  displayedColumnsDesktop = ['fullAddress', 'condominium', 'keysFree', 'open'];
  displayedColumnsMobile = ['arrow', 'fullAddress', 'open'];
  expandedElement: Apartment | null;
  keysFree: any = [];
  pageSize = 50;

  @Input() condominium: Condominium;
  @Input() fromSearch = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private apartmentService: ApartmentService,
    private condominiumService: CondominiumService,
    private companyService: CompanyService,
    public handsetObserverService: HandsetObserverService,
    public openers: OpenersService,
    public loginService: LoginService,
    private dialogService: DialogService
  ) {}

  private _apartments: Apartment[] = [];

  get apartments(): Apartment[] {
    return this._apartments;
  }

  @Input()
  set apartments(val: Apartment[]) {
    this._apartments = val;

    if (this.fromSearch) {
      this.setupComponent().then(async () => {
        if (this.apartments.length > 0) {
          await this.fetchRelatedObjects();
        }
      });
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.condominium && !changes.condominium.firstChange) {
      await this.setupComponent();
    }
  }

  async ngOnInit() {
    await this.setupComponent();
  }

  async setupComponent() {
    if (this.fromSearch) {
      this.pageSize = 5;
    }

    await this.getApartments();
  }

  async fetchRelatedObjects() {
    if (this.loginService.userHasPermissions('Key_Read')) {
      const ids = [...new Set(this.apartments.map((a) => a.id))];
      this.keysFree = await lastValueFrom(this.apartmentService.getKeysFree(ids));
    }
  }

  getKeysFree(apartmentId: string) {
    return this.keysFree[apartmentId];
  }

  applyFilter($event: KeyboardEvent) {
    const filterValue = ($event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async openCreateModal(multiple: boolean) {
    const condominia: Condominium[] = await lastValueFrom(this.condominiumService.getCondominia());
    const owners: Company[] = await lastValueFrom(this.companyService.getCompanies());

    this.dialogService.showDialog(ApartmentModalComponent, { multiple, presetCondominium: this.condominium ?? undefined, condominia, owners }, async () => await this.setupComponent());
  }

  private async getApartments() {
    if (!this.fromSearch) {
      this.apartments = this.condominium ? await lastValueFrom(this.condominiumService.getCondominiumApartments(this.condominium.id)) : (this.apartments = await lastValueFrom(this.apartmentService.getApartments()));

      await this.fetchRelatedObjects();
    }

    this.dataSource = new MatTableDataSource(this.apartments);
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = DefaultFilterPredicate();
    this.dataSource.sortingDataAccessor = DefaultSortingDataAccessor();
    this.dataSource.sort = this.sort;
  }
}
