<ng-container>
  <div class="page-header">
    <h2>
      <mat-icon>announcement</mat-icon>
      {{ 'announcement.header' | translate }}
    </h2>
  </div>
  <div class="card main-card">
    <div *ngIf="!(handsetObserverService.isSmallScreen$ | async)" class="buttons">
      <ng-container *ngTemplateOutlet="buttons"></ng-container>
    </div>

    <mat-accordion *ngIf="handsetObserverService.isSmallScreen$ | async">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'general.actions' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="buttons"></ng-container>
      </mat-expansion-panel>
    </mat-accordion>

    <div *ngFor="let announcement of fromDashboard ? (announcements | slice: 0 : 2) : announcements" class="row">
      <div class="card announcement-card">
        <div class="card-body">
          <div class="float-right pt-1">
            <button (click)="openCreateModifyModal(announcement)" [hidden]="!loginService.userHasPermissions('Announcement_Update')" class="btn modify-btn">
              <mat-icon>edit</mat-icon>
            </button>
            <button (click)="openDeleteModal(announcement.id)" [hidden]="!loginService.userHasPermissions('Announcement_Delete')" class="btn delete-btn">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
          <small class="text-muted">{{ announcement.date | date: 'dd.MM.y HH:mm:ss' }}</small>
          <h3 class="mb-3">{{ announcement.title }}</h3>
          <p class="message">{{ announcement.message }}</p>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #buttons>
  <xkey-go-back-button *ngIf="!fromDashboard" defaultUrl="/ilmoitukset"></xkey-go-back-button>
  <button (click)="openCreateModifyModal()" [hidden]="!loginService.userHasPermissions('Announcement_Create')" class="block-on-mobile" color="primary" mat-flat-button>
    <mat-icon>note_add</mat-icon>
    {{ 'announcement.createNewAnnouncement' | translate }}
  </button>
  <button (click)="goToAnnouncements()" *ngIf="fromDashboard" class="block-on-mobile pull-right-on-desktop" color="primary" mat-flat-button>{{ 'announcement.showMore' | translate }}</button>
</ng-template>
