<div *ngIf="selectedKey">
  <div class="page-header">
    <h2>
      <mat-icon>vpn_key</mat-icon>
      {{ 'key.headerSingle' | translate }} {{ selectedKey.id }}
      <mat-icon *ngIf="selectedKey.lost" [matTooltip]="'key.keyLost' | translate" class="ms-2">warning</mat-icon>
    </h2>
  </div>

  <div class="card main-card">
    <div *ngIf="!(handsetObserverService.isHandSet$ | async)" class="buttons">
      <ng-container *ngTemplateOutlet="buttons"></ng-container>
    </div>

    <mat-accordion *ngIf="handsetObserverService.isHandSet$ | async">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'general.actions' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="buttons"></ng-container>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-tab-group (selectedTabChange)="tabChanged($event)" [(selectedIndex)]="tabIndex" mat-stretch-tabs>
      <mat-tab *ngIf="loginService.userHasPermissions('Key_Read')" [label]="'general.basicInformation' | translate">
        <div class="card basic-info">
          <div class="row">
            <div class="col-sm-6">
              <xkey-basic-info-field [title]="'key.id'" [value]="selectedKey.id"></xkey-basic-info-field>
              <xkey-basic-info-field
                (click)="openers.openApartment(selectedKey.apartment, selectedKey.owner?.id)"
                *ngIf="selectedKey.apartment"
                [isClickable]="true"
                [title]="'key.apartment'"
                [value]="selectedKey.apartment?.addressStreet + ', ' + selectedKey.apartment?.addressPostalCode + ' ' + selectedKey.apartment?.addressCity"
              ></xkey-basic-info-field>
              <xkey-basic-info-field
                (click)="openers.openCondominium(selectedKey.condominium, selectedKey.owner.id)"
                *ngIf="selectedKey.condominium"
                [isClickable]="true"
                [title]="'key.condominium'"
                [value]="selectedKey.condominium?.name"
              ></xkey-basic-info-field>
              <xkey-basic-info-field
                (click)="openCurrentHolder(selectedKey)"
                [isClickable]="!!selectedKey.currentHolderInternal || !!selectedKey.currentHolder"
                [title]="'key.holder'"
                [value]="getCurrentHolderValue(selectedKey)"
              ></xkey-basic-info-field>
              <xkey-basic-info-field [title]="'key.stamp'" [value]="selectedKey.stamp"></xkey-basic-info-field>
              <xkey-basic-info-field [title]="'key.type'" [value]="selectedKey.type?.name"></xkey-basic-info-field>
            </div>
            <div class="col-sm-6">
              <xkey-basic-info-field [title]="'key.lockType'" [value]="selectedKey.lockType?.name"></xkey-basic-info-field>
              <xkey-basic-info-field *ngIf="getCurrentHolderValue(selectedKey)" [title]="'key.dueDate'" [value]="selectedKey.dueDate | date: 'dd.MM.y HH:mm:ss'"></xkey-basic-info-field>
              <xkey-basic-info-field *ngIf="getCurrentHolderValue(selectedKey)" [title]="'key.canBeReGiven'" [value]="(selectedKey.canBeReGiven ? 'general.yes' : 'general.no') | translate"></xkey-basic-info-field>
              <xkey-basic-info-field (click)="openers.openCompany(selectedKey.owner)" [isClickable]="true" [title]="'resident.owner'" [value]="selectedKey.owner?.name"></xkey-basic-info-field>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab *ngIf="loginService.userHasPermissions('Key_Read') && userHasRightCompany" [label]="'keyHistory.header' | translate">
        <xkey-key-history [key]="selectedKey"></xkey-key-history>
      </mat-tab>
    </mat-tab-group>
  </div>

  <ng-template #buttons>
    <xkey-go-back-button [defaultUrl]="selectedKey.apartment ? '/asunnot/' + selectedKey.apartmentId : '/kiinteistot/' + selectedKey.condominiumId"></xkey-go-back-button>
    <button (click)="openModifyModal(selectedKey)" [disabled]="!loginService.userHasPermissions('Key_Update') || !userHasRightCompany" class="block-on-mobile" color="accent" mat-flat-button>
      <mat-icon>edit</mat-icon>
      {{ 'general.edit' | translate }}
    </button>
    <button (click)="openChangeDueDateModal(selectedKey)" [disabled]="!loginService.userHasPermissions('Key_GiveReturn') || !userHasRightCompany" class="block-on-mobile" color="accent" mat-flat-button>
      <mat-icon>edit</mat-icon>
      {{ 'key.changeDueDate' | translate }}
    </button>
    <button (click)="openDeleteModal(selectedKey.id)" [disabled]="!loginService.userHasPermissions('Key_Delete') || !userHasRightCompany" class="block-on-mobile" color="warn" mat-flat-button>
      <mat-icon>delete</mat-icon>
      {{ 'general.delete' | translate }}
    </button>
    <button (click)="openMarkLostModal(selectedKey)" [disabled]="!loginService.userHasPermissions('Key_Update') || !userHasRightCompany" class="block-on-mobile" color="primary" mat-flat-button>
      <mat-icon>warning</mat-icon>
      {{ selectedKey.lost ? ('key.markFound' | translate) : ('key.markLost' | translate) }}
    </button>
    <button (click)="openSendReminderModal(selectedKey)" [disabled]="!loginService.userHasPermissions('Key_Read') || !userHasRightCompany" class="block-on-mobile" color="primary" mat-flat-button>
      <mat-icon>add_alert</mat-icon>
      {{ 'key.sendReminder' | translate }}
    </button>
  </ng-template>
</div>
