import { Component, Input } from '@angular/core';
import * as L from 'leaflet';
import { latLng, tileLayer } from 'leaflet';
import { NominatimService } from '../../services/nominatim.service';

@Component({
  selector: 'xkey-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent {
  @Input()
  addressStreet: string;

  @Input()
  addressPostalCode: string;

  @Input()
  addressCity: string;

  map: L.Map;
  leafletOptions = {
    layers: [tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png')],
    zoom: 17,
    dragging: false,
    boxZoom: false,
    doubleClickZoom: false,
    keyboard: false,
    scrollWheelZoom: false,
    tap: false,
    touchZoom: false,
    zoomControl: false,
    center: latLng([60.45451, 22.264824]),
  };

  constructor(private nominatimService: NominatimService) {}

  onMapReady(m: L.Map) {
    this.map = m;

    this.nominatimService.getAddressInfo(this.addressStreet, this.addressPostalCode, this.addressCity).subscribe((response: [1]) => {
      if (response.length > 0 && this.map) {
        this.map.panTo(new L.LatLng(response[0]['lat'], response[0]['lon']));
      }
    });
  }
}
