import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ConfirmModalComponent } from '../../../ui/components/confirm-modal/confirm-modal.component';
import { CondominiumFile } from '../../../../models/condominiumfile';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { CondominiumService } from '../../services/condominium.service';
import { TranslateService } from '@ngx-translate/core';
import { Condominium } from '../../../../models/condominium';
import { LoginService } from '../../../auth/services/login.service';
import { lastValueFrom } from 'rxjs';
import * as FileSaver from 'file-saver';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { HandsetObserverService } from '../../../ui/services/handset-observer.service';
import { AnimationTriggerMetadata } from '../../../../helpers/AnimationTriggerMetadata';
import { DialogService } from '../../../ui/services/dialog.service';
import { SnackService } from '../../../ui/services/snack.service';

@Component({
  selector: 'xkey-condominium-files',
  templateUrl: './condominium-files.component.html',
  styleUrls: ['./condominium-files.component.scss'],
  animations: [AnimationTriggerMetadata()],
})
export class CondominiumFilesComponent implements OnInit {
  @Input() selectedCondominium: Condominium;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource = new MatTableDataSource<CondominiumFile>([]);
  displayedColumnsDesktop = ['name', 'createdAt', 'actions'];
  displayedColumnsMobile = ['arrow', 'name', 'open'];
  expandedElement: CondominiumFile | null;
  condominiumFiles: CondominiumFile[] = [];

  constructor(
    private condominiumService: CondominiumService,
    private translate: TranslateService,
    public handsetObserverService: HandsetObserverService,
    public loginService: LoginService,
    private dialogService: DialogService,
    private snackService: SnackService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.setupComponent();
  }

  openDeleteFileModal(condominiumFileId: number) {
    this.dialogService.showDialog(ConfirmModalComponent, { modalClass: 'modal-condominium', title: this.translate.instant('condominium.deleteFile') }, () => {
      this.condominiumService.deleteCondominiumFile(this.selectedCondominium.id, condominiumFileId).subscribe(async () => {
        await this.setupComponent();
      });
    });
  }

  public async dropped(droppedFiles: NgxFileDropEntry[]) {
    for (const droppedFile of droppedFiles) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        try {
          const file = await this.getFile(fileEntry);
          const formData = new FormData();
          formData.append('file', file, droppedFile.relativePath);

          await this.condominiumService.sendCondominiumFile(this.selectedCondominium, formData).toPromise();
          await this.setupComponent();
        } catch (error) {
          if (error.status === 409) {
            this.snackService.showMessage('condominium.fileAlreadyExists');
          } else {
            this.snackService.showGeneralError();
            console.error(`Failed to upload file: ${error}`);
          }
        }
      }
    }
  }

  getCondominiumFile(condominiumFile: CondominiumFile) {
    this.condominiumService.getCondominiumFile(this.selectedCondominium.id, condominiumFile).then((response: any) => {
      const data: Blob = new Blob([response.data]);
      FileSaver.saveAs(data, condominiumFile.name);
    });
  }

  private async getFile(fileEntry: FileSystemFileEntry): Promise<File> {
    return new Promise((resolve) => {
      fileEntry.file((file) => {
        resolve(file);
      });
    });
  }

  private async setupComponent() {
    this.condominiumFiles = await lastValueFrom(this.condominiumService.getCondominiumFiles(this.selectedCondominium.id));

    this.dataSource = new MatTableDataSource(this.condominiumFiles);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}
