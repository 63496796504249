import { User } from '../models/user';
import { Company } from '../models/company';
import { Role } from '../models/role';
import { Apartment } from '../models/apartment';
import { Key } from '../models/key';
import { KeyHistory } from '../models/keyhistory';

export function DefaultSortingDataAccessor() {
  const accessors = new Map<string, (item: any) => any>([
    ['condominium', (item) => item.condominium?.name.toLocaleLowerCase() ?? ''],
    ['superIntendent', (item) => `${(item.superIntendent as User)?.fullName}`.toLocaleLowerCase()],
    ['company', (item) => (item.company as Company)?.name.toLocaleLowerCase()],
    ['role', (item) => (item.role as Role)?.name.toLocaleLowerCase()],
    ['apartment', (item) => (item.apartment as Apartment)?.fullAddress.toLocaleLowerCase()],
    ['apartmentOrCondominium', (item) => item.getApartmentOrCondominiumToString().toLocaleLowerCase()],
    ['holder', (item) => item.getHolderToString().toLocaleLowerCase()],
    ['originalHolder', (item) => item.getOriginalHolderToString().toLocaleLowerCase()],
    ['name', (item) => item.name?.toLocaleLowerCase()],
    ['keyLockType', (item: Key) => item.lockType.name.toLocaleLowerCase()],
    ['keyId', (item: KeyHistory) => item.key.id],
    ['keyStamp', (item: KeyHistory) => item.key.stamp.toLocaleLowerCase()],
  ]);
  return (item, property) => {
    let value = accessors.has(property) ? accessors.get(property)(item) : item[property];

    if (typeof value === 'string') {
      value = value.toLocaleLowerCase();
    }

    return value;
  };
}
