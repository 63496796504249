import { Injectable } from '@angular/core';
import { GeneralService } from '../../../services/general.service';
import { Apartment } from '../../../models/apartment';
import { debounceTime, filter, Observable, switchMap } from 'rxjs';
import { Key } from '../../../models/key';
import { Resident } from '../../../models/resident';

@Injectable({
  providedIn: 'root',
})
export class ApartmentService {
  constructor(private general: GeneralService) {}

  getApartments(): Observable<Apartment[]> {
    return this.general.get('apartments');
  }

  getApartment(id): Observable<Apartment> {
    return this.general.get(`apartments/${id}`);
  }

  getApartmentKeys(id): Observable<Key[]> {
    return this.general.get(`apartments/${id}/keys`);
  }

  getApartmentResidents(id): Observable<Resident[]> {
    return this.general.get(`apartments/${id}/residents`);
  }

  deleteApartment(id): Observable<void> {
    return this.general.delete(`apartments/${id}`);
  }

  updateApartment(apartment: Apartment): Observable<Apartment> {
    return this.general.update(`apartments`, apartment);
  }

  createApartment(apartment: Apartment): Observable<Apartment> {
    return this.general.post('apartments', apartment);
  }

  getKeysFree(ids: number[]) {
    return this.general.post('apartments/keys-free', { ids: ids });
  }

  upsertApartment(apartment: Apartment) {
    return apartment.id ? this.updateApartment(apartment) : this.createApartment(apartment);
  }

  search(terms: Observable<string>) {
    return terms.pipe(
      filter(Boolean),
      debounceTime(500),
      switchMap((term: string) => this.general.post('apartments/search', { searchPhrase: term }))
    );
  }
}
