import { Injectable } from '@angular/core';
import { GeneralService } from '../../../services/general.service';
import { User } from '../../../models/user';
import { Observable } from 'rxjs/internal/Observable';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { filter } from 'rxjs';
import { Condominium } from '../../../models/condominium';
import { UserSettings } from '../../../models/userSettings';
import { Key } from '../../../models/key';
import { AuditData } from '../../../models/auditData';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private general: GeneralService) {}

  getUsers(): Observable<User[]> {
    return this.general.get('users');
  }

  getUsersBidirectional(): Observable<User[]> {
    return this.general.get('users/bidirectional', true);
  }

  getUser(id): Observable<User> {
    return this.general.get('users/' + id);
  }

  getCurrentUser(): Observable<User> {
    return this.general.get('users/current');
  }

  getCurrentUserSettings(): Observable<UserSettings> {
    return this.general.get('users/current/settings');
  }

  saveCurrentUserSettings(settings: any): Observable<UserSettings> {
    return this.general.post('users/current/settings', settings);
  }

  getUserCondominia(id): Observable<Condominium[]> {
    return this.general.get('users/' + id + '/condominia');
  }

  deleteUser(id): Observable<void> {
    return this.general.delete('users/' + id);
  }

  updateUser(user: User): Observable<User> {
    return this.general.update('users', user);
  }

  changePassword(password: any): Observable<void> {
    return this.general.update('users/change-password', password);
  }

  getAuditData() {
    return this.general.get<AuditData>('users/audit/data');
  }

  getUserKeys(id: number): Observable<Key[]> {
    return this.general.get('users/' + id + '/keys');
  }

  search(terms: Observable<string>) {
    return terms.pipe(
      filter(Boolean),
      debounceTime(500),
      switchMap((term: string) => this.general.post('users/search', { searchPhrase: term }))
    );
  }
}
