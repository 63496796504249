<ng-container>
  <div class="modal-header">
    <h2 class="modal-title" mat-dialog-title>{{ 'key.returnKey' | translate }}</h2>
  </div>
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="form">
      <div class="mb-2">
        <mat-label>{{ 'key.returnTo' | translate }}</mat-label>
      </div>
      <mat-radio-group formControlName="returnTo">
        <mat-radio-button value="owner">{{ 'key.toOwner' | translate }}</mat-radio-button>
        <mat-radio-button class="ms-2" value="originalUser">{{ 'key.toOriginalHolder' | translate }}</mat-radio-button>
      </mat-radio-group>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button [mat-dialog-close]="false" color="primary" mat-flat-button>{{ 'general.close' | translate }}</button>
    <button (click)="save()" color="accent" mat-flat-button>{{ 'general.save' | translate }}</button>
  </mat-dialog-actions>
</ng-container>
