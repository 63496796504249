import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ResetRequest } from '../../../../models/resetRequest';
import { SnackService } from '../../../ui/services/snack.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'xkey-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  code: string;
  codeWrong = false;
  protected readonly environment = environment;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private snackService: SnackService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {}

  get f() {
    return this.form.controls;
  }

  async ngOnInit() {
    this.code = this.activatedRoute.snapshot.paramMap.get('code');

    if (this.loginService.getToken()) {
      await this.router.navigate(['/etusivu']);
    }

    if (this.code) {
      this.loginService.getResetPassword(this.code).subscribe({
        next: (resetRequest: ResetRequest) => {
          this.codeWrong = !!!resetRequest;
        },
        error: (error) => {
          console.error(error);
        },
      });

      this.form = this.formBuilder.group({
        password: ['', [Validators.required, Validators.pattern('^(?=.*[A-Z])(?=.*[\\W])(?=.*[0-9])(?=.*[a-z]).{8,128}$')]],
        passwordAgain: ['', Validators.required],
      });
    } else {
      this.form = this.formBuilder.group({
        email: ['', Validators.required],
      });
    }
  }

  resetPassword() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    if (this.form.value.password !== this.form.value.passwordAgain) {
      this.form.controls['passwordAgain'].setErrors({ noMatch: true });
      return;
    }

    const values = this.code ? { password: this.f.password.value, code: this.code } : { email: this.f.email.value };

    this.loginService.resetPassword(values).subscribe({
      next: async () => {
        this.snackService.showMessage(this.code ? 'forgot.passwordReset' : 'forgot.emailSent');
        await this.router.navigate(['/login']);
      },
      error: (error) => {
        console.error(error);
        this.snackService.showGeneralError();
      },
    });
  }
}
