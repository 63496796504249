import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { UserService } from '../../../user/services/user.service';
import { User } from 'src/app/models/user';
import { Company } from '../../../../models/company';
import { CompanyService } from '../../../company/services/company.service';
import { Resident } from '../../../../models/resident';
import { ResidentService } from '../../../resident/services/resident.service';
import { Apartment } from '../../../../models/apartment';
import { ApartmentService } from '../../../apartment/services/apartment.service';
import { Condominium } from '../../../../models/condominium';
import { CondominiumService } from '../../../condominium/services/condominium.service';
import { Key } from '../../../../models/key';
import { KeyService } from '../../../key/services/key.service';
import { LoginService } from '../../../auth/services/login.service';

@Component({
  selector: 'xkey-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements AfterViewInit {
  @ViewChild('searchInput') searchInput: ElementRef;

  searchTerm$ = new Subject<string>();
  searchPageOpen = false;
  searchPhrase = '';
  users: User[] = [];
  companies: Company[] = [];
  residents: Resident[] = [];
  apartments: Apartment[] = [];
  condominia: Condominium[] = [];
  keys: Key[] = [];
  private searchItems = [];

  constructor(
    private router: Router,
    private userService: UserService,
    private companyService: CompanyService,
    private residentService: ResidentService,
    private apartmentService: ApartmentService,
    private condominiumService: CondominiumService,
    private keyService: KeyService,
    private loginService: LoginService
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.hideSearch();
      }
    });

    this.searchItems = [
      { service: this.userService, list: 'users', permission: 'User_Read' },
      { service: this.companyService, list: 'companies', permission: 'Company_Read' },
      { service: this.residentService, list: 'residents', permission: 'Resident_Read' },
      { service: this.apartmentService, list: 'apartments', permission: 'Apartment_Read' },
      { service: this.condominiumService, list: 'condominia', permission: 'Condominium_Read' },
      { service: this.keyService, list: 'keys', permission: 'Key_Read' },
    ];
  }

  get searchPageIsOpen() {
    return this.searchPageOpen && this.searchPhrase.length > 0;
  }

  ngAfterViewInit(): void {
    this.searchTerm$.subscribe((value) => {
      if (value.length === 0) {
        this.hideSearch(false);
      }
    });

    setTimeout(() => {
      this.registerSearches();
    }, 500);
  }

  updateSearchPageOpenStatus(): void {
    const searchResultsExist = [this.users, this.companies, this.residents, this.apartments, this.condominia, this.keys].some((results) => results.length > 0);
    searchResultsExist ? this.openSearch() : this.hideSearch(false);
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent) {
    const element = event.target as HTMLElement;

    if (
      ['HTML', 'xkey-SEARCH-PAGE'].includes(element?.tagName) ||
      (element?.className?.includes && element?.className?.includes('search-overlay')) ||
      (element?.className?.indexOf && element?.className?.indexOf('search-overlay') === 0 && !this.searchPhrase)
    ) {
      this.hideSearch();
    }
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.hideSearch();
  }

  openSearch() {
    this.searchPageOpen = true;
  }

  updateSearch() {
    this.searchTerm$.next(this.searchPhrase);
  }

  private registerSearches() {
    this.searchItems.forEach((item) => {
      if (this.loginService.userHasPermissions(item.permission)) {
        item.service.search(this.searchTerm$).subscribe((results: any[]) => {
          this[item.list] = results;
          this.updateSearchPageOpenStatus();
        });
      }
    });
  }

  private hideSearch(blurSearchBar: boolean = true) {
    this.searchPageOpen = false;

    if (this.searchInput && blurSearchBar) {
      this.searchPhrase = '';
      this.searchTerm$.next('');
      this.searchInput.nativeElement.blur();
    }
  }
}
