<ng-container>
  <div class="page-header">
    <h2>
      <mat-icon class="mobile-hidden">info</mat-icon>
      {{ 'privacyPolicy.header' | translate }}
    </h2>
  </div>
  <div class="card">
    <div>
      <h3>Rekisterinpitäjä</h3>
      <p>Xkii Oy</p>
      <p>Y-tunnus: 3362239-1</p>
    </div>

    <div class="mt-4">
      <h3>Tietosuojavastaava</h3>
      <p>Xkii.fi vastaa tämän palvelun kautta kerättyjen henkilötietojen käsittelystä. Voit ottaa tarvittaessa yhteyttä alla oleviin yhteystietoihin.</p>
      <p>
        Xkii Oy<br />
        Lauri Mukkala<br />
        <a href="mailto:info@xkey.fi">info&#64;xkey.fi</a>
      </p>
    </div>

    <div class="mt-4">
      <h3>Rekisterin käyttötarkoitus</h3>
      <p>Keräämme henkilötietoja asiakkaan tunnistautumista, käyttöoikeuksien hallintaa, tilausten toimittamista, asiakassuhteen ylläpitoa ja kehittämistä varten.</p>
    </div>

    <div class="mt-4">
      <h3>Tietojen keräämisen ja käsittelyn peruste</h3>
      <p>Keräämme ja käsittelemme henkilötietoja asiakkaan suostumuksella tai sopimuksen täytäntöönpanon vuoksi.</p>
    </div>

    <div class="mt-4">
      <h3>Rekisterin tietosisältö</h3>
      <p>Rekisteriin kerätään henkilötietoja, kuten nimi, sähköposti, puhelinnumero, yrityksen nimi ja asema, asiakassuhteeseen liittyvät tiedot, yhteydenpitohistoria ja verkkosivuston käyttöanalytiikka.</p>
    </div>

    <div class="mt-4">
      <h3>Tietojen säilytysaika</h3>
      <p>Säilytämme henkilötietoja niin kauan kuin niitä tarvitaan sopimuksen täytäntöönpanoon tai asiakaspalvelun kehittämiseen.</p>
    </div>

    <div class="mt-4">
      <h3>Säännönmukaiset tietolähteet</h3>
      <p>Keräämme tietoja asiakkaalta itseltään sekä Google Analytics -analytiikkatyökalun avulla.</p>
    </div>

    <div class="mt-4">
      <h3>Säännönmukaiset tietojen luovutukset ja siirrot EU:n tai Euroopan talousalueen ulkopuolelle</h3>
      <p>Emme luovuta tietoja yrityksen ulkopuolelle, mutta osa käyttämistämme ulkopuolisista palveluntarjoajista saattaa säilyttää tietoja EU:n tai Euroopan talousalueen ulkopuolella.</p>
    </div>

    <div class="mt-4">
      <h3>Evästeiden käyttö</h3>
      <p>Käytämme evästeitä sivustomme parantamiseen ja käyttökokemuksen personointiin. Kävijät voivat halutessaan estää evästeiden käytön selaimensa asetuksista.</p>
    </div>

    <div class="mt-4">
      <h3>Rekisterin suojaus</h3>
      <p>Tiedot siirretään SSL-suojatulla yhteydellä ja sähköiset tiedot suojataan palomuurilla, käyttäjätunnuksin ja salasanoin. Tietojen käyttöoikeus on vain niillä henkilöillä, jotka tarvitsevat niitä tehtävissään.</p>
    </div>

    <div class="mt-4">
      <h3>Automaattinen päätöksenteko</h3>
      <p>Emme käytä automaattista päätöksentekoa henkilötietojen käsittelyssä.</p>
    </div>

    <div class="mt-4">
      <h3>Rekisteröidyn oikeudet</h3>
      <p
        >Rekisteröidyllä on oikeus pyytää tietoa siitä, mitä henkilötietoja on tallennettu häntä koskevaan henkilötietorekisteriin. Tarkastuspyyntö tulee lähettää kirjallisena ja allekirjoitettuna rekisteriasioista vastaavalle
        henkilölle.</p
      >
      <p>Rekisteröidyllä on oikeus pyytää maksutonta tarkastusta enintään kerran vuodessa.</p>
      <p
        >Rekisteröidyllä on oikeus pyytää virheellisten tai vanhentuneiden tietojen korjaamista tai poistamista, tai pyytää tietojen siirtämistä toiseen järjestelmään. Lisäksi hänellä on oikeus rajoittaa tai vastustaa tietojensa käsittelyä
        EU:n tietosuoja-asetuksen artiklojen 18 ja 21 mukaisesti.</p
      >
      <p>Rekisteröidyllä on oikeus peruuttaa aiemmin antamansa suostumus tietojensa käsittelylle, tai tehdä valitus valvontaviranomaiselle henkilötietojensa käsittelyyn liittyvistä asioista.</p>
      <p>Lisäksi rekisteröidyllä on oikeus kieltää henkilötietojensa käyttö suoramarkkinointitarkoituksiin.</p>
    </div>
  </div>
</ng-container>
