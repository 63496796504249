import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ResidentService } from '../../services/resident.service';
import { Resident } from '../../../../models/resident';
import { ApartmentService } from '../../../apartment/services/apartment.service';
import { Apartment } from '../../../../models/apartment';
import { ResidentModalComponent } from '../resident-modal/resident-modal.component';
import { LoginService } from '../../../auth/services/login.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { lastValueFrom } from 'rxjs';
import { OpenersService } from '../../../ui/services/openers.service';
import { HandsetObserverService } from '../../../ui/services/handset-observer.service';
import { DefaultSortingDataAccessor } from '../../../../helpers/DefaultSortingDataAccessor';
import { DefaultFilterPredicate } from '../../../../helpers/DefaultFilterPredicate';
import { AnimationTriggerMetadata } from '../../../../helpers/AnimationTriggerMetadata';
import { DialogService } from '../../../ui/services/dialog.service';
import { CompanyService } from '../../../company/services/company.service';

@Component({
  selector: 'xkey-resident',
  templateUrl: './resident.component.html',
  styleUrls: ['./resident.component.scss'],
  animations: [AnimationTriggerMetadata()],
})
export class ResidentComponent implements OnInit {
  dataSource = new MatTableDataSource<Resident>([]);
  displayedColumnsDesktop = ['fullName', 'phone', 'email', 'apartment', 'open'];
  displayedColumnsMobile = ['arrow', 'fullName', 'open'];
  expandedElement: Resident | null;
  pageSize = 50;

  @Input() apartment: Apartment;
  @Input() fromSearch = false;
  @Input() showHeader = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private residentService: ResidentService,
    private apartmentService: ApartmentService,
    private companyService: CompanyService,
    public handsetObserverService: HandsetObserverService,
    public openers: OpenersService,
    public loginService: LoginService,
    private dialogService: DialogService
  ) {}

  private _residents: Resident[] = [];

  get residents(): Resident[] {
    return this._residents;
  }

  @Input()
  set residents(val: Resident[]) {
    this._residents = val;

    if (this.fromSearch) {
      this.setupComponent().then();
    }
  }

  async ngOnInit() {
    await this.setupComponent();
  }

  async setupComponent() {
    if (this.fromSearch) {
      this.pageSize = 5;
    }

    await this.getResidents();
  }

  async openCreateModal() {
    const apartments = await lastValueFrom(this.apartmentService.getApartments());
    const owners = await lastValueFrom(this.companyService.getCompanies());

    this.dialogService.showDialog(ResidentModalComponent, { presetApartment: this.apartment ?? undefined, apartments, owners }, async (result: Resident) => {
      await this.setupComponent();
    });
  }

  async getResidents() {
    if (!this.fromSearch) {
      if (this.apartment) {
        this.residents = await lastValueFrom(this.apartmentService.getApartmentResidents(this.apartment.id));
      } else {
        this.residents = await lastValueFrom(this.residentService.getResidents());
      }
    }

    this.dataSource = new MatTableDataSource(this.residents);
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = DefaultFilterPredicate();
    this.dataSource.sortingDataAccessor = DefaultSortingDataAccessor();
    this.dataSource.sort = this.sort;
  }

  applyFilter($event: KeyboardEvent) {
    const filterValue = ($event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
