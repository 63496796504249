export function DefaultFilterPredicate() {
  const searchObject = (obj: any, filter: string): boolean => {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        const value = obj[prop];
        if (typeof value === 'object') {
          if (searchObject(value, filter)) {
            return true;
          }
        } else if (typeof value === 'string') {
          if (value.toLowerCase().indexOf(filter) !== -1) {
            return true;
          }
        }
      }
    }
    return false;
  };

  return (data: any, filter: string) => {
    return searchObject(data, filter.toLowerCase());
  };
}
