import { NgModule } from '@angular/core';
import { CustomisationsService } from './services/customisations.service';

@NgModule({
  declarations: [],
  imports: [],
  providers: [CustomisationsService],
  exports: [],
  schemas: [],
})
export class CustomisationsModule {}
