<ng-container>
  <div class="modal-header">
    <h2 class="modal-title" mat-dialog-title>{{ data.condominium.id ? ('condominium.edit' | translate) : ('apartment.condominium' | translate) }}</h2>
  </div>
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="form">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'condominium.name' | translate }}</mat-label>
        <input autocomplete="off" formControlName="name" matInput placeholder="{{ 'condominium.name' | translate }}" />
        <mat-error *ngIf="f.name.errors && f.name.errors.required">{{ 'condominium.nameIsRequired' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'condominium.businessId' | translate }}</mat-label>
        <input autocomplete="off" formControlName="businessId" matInput placeholder="{{ 'condominium.businessId' | translate }}" />
        <mat-error *ngIf="f.businessId.errors && f.businessId.errors.required">{{ 'condominium.businessIdIsRequired' | translate }}</mat-error>
        <mat-error *ngIf="f.businessId.errors && f.businessId.errors.pattern">{{ 'condominium.businessIdIsInWrongFormat' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'condominium.number' | translate }}</mat-label>
        <input autocomplete="off" formControlName="number" matInput placeholder="{{ 'condominium.number' | translate }}" type="number" />
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'condominium.address' | translate }}</mat-label>
        <input autocomplete="off" formControlName="addressStreet" matInput placeholder="{{ 'condominium.address' | translate }}" />
        <mat-error *ngIf="f.addressStreet.errors && f.addressStreet.errors.required">{{ 'condominium.addressIsRequired' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'condominium.postalCode' | translate }}</mat-label>
        <input autocomplete="off" formControlName="addressPostal" matInput placeholder="{{ 'condominium.postalCode' | translate }}" />
        <mat-error *ngIf="f.addressPostal.errors && f.addressPostal.errors.required">{{ 'condominium.postalCodeIsRequired' | translate }}</mat-error>
        <mat-error *ngIf="f.addressPostal.errors && f.addressPostal.errors.pattern">{{ 'condominium.postalCodeIsInWrongFormat' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'condominium.city' | translate }}</mat-label>
        <input autocomplete="off" formControlName="addressCity" matInput placeholder="{{ 'condominium.city' | translate }}" />
        <mat-error *ngIf="f.addressCity.errors && f.addressCity.errors.required">{{ 'condominium.cityIsRequired' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'condominium.superIntendent' | translate }}</mat-label>
        <mat-select [formControl]="superIntendentCtrl" placeholder="{{ 'condominium.superIntendent' | translate }}">
          <mat-option>
            <ngx-mat-select-search [formControl]="superIntendentFilterCtrl" [noEntriesFoundLabel]="'general.noEntriesFound' | translate" [placeholderLabel]="'general.selectSearch' | translate"></ngx-mat-select-search>
          </mat-option>
          <mat-option></mat-option>
          <mat-option *ngFor="let superIntendent of filteredSuperIntendents | async" [value]="superIntendent.id">
            {{ superIntendent.fullName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'condominium.lockType' | translate }}</mat-label>
        <mat-select [formControl]="lockTypeCtrl" placeholder="{{ 'condominium.lockType' | translate }}">
          <mat-option>
            <ngx-mat-select-search [formControl]="lockTypeFilterCtrl" [noEntriesFoundLabel]="'general.noEntriesFound' | translate" [placeholderLabel]="'general.selectSearch' | translate"></ngx-mat-select-search>
          </mat-option>
          <mat-option></mat-option>
          <mat-option *ngFor="let lockType of filteredLockTypes | async" [value]="lockType.id">
            {{ lockType.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'condominium.keyType' | translate }}</mat-label>
        <mat-select [formControl]="keyTypeCtrl" placeholder="{{ 'condominium.keyType' | translate }}">
          <mat-option>
            <ngx-mat-select-search [formControl]="keyTypeFilterCtrl" [noEntriesFoundLabel]="'general.noEntriesFound' | translate" [placeholderLabel]="'general.selectSearch' | translate"></ngx-mat-select-search>
          </mat-option>
          <mat-option></mat-option>
          <mat-option *ngFor="let keyType of filteredKeyTypes | async" [value]="keyType.id">
            {{ keyType.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'condominium.apartmentKeyType' | translate }}</mat-label>
        <mat-select [formControl]="apartmentKeyTypeCtrl" placeholder="{{ 'condominium.apartmentKeyType' | translate }}">
          <mat-option>
            <ngx-mat-select-search [formControl]="apartmentKeyTypeFilterCtrl" [noEntriesFoundLabel]="'general.noEntriesFound' | translate" [placeholderLabel]="'general.selectSearch' | translate"></ngx-mat-select-search>
          </mat-option>
          <mat-option></mat-option>
          <mat-option *ngFor="let apartmentKeyType of filteredApartmentKeyTypes | async" [value]="apartmentKeyType.id">
            {{ apartmentKeyType.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'condominium.owner' | translate }}</mat-label>
        <mat-select [formControl]="ownerCtrl" placeholder="{{ 'condominium.owner' | translate }}">
          <mat-option>
            <ngx-mat-select-search [formControl]="ownerFilterCtrl" [noEntriesFoundLabel]="'general.noEntriesFound' | translate" [placeholderLabel]="'general.selectSearch' | translate"></ngx-mat-select-search>
          </mat-option>
          <mat-option></mat-option>
          <mat-option *ngFor="let owner of filteredOwners | async" [value]="owner.id">
            {{ owner.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button [mat-dialog-close]="false" color="primary" mat-flat-button>{{ 'general.close' | translate }}</button>
    <button (click)="save()" color="accent" mat-flat-button>{{ 'general.save' | translate }}</button>
  </mat-dialog-actions>
</ng-container>
