import { Injectable } from '@angular/core';
import { Apartment } from '../../../models/apartment';
import { Router } from '@angular/router';
import { LoginService } from '../../auth/services/login.service';
import { Resident } from '../../../models/resident';
import { Condominium } from '../../../models/condominium';
import { User } from '../../../models/user';
import { Company } from '../../../models/company';
import { Key } from '../../../models/key';
import { KeyHistory } from '../../../models/keyhistory';
import { SnackService } from './snack.service';

@Injectable({
  providedIn: 'root',
})
export class OpenersService {
  constructor(
    private router: Router,
    private loginService: LoginService,
    private snackService: SnackService
  ) {}

  public async openApartment(apartment: Apartment, neededCompanyId?: number) {
    if (!apartment || (apartment && !this.loginService.userHasPermissions('Apartment_Read')) || (neededCompanyId && !this.loginService.userHasRightCompany(neededCompanyId))) {
      this.showNoPermissionSnack();
      return;
    }

    await this.router.navigate(['/asunnot/' + apartment.id]);
  }

  public async openResident(resident: Resident) {
    if (!resident || (resident && !this.loginService.userHasPermissions('Resident_Read'))) {
      this.showNoPermissionSnack();
      return;
    }

    await this.router.navigate(['/asukkaat/' + resident.id]);
  }

  public async openCondominium(condominium: Condominium, neededCompanyId?: number) {
    if (!condominium || (condominium && !this.loginService.userHasPermissions('Condominium_Read')) || (neededCompanyId && !this.loginService.userHasRightCompany(neededCompanyId))) {
      this.showNoPermissionSnack();
      return;
    }

    await this.router.navigate(['/kiinteistot/' + condominium.id]);
  }

  public async openUser(user: User) {
    if (!user || (user && !this.loginService.userHasPermissions('User_Read'))) {
      this.showNoPermissionSnack();
      return;
    }

    await this.router.navigate(['/kayttajat/' + user.id]);
  }

  async openCompany(company: Company) {
    if (!company || (company && !this.loginService.userHasPermissions('Company_Read'))) {
      this.showNoPermissionSnack();
      return;
    }

    await this.router.navigate(['/yritykset/' + company.id]);
  }

  async openKey(key: Key) {
    if (!key || (key && !this.loginService.userHasPermissions('Key_Read'))) {
      this.showNoPermissionSnack();
      return;
    }

    await this.router.navigate(['/avaimet/' + key.id]);
  }

  async openKeyHistory(keyHistory: KeyHistory) {
    if (!keyHistory || (keyHistory && !this.loginService.userHasPermissions('Key_Read'))) {
      this.showNoPermissionSnack();
      return;
    }

    await this.router.navigate(['/avain-historiat/' + keyHistory.id]);
  }

  private showNoPermissionSnack = () => {
    this.snackService.showMessage('general.noPermission');
  };
}
