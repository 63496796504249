import { Component, Inject, OnInit } from '@angular/core';
import { PermissionService } from '../../services/permission.service';
import { Permission } from '../../../../models/permission';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackService } from '../../../ui/services/snack.service';

@Component({
  selector: 'xkey-permission-modal',
  templateUrl: './permission-modal.component.html',
  styleUrls: ['./permission-modal.component.scss'],
})
export class PermissionModalComponent implements OnInit {
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private permissionService: PermissionService,
    private snackService: SnackService,
    public dialogRef: MatDialogRef<PermissionModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      permission?: Permission;
    }
  ) {
    data.permission = data.permission ?? new Permission();
  }

  get f() {
    return this.form.controls;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [this.data.permission.name, Validators.required],
    });
  }

  save() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.data.permission.name = this.f.name.value;
    this.savePermission(this.data.permission);
  }

  savePermission(permission: Permission) {
    const isNew = !permission.id;

    this.permissionService.upsertPermission(permission).subscribe({
      next: (result) => {
        this.snackService.showMessage(isNew ? 'permission.created' : 'permission.updated');
        this.dialogRef.close(result);
      },
      error: (error) => {
        console.error(error);
        this.snackService.showGeneralError();
      },
    });
  }
}
