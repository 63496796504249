import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  constructor(private http: HttpClient) {}

  get<T>(address: string, skipLoading?: boolean, params?: any): Observable<T> {
    const headers = this.createHeaders(skipLoading);
    const queryParams = this.createParams(params);
    return this.http.get<T>(environment.serverUrls[window.location.hostname] + address, { headers, params: queryParams });
  }

  delete<T>(address: string): Observable<T> {
    return this.http.delete<T>(environment.serverUrls[window.location.hostname] + address);
  }

  update<T>(address: string, object: unknown): Observable<T> {
    return this.http.put<T>(environment.serverUrls[window.location.hostname] + address, object);
  }

  post<T>(address: string, object: unknown, skipLoading?: boolean): Observable<T> {
    const headers = this.createHeaders(skipLoading);
    return this.http.post<T>(environment.serverUrls[window.location.hostname] + address, object, { headers });
  }

  private createHeaders(skipLoading?: boolean): HttpHeaders {
    const headers = {};
    if (skipLoading) {
      headers['X-Skip-Loading'] = '1';
    }
    return new HttpHeaders(headers);
  }

  private createParams(params?: any): HttpParams {
    let httpParams = new HttpParams();
    if (params) {
      Object.keys(params).forEach((key) => {
        httpParams = httpParams.append(key, params[key]);
      });
    }
    return httpParams;
  }
}
