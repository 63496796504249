import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { KeyModule } from '../key/key.module';
import { CondominiumModule } from '../condominium/condominium.module';
import { ApartmentModule } from '../apartment/apartment.module';
import { ResidentModule } from '../resident/resident.module';
import { CompanyModule } from '../company/company.module';
import { UserModule } from '../user/user.module';
import { SharedModule } from '../shared/shared.module';
import { XTranslateModule } from '../x-translate/translate.module';

@NgModule({
  declarations: [SearchBarComponent],
  imports: [SharedModule, XTranslateModule, CommonModule, KeyModule, CondominiumModule, ApartmentModule, ResidentModule, CompanyModule, UserModule],
  exports: [SearchBarComponent],
})
export class SearchBarModule {}
