<div *ngIf="selectedUser">
  <div class="page-header">
    <h2>
      <mat-icon>account_circle</mat-icon>
      {{ 'user.headerSingle' | translate }} {{ selectedUser.fullName }}
    </h2>
  </div>
  <div class="card main-card">
    <div *ngIf="!(handsetObserverService.isSmallScreen$ | async)" class="buttons">
      <ng-container *ngTemplateOutlet="buttons"></ng-container>
    </div>

    <mat-accordion *ngIf="handsetObserverService.isSmallScreen$ | async">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'general.actions' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="buttons"></ng-container>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-tab-group (selectedTabChange)="tabChanged($event)" [(selectedIndex)]="tabIndex" mat-stretch-tabs>
      <mat-tab *ngIf="loginService.userHasPermissions('User_Read')" [label]="'general.basicInformation' | translate">
        <div class="card basic-info">
          <div class="row">
            <div class="col-sm-6">
              <xkey-basic-info-field [title]="'user.name'" [value]="selectedUser.firstName + ' ' + selectedUser.lastName"></xkey-basic-info-field>
              <xkey-basic-info-field [title]="'user.email'" [value]="selectedUser.email"></xkey-basic-info-field>
              <xkey-basic-info-field [title]="'user.phone'" [value]="selectedUser.phone"></xkey-basic-info-field>
            </div>
            <div class="col-sm-6">
              <xkey-basic-info-field (click)="openers.openCompany(selectedUser.company)" [isClickable]="true" [title]="'user.company'" [value]="selectedUser.company?.name"></xkey-basic-info-field>
              <xkey-basic-info-field [title]="'user.role'" [value]="selectedUser.role?.name"></xkey-basic-info-field>
              <xkey-basic-info-field [title]="'user.active'" [value]="selectedUser.active ? ('general.yes' | translate) : ('general.no' | translate)"></xkey-basic-info-field>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab *ngIf="loginService.userHasPermissions('Condominium_Read')" [label]="'condominium.header' | translate">
        <xkey-condominium [user]="selectedUser"></xkey-condominium>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<ng-template #buttons>
  <xkey-go-back-button [defaultUrl]="'/yritykset/' + selectedUser.company.id"></xkey-go-back-button>
  <button (click)="openModifyModal(selectedUser.id)" [disabled]="!loginService.userHasPermissions('User_Update')" class="block-on-mobile" color="accent" mat-flat-button>
    <mat-icon>edit</mat-icon>
    {{ 'general.edit' | translate }}
  </button>
  <button (click)="openDeleteModal(selectedUser.id)" [disabled]="!loginService.userHasPermissions('User_Delete')" class="block-on-mobile" color="warn" mat-flat-button>
    <mat-icon>delete</mat-icon>
    {{ 'general.delete' | translate }}
  </button>
</ng-template>
