import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Condominium } from '../../../../models/condominium';
import { CondominiumService } from '../../services/condominium.service';
import { ReplaySubject } from 'rxjs';
import { FilterByField } from '../../../../helpers/DropdownFilters';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackService } from '../../../ui/services/snack.service';
import { Company } from '../../../../models/company';
import { LoginService } from '../../../auth/services/login.service';

@Component({
  selector: 'xkey-condominium-modal',
  templateUrl: './condominium-transfer-modal.component.html',
  styleUrls: ['./condominium-transfer-modal.component.scss'],
})
export class CondominiumTransferModalComponent implements OnInit {
  public ownerCtrl: FormControl = new FormControl();
  public ownerFilterCtrl: FormControl = new FormControl();
  public filteredOwners: ReplaySubject<Company[]> = new ReplaySubject<Company[]>(1);

  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private condominiumService: CondominiumService,
    private snackService: SnackService,
    private loginService: LoginService,
    public dialogRef: MatDialogRef<CondominiumTransferModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      condominium: Condominium;
      owners: Company[];
    }
  ) {
    data.condominium = data.condominium ?? new Condominium();
    data.owners = data.owners ?? [];
  }

  get f() {
    return this.form.controls;
  }

  ngOnInit() {
    this.ownerCtrl.setValue((this.data.condominium?.owner as Company)?.id ?? this.loginService.getCompany());
    this.filteredOwners.next(this.data.owners.slice());

    this.ownerFilterCtrl.valueChanges.subscribe(() => {
      FilterByField()(this.filteredOwners, this.data.owners, 'name', this.ownerFilterCtrl.value?.toLowerCase());
    });

    this.form = this.formBuilder.group({
      name: [this.data.condominium.name],
      owner: this.ownerCtrl,
    });

    this.f.name.disable();
  }

  save() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.transferCondominium();
  }

  close() {
    this.dialogRef.close();
  }

  transferCondominium() {
    this.condominiumService.transferCondominium(this.data.condominium.id, this.f.owner.value).subscribe({
      next: (result) => {
        this.snackService.showMessage('condominium.transferred');
        this.dialogRef.close(result);
      },
      error: (error) => {
        this.snackService.showGeneralError();
        console.error(error);
      },
    });
  }
}
