import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'xkey-basic-info-field',
  templateUrl: './basic-info-field.component.html',
  styleUrls: ['./basic-info-field.component.scss'],
})
export class BasicInfoFieldComponent implements OnInit {
  @Input() title: string;
  @Input() value: string | number;
  @Input() isClickable = false;
  @Input() icon: string;

  constructor() {}

  ngOnInit(): void {}
}
