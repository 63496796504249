import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  public showDialog<T>(modalComponent: any, data: any, callback: (result: T) => void) {
    const dialogRef = this.dialog.open(modalComponent, { data });

    dialogRef.afterClosed().subscribe((result: T) => {
      if (!result) {
        return;
      }

      callback(result);
    });
  }
}
