import { Company } from './company';
import { Role } from './role';

export class Invitation {
  id: number;
  code: string;
  createdAt: Date;
  company: Company | number;
  companyId: number;
  role: Role | number;
  roleId: number;
  email: string;
  used: boolean;
}
