<div class="card">
  <div class="datatable mt-2">
    <mat-form-field [floatLabel]="'always'" appearance="outline" class="full-width-search mt-2">
      <mat-label>{{ 'general.search' | translate }}</mat-label>
      <mat-icon matPrefix>search</mat-icon>
      <input (keyup)="applyFilter($event)" matInput />
    </mat-form-field>

    <ng-template #desktopTable>
      <mat-table [dataSource]="dataSource" [matSortDirection]="'asc'" matSort matSortActive="apartmentOrCondominium" matSortDisableClear>
        <ng-container matColumnDef="apartmentOrCondominium">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'key.apartmentOrCondominium' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span (click)="openers.openApartment(row.apartment)" *ngIf="row.apartment" class="link"> {{ row.apartment?.fullAddress }}</span>
            <span (click)="openers.openCondominium(row.condominium)" *ngIf="row.condominium" class="link"> {{ row.condominium.name }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'condominium.type' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.type?.name }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="keyCount">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'condominium.keys' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.keyCount }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsDesktop"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsDesktop"></mat-row>
      </mat-table>
    </ng-template>

    <ng-template #mobileTable>
      <mat-table [dataSource]="dataSource" [matSortDirection]="'desc'" matSort matSortActive="date" matSortDisableClear multiTemplateDataRows>
        <ng-container matColumnDef="arrow">
          <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-icon>{{ expandedElement && expandedElement.id === row.id ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}</mat-icon>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="apartmentOrCondominium">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'key.apartmentOrCondominium' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span (click)="openers.openApartment(row.apartment)" *ngIf="row.apartment" class="link"> {{ row.apartment?.fullAddress }}</span>
            <span (click)="openers.openCondominium(row.condominium)" *ngIf="row.condominium && row.condominium" class="link"> {{ row.condominium.name }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td *matCellDef="let element" [attr.colspan]="displayedColumnsMobile.length" mat-cell>
            <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'" class="element-detail">
              <xkey-basic-info-field (click)="openers.openCondominium(element.condominium)" *ngIf="element.condominium" [isClickable]="true" [title]="'condominium.headerSingle'" [value]="element.condominium?.name"></xkey-basic-info-field>
              <xkey-basic-info-field
                (click)="openers.openApartment(element.apartment)"
                *ngIf="element.apartment"
                [isClickable]="true"
                [title]="'condominium.apartment'"
                [value]="element.apartment.addressStreet + ', ' + element.apartment.addressPostalCode + ' ' + element.apartment.addressCity"
              ></xkey-basic-info-field>
              <xkey-basic-info-field [title]="'condominium.keys'" [value]="element.keyCount"></xkey-basic-info-field>
            </div>
          </td>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsMobile"></mat-header-row>
        <mat-row (click)="expandedElement = expandedElement === element ? null : element" *matRowDef="let element; columns: displayedColumnsMobile" [class.expanded-row]="expandedElement === element" class="element-row"></mat-row>

        <tr
          *matRowDef="let row; columns: ['expandedDetail']"
          [ngClass]="{
            'expanded-detail': expandedElement && expandedElement.id == row.id,
            'closed-detail': !(expandedElement && expandedElement.id == row.id)
          }"
          class="detail-row"
          mat-row
        ></tr>
      </mat-table>
    </ng-template>

    <ng-container *ngIf="handsetObserverService.isHandSet$ | async">
      <ng-template [ngTemplateOutlet]="mobileTable"></ng-template>
    </ng-container>

    <ng-container *ngIf="!(handsetObserverService.isHandSet$ | async)">
      <ng-template [ngTemplateOutlet]="desktopTable"></ng-template>
    </ng-container>

    <mat-paginator #paginator [length]="dataSource.data.length" [pageIndex]="0" [pageSizeOptions]="[25, 50, 100, 250]" [pageSize]="50"> </mat-paginator>
  </div>
</div>
