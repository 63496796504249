export class Company {
  id: number;
  name: string;
  addressStreet: string;
  addressPostalCode: string;
  addressCity: string;
  parent: Company;
  fullAddress: string;
  keyAgreement: string;
}
