import { Component, Inject, OnInit } from '@angular/core';
import { KeyService } from '../../services/key.service';
import { Key } from '../../../../models/key';
import { Apartment } from '../../../../models/apartment';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Condominium } from '../../../../models/condominium';
import { LockType } from '../../../../models/lockType';
import { ReplaySubject } from 'rxjs';
import { KeyType } from '../../../../models/keyType';
import { FilterByField } from '../../../../helpers/DropdownFilters';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackService } from '../../../ui/services/snack.service';
import { Company } from '../../../../models/company';
import { LoginService } from '../../../auth/services/login.service';

@Component({
  selector: 'xkey-key-modal',
  templateUrl: './key-modal.component.html',
  styleUrls: ['./key-modal.component.scss'],
})
export class KeyModalComponent implements OnInit {
  public form: FormGroup;

  public lockTypeCtrl: FormControl = new FormControl();
  public lockTypeFilterCtrl: FormControl = new FormControl();
  public filteredLockTypes: ReplaySubject<LockType[]> = new ReplaySubject<[]>(1);

  public keyTypeCtrl: FormControl = new FormControl();
  public keyTypeFilterCtrl: FormControl = new FormControl();
  public filteredKeyTypes: ReplaySubject<KeyType[]> = new ReplaySubject<[]>(1);

  public condominiumCtrl: FormControl = new FormControl();
  public condominiumFilterCtrl: FormControl = new FormControl();
  public filteredCondominia: ReplaySubject<Condominium[]> = new ReplaySubject<[]>(1);

  public apartmentCtrl: FormControl = new FormControl();
  public apartmentFilterCtrl: FormControl = new FormControl();
  public filteredApartments: ReplaySubject<Apartment[]> = new ReplaySubject<[]>(1);

  public ownerCtrl: FormControl = new FormControl();
  public ownerFilterCtrl: FormControl = new FormControl();
  public filteredOwners: ReplaySubject<Company[]> = new ReplaySubject<[]>(1);

  constructor(
    private formBuilder: FormBuilder,
    private keyService: KeyService,
    private snackService: SnackService,
    private loginService: LoginService,
    public dialogRef: MatDialogRef<KeyModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      key?: Key;
      lockTypes?: LockType[];
      keyTypes?: KeyType[];
      apartments?: Apartment[];
      condominia?: Condominium[];
      presetCondominium?: Condominium;
      presetApartment?: Apartment;
      presetKeyType: KeyType;
      presetLockType: LockType;
      owners: Company[];
    }
  ) {
    data.key = data.key ?? new Key();
    data.lockTypes = data.lockTypes ?? [];
    data.keyTypes = data.keyTypes ?? [];
    data.apartments = data.apartments ?? [];
    data.owners = data.owners ?? [];
  }

  get f() {
    return this.form.controls;
  }

  ngOnInit(): void {
    this.keyTypeCtrl.setValidators(Validators.required);

    this.lockTypeCtrl.setValue(this.data.presetLockType?.id ?? (this.data.key.lockType as LockType)?.id ?? undefined);
    this.keyTypeCtrl.setValue(this.data.presetKeyType?.id ?? (this.data.key.type as KeyType)?.id ?? undefined);
    this.apartmentCtrl.setValue(this.data.presetApartment?.id ?? (this.data.key.apartment as Apartment)?.id ?? undefined);
    this.condominiumCtrl.setValue(this.data.presetCondominium?.id ?? (this.data.key.condominium as Condominium)?.id ?? undefined);
    this.ownerCtrl.setValue((this.data.presetCondominium?.owner as Company)?.id ?? (this.data.presetApartment?.owner as Company)?.id ?? this.data.key.owner?.id ?? this.loginService.getCompany());

    this.filteredLockTypes.next(this.data.lockTypes.slice());
    this.filteredKeyTypes.next(this.data.keyTypes.slice());
    this.filteredApartments.next(this.data.apartments.slice());
    this.filteredCondominia.next(this.data.condominia.slice());
    this.filteredOwners.next(this.data.owners.slice());

    this.form = this.formBuilder.group({
      stamp: [this.data.key.stamp],
      amount: [1],
      type: this.keyTypeCtrl,
      lockType: this.lockTypeCtrl,
      apartment: this.apartmentCtrl,
      condominium: this.condominiumCtrl,
      owner: this.ownerCtrl,
    });

    this.setFilters();
  }

  save() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.data.key.stamp = this.f.stamp.value;
    this.data.key.lockTypeId = this.f.lockType.value;
    this.data.key.condominiumId = this.f.condominium.value;
    this.data.key.apartmentId = this.f.apartment.value;
    this.data.key.typeId = this.f.type.value;
    this.data.key.ownerId = this.f.owner.value;
    this.saveKey(this.data.key);
  }

  saveKey(key: Key) {
    const isNew = !key.id;

    this.keyService.upsertKey(key, this.f.amount.value).subscribe({
      next: (result) => {
        this.snackService.showMessage(isNew ? 'key.created' : 'key.updated');
        this.dialogRef.close(result);
      },
      error: (error) => {
        console.error(error);
        this.snackService.showGeneralError();
      },
    });
  }

  keyTypeChanged(event: any) {
    const keyType = this.data.keyTypes.find((k) => k.id === event.value);

    if (keyType.generalKey) {
      this.apartmentCtrl.setValidators(null);
      this.apartmentCtrl.setErrors(null);
      this.condominiumCtrl.setValidators(Validators.required);
    } else {
      this.apartmentCtrl.setValidators(Validators.required);
      this.condominiumCtrl.setValidators(null);
      this.condominiumCtrl.setErrors(null);
    }

    this.form.updateValueAndValidity();
  }

  isGeneralKey(keyTypeId: number) {
    const keyType = this.data.keyTypes.find((k) => k.id === keyTypeId);
    return keyType.generalKey;
  }

  private setFilters() {
    this.keyTypeFilterCtrl.valueChanges.subscribe(() => {
      FilterByField()(this.filteredKeyTypes, this.data.keyTypes, 'name', this.keyTypeFilterCtrl.value?.toLowerCase());
    });

    this.lockTypeFilterCtrl.valueChanges.subscribe(() => {
      FilterByField()(this.filteredLockTypes, this.data.lockTypes, 'name', this.lockTypeFilterCtrl.value?.toLowerCase());
    });

    this.apartmentFilterCtrl.valueChanges.subscribe(() => {
      FilterByField()(this.filteredApartments, this.data.apartments, 'fullAddress', this.apartmentFilterCtrl.value?.toLowerCase());
    });

    this.condominiumFilterCtrl.valueChanges.subscribe(() => {
      FilterByField()(this.filteredCondominia, this.data.condominia, 'name', this.condominiumFilterCtrl.value?.toLowerCase());
    });

    this.ownerFilterCtrl.valueChanges.subscribe(() => {
      FilterByField()(this.filteredOwners, this.data.owners, 'name', this.ownerFilterCtrl.value?.toLowerCase());
    });
  }
}
