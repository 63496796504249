import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvitationService } from './services/invitation.service';
import { InvitationComponent } from './components/invitation/invitation.component';
import { InvitationModalComponent } from './components/invitation-modal/invitation-modal.component';
import { XTranslateModule } from '../x-translate/translate.module';
import { SharedModule } from '../shared/shared.module';
import { SendInvitationModalComponent } from './components/send-invitation-modal/send-invitation-modal.component';

@NgModule({
  declarations: [InvitationComponent, InvitationModalComponent, SendInvitationModalComponent],
  imports: [CommonModule, XTranslateModule, SharedModule],
  providers: [InvitationService],
  exports: [InvitationComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class InvitationModule {}
