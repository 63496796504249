import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OverlayService } from './services/overlay.service';
import { OverlayModule } from '@angular/cdk/overlay';
import { ProgressSpinnerComponent } from './componets/progress-spinner/progress-spinner.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [ProgressSpinnerComponent],
  imports: [CommonModule, OverlayModule, SharedModule],
  exports: [ProgressSpinnerComponent],
  providers: [OverlayService],
})
export class LoadSpinnerModule {}
