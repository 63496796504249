<div>
  <div class="page-header page-header-control-panel">
    <h2>
      <mat-icon>edit</mat-icon>
      {{ 'controlPanel.header' | translate }}
    </h2>
  </div>
  <div class="card main-card">
    <div class="tab-container">
      <mat-tab-group (selectedTabChange)="tabChanged($event)" [(selectedIndex)]="tabIndex" mat-stretch-tabs>
        <mat-tab *ngIf="loginService.userHasPermissions('Super_Admin')" [label]="'role.header' | translate">
          <xkey-role></xkey-role>
        </mat-tab>
        <mat-tab *ngIf="loginService.userHasPermissions('Super_Admin')" [label]="'permission.header' | translate">
          <xkey-permission></xkey-permission>
        </mat-tab>
        <mat-tab [label]="'lockType.header' | translate">
          <xkey-lock-type></xkey-lock-type>
        </mat-tab>
        <mat-tab [label]="'keyType.header' | translate">
          <xkey-key-type></xkey-key-type>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
