import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class NominatimService {
  constructor(private http: HttpClient) {}

  getAddressInfo(street: string, postalCode: string, city: string) {
    const baseUrl = 'https://nominatim.openstreetmap.org/search';
    const params = new HttpParams({
      fromObject: {
        street: street,
        city: city,
        postalcode: postalCode,
        format: 'json',
        addressdetails: '1',
        limit: '1',
        polygon_svg: '1',
      },
    });

    return this.http.get(baseUrl, { params });
  }
}
