<ng-container>
  <div class="modal-header">
    <h2 class="modal-title" mat-dialog-title>{{ data.key.id ? ('key.edit' | translate) : ('key.create' | translate) }}</h2>
  </div>
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="form">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'key.stamp' | translate }}</mat-label>
        <input formControlName="stamp" matInput placeholder="{{ 'key.stamp' | translate }}" />
        <mat-error *ngIf="f.stamp.errors && f.stamp.errors.required">{{ 'key.stampIsRequired' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'key.lockType' | translate }}</mat-label>
        <mat-select [formControl]="lockTypeCtrl" placeholder="{{ 'key.lockType' | translate }}">
          <mat-option>
            <ngx-mat-select-search [formControl]="lockTypeFilterCtrl" [noEntriesFoundLabel]="'general.noEntriesFound' | translate" [placeholderLabel]="'general.selectSearch' | translate"></ngx-mat-select-search>
          </mat-option>
          <mat-option></mat-option>
          <mat-option *ngFor="let lockType of filteredLockTypes | async" [value]="lockType.id">
            {{ lockType.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'key.keyType' | translate }}</mat-label>
        <mat-select (selectionChange)="keyTypeChanged($event)" [formControl]="keyTypeCtrl" placeholder="{{ 'key.keyType' | translate }}">
          <mat-option>
            <ngx-mat-select-search [formControl]="keyTypeFilterCtrl" [noEntriesFoundLabel]="'general.noEntriesFound' | translate" [placeholderLabel]="'general.selectSearch' | translate"></ngx-mat-select-search>
          </mat-option>
          <mat-option></mat-option>
          <mat-option *ngFor="let keyType of filteredKeyTypes | async" [value]="keyType.id">
            {{ keyType.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="f.type.errors && f.type.errors.required">{{ 'key.typeIsRequired' | translate }}</mat-error>
      </mat-form-field>

      <div *ngIf="f.type.value && isGeneralKey(f.type.value)">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'key.condominium' | translate }}</mat-label>
          <mat-select [formControl]="condominiumCtrl" placeholder="{{ 'key.condominium' | translate }}">
            <mat-option>
              <ngx-mat-select-search [formControl]="condominiumFilterCtrl" [noEntriesFoundLabel]="'general.noEntriesFound' | translate" [placeholderLabel]="'general.selectSearch' | translate"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let condominium of filteredCondominia | async" [value]="condominium.id">
              {{ condominium.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="f.condominium.errors && f.condominium.errors.required">{{ 'key.condominiumIsRequired' | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="f.type.value && !isGeneralKey(f.type.value)">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'key.apartment' | translate }}</mat-label>
          <mat-select [formControl]="apartmentCtrl" placeholder="{{ 'key.apartment' | translate }}">
            <mat-option>
              <ngx-mat-select-search [formControl]="apartmentFilterCtrl" [noEntriesFoundLabel]="'general.noEntriesFound' | translate" [placeholderLabel]="'general.selectSearch' | translate"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let apartment of filteredApartments | async" [value]="apartment.id">
              {{ apartment.fullAddress }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="f.apartment.errors && f.apartment.errors.required">{{ 'key.apartmentIsRequired' | translate }}</mat-error>
        </mat-form-field>
      </div>

      <mat-form-field *ngIf="!data.key.id" appearance="fill">
        <mat-label>{{ 'key.amount' | translate }}</mat-label>
        <input formControlName="amount" matInput placeholder="{{ 'key.amount' | translate }}" type="number" />
        <mat-error *ngIf="f.amount.errors && f.amount.errors.required">{{ 'key.amountIsRequired' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'key.owner' | translate }}</mat-label>
        <mat-select [formControl]="ownerCtrl" placeholder="{{ 'key.owner' | translate }}">
          <mat-option>
            <ngx-mat-select-search [formControl]="ownerFilterCtrl" [noEntriesFoundLabel]="'general.noEntriesFound' | translate" [placeholderLabel]="'general.selectSearch' | translate"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let owner of filteredOwners | async" [value]="owner.id">
            {{ owner.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button [mat-dialog-close]="false" color="primary" mat-flat-button>{{ 'general.close' | translate }}</button>
    <button (click)="save()" color="accent" mat-flat-button>{{ 'general.save' | translate }}</button>
  </mat-dialog-actions>
</ng-container>
