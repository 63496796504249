import { Injectable } from '@angular/core';
import { LoginService } from '../../auth/services/login.service';
import { GeneralService } from '../../../services/general.service';
import { Condominium } from '../../../models/condominium';
import { CondominiumFile } from '../../../models/condominiumfile';
import { environment } from '../../../../environments/environment';
import { debounceTime, filter, map, Observable, switchMap } from 'rxjs';
import { Apartment } from '../../../models/apartment';
import { Key } from '../../../models/key';
import axios from 'axios';

@Injectable({
  providedIn: 'root',
})
export class CondominiumService {
  constructor(
    private loginService: LoginService,
    private general: GeneralService
  ) {}

  getCondominia(): Observable<Condominium[]> {
    return this.general.get('condominia');
  }

  getCondominium(id): Observable<Condominium> {
    return this.general.get('condominia/' + id);
  }

  getCondominiumApartments(id): Observable<Apartment[]> {
    return this.general.get('condominia/' + id + '/apartments');
  }

  deleteCondominium(id): Observable<void> {
    return this.general.delete('condominia/' + id);
  }

  updateCondominium(condominium: Condominium): Observable<Condominium> {
    return this.general.update('condominia', condominium);
  }

  createCondominium(condominium: Condominium): Observable<Condominium> {
    return this.general.post('condominia', condominium);
  }

  getCondominiumKeys(id: number, allKeys: boolean): Observable<Key[]> {
    return this.general.get<Key[]>('condominia/' + id + '/keys/' + (allKeys ? 'all' : '')).pipe(map((keys) => keys.map((k) => new Key(k))));
  }

  getCondominiumFiles(id: number): Observable<CondominiumFile[]> {
    return this.general.get('condominia/' + id + '/files');
  }

  sendCondominiumFile(condominium: Condominium, formData: FormData) {
    return this.general.post('condominia/' + condominium.id + '/files', formData);
  }

  getCondominiumFile(condominium: number, condominiumFile: CondominiumFile) {
    const token = this.loginService.getToken();

    return axios({
      url: environment.serverUrls[window.location.hostname] + 'condominia/' + condominium + '/files/' + condominiumFile.id,
      method: 'GET',
      responseType: 'blob',
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  }

  deleteCondominiumFile(condominium: number, condominiumFile: number): Observable<void> {
    return this.general.delete('condominia/' + condominium + '/files/' + condominiumFile);
  }

  upsertCondominium(condominium: Condominium): Observable<Condominium> {
    return condominium.id ? this.updateCondominium(condominium) : this.createCondominium(condominium);
  }

  transferCondominium(condominiumId: number, owner: number) {
    return this.general.get('condominia/' + condominiumId + '/transfer/' + owner);
  }

  search(terms: Observable<string>) {
    return terms.pipe(
      filter(Boolean),
      debounceTime(500),
      switchMap((term: string) => this.general.post('condominia/search', { searchPhrase: term }))
    );
  }
}
