<ng-container>
  <div class="modal-header">
    <h2 class="modal-title" mat-dialog-title>{{ data.announcement?.id ? ('announcement.edit' | translate) : ('announcement.create' | translate) }}</h2>
  </div>
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="form">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'announcement.title' | translate }}</mat-label>
        <input formControlName="title" matInput placeholder="{{ 'announcement.title' | translate }}" />
        <mat-error *ngIf="f.title.errors && f.title.errors.required">{{ 'announcement.titleIsRequired' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'announcement.message' | translate }}</mat-label>
        <textarea formControlName="message" matInput placeholder="{{ 'announcement.message' | translate }}" rows="5"></textarea>
        <mat-error *ngIf="f.message.errors && f.message.errors.required">{{ 'announcement.messageIsRequired' | translate }}</mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button [mat-dialog-close]="false" color="primary" mat-flat-button>{{ 'general.close' | translate }}</button>
    <button (click)="save()" color="accent" mat-flat-button>{{ 'general.save' | translate }}</button>
  </mat-dialog-actions>
</ng-container>
