<div class="card my-2 px-3">
  <h2>{{ 'condominium.files' | translate }}</h2>

  <div class="datatable">
    <ng-template #desktopTable>
      <mat-table [dataSource]="dataSource" [matSortDirection]="'asc'" matSort matSortActive="name" matSortDisableClear>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'condominium.fileName' | translate }}</mat-header-cell>
          <mat-cell (click)="getCondominiumFile(row)" *matCellDef="let row" class="link">{{ row.name }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'condominium.fileCreated' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.createdAt | date: 'dd.MM.y HH:mm:ss' }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>{{ 'general.actions' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button (click)="openDeleteFileModal(row.id)" [disabled]="!loginService.userHasPermissions('Condominium_Delete')" [matTooltip]="'general.delete' | translate" class="mx-1" color="warn" mat-flat-button>
              <mat-icon>delete</mat-icon>
              {{ 'general.delete' | translate }}
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsDesktop"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsDesktop"></mat-row>
      </mat-table>
    </ng-template>

    <ng-template #mobileTable>
      <mat-table [dataSource]="dataSource" [matSortDirection]="'asc'" matSort matSortActive="name" matSortDisableClear multiTemplateDataRows>
        <ng-container matColumnDef="arrow">
          <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-icon>{{ expandedElement && expandedElement.id === row.id ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}</mat-icon>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'condominium.name' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.name }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="open">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell (click)="getCondominiumFile(row)" *matCellDef="let row">
            <mat-icon>open_in_new</mat-icon>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td *matCellDef="let element" [attr.colspan]="displayedColumnsMobile.length" mat-cell>
            <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'" class="element-detail">
              <xkey-basic-info-field [title]="'condominium.fileCreated' | translate" [value]="element.createdAt | date: 'dd.MM.y HH:mm:ss'" />
              <div class="detail-buttons">
                <button (click)="openDeleteFileModal(element)" [disabled]="!loginService.userHasPermissions('Condominium_Delete')" class="block-on-mobile" color="warn" mat-flat-button>
                  <mat-icon>delete</mat-icon>
                  {{ 'general.delete' | translate }}
                </button>
              </div>
            </div>
          </td>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsMobile"></mat-header-row>
        <mat-row (click)="expandedElement = expandedElement === element ? null : element" *matRowDef="let element; columns: displayedColumnsMobile" [class.expanded-row]="expandedElement === element" class="element-row"></mat-row>

        <tr
          *matRowDef="let row; columns: ['expandedDetail']"
          [ngClass]="{
            'expanded-detail': expandedElement && expandedElement.id == row.id,
            'closed-detail': !(expandedElement && expandedElement.id == row.id)
          }"
          class="detail-row"
          mat-row
        ></tr>
      </mat-table>
    </ng-template>

    <ng-container *ngIf="handsetObserverService.isHandSet$ | async">
      <ng-template [ngTemplateOutlet]="mobileTable"></ng-template>
    </ng-container>

    <ng-container *ngIf="!(handsetObserverService.isHandSet$ | async)">
      <ng-template [ngTemplateOutlet]="desktopTable"></ng-template>
    </ng-container>

    <mat-paginator #paginator [length]="dataSource.data.length" [pageIndex]="0" [pageSizeOptions]="[5, 25, 50, 100, 250]" [pageSize]="5"> </mat-paginator>
  </div>

  <ngx-file-drop
    (onFileDrop)="dropped($event)"
    *ngIf="loginService.userHasPermissions('Condominium_Create')"
    [browseBtnLabel]="'condominium.fileBrowse' | translate"
    [dropZoneLabel]="'condominium.fileTooltip' | translate"
    [showBrowseBtn]="true"
    browseBtnClassName="btn btn-primary btn-browse"
    class="mt-4"
  ></ngx-file-drop>
</div>
