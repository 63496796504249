import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from '../../../../models/company';
import { CompanyService } from '../../services/company.service';
import { LoginService } from '../../../auth/services/login.service';
import { OpenersService } from '../../../ui/services/openers.service';
import { CompanyModalComponent } from '../company-modal/company-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { Location } from '@angular/common';
import { DialogService } from '../../../ui/services/dialog.service';
import { ConfirmModalComponent } from '../../../ui/components/confirm-modal/confirm-modal.component';
import { HandsetObserverService } from '../../../ui/services/handset-observer.service';
import { SnackService } from '../../../ui/services/snack.service';

@Component({
  selector: 'xkey-company-single',
  templateUrl: './company-single.component.html',
  styleUrls: ['./company-single.component.scss'],
})
export class CompanySingleComponent implements OnInit {
  selectedCompany: Company;
  tabIndex = 0;

  constructor(
    public loginService: LoginService,
    public openers: OpenersService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private companyService: CompanyService,
    private dialogService: DialogService,
    public handsetObserverService: HandsetObserverService,
    private snackService: SnackService
  ) {
    this.route.params.subscribe(async (params) => {
      await this.setupComponent(params['id']);
    });
  }

  ngOnInit() {
    this.tabIndex = parseInt(this.activatedRoute.snapshot.paramMap.get('tab'), 10);
  }

  openModifyModal(company: Company) {
    this.dialogService.showDialog(CompanyModalComponent, { company }, async () => {
      await this.setupComponent(this.selectedCompany.id);
    });
  }

  openDeleteModal(companyId: number) {
    this.dialogService.showDialog(ConfirmModalComponent, { modalClass: 'modal-company', title: this.translate.instant('company.deleteCompany') }, () => {
      this.companyService.deleteCompany(companyId).subscribe(async () => {
        await this.router.navigate(['/yritykset']);
      });
    });
  }

  tabChanged(event) {
    this.tabIndex = event.index;
    this.location.go('/yritykset/' + this.selectedCompany.id + '/' + this.tabIndex);
  }

  copyKeyAgreementToSubCompanies() {
    this.dialogService.showDialog(ConfirmModalComponent, { modalClass: 'modal-company', title: this.translate.instant('company.confirmCopyKeyAgreement') }, () => {
      this.companyService.copyKeyAgreementToSubCompanies(this.selectedCompany.id).subscribe(async () => {
        await this.setupComponent(this.selectedCompany.id);
        this.snackService.showMessage('company.keyAgreementCopiedSuccessfully');
      });
    });
  }

  private async setupComponent(companyId: number) {
    this.selectedCompany = <Company>await lastValueFrom(this.companyService.getCompany(companyId));
  }
}
