<div *ngIf="currentUser">
  <div class="page-header">
    <h2>
      <mat-icon>contact_page</mat-icon>
      {{ 'general.myPage' | translate }}
    </h2>
  </div>

  <div class="card main-card">
    <div *ngIf="!(handsetObserverService.isSmallScreen$ | async)" class="buttons">
      <ng-container *ngTemplateOutlet="buttons"></ng-container>
    </div>

    <mat-accordion *ngIf="handsetObserverService.isSmallScreen$ | async">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'general.actions' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="buttons"></ng-container>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-tab-group (selectedTabChange)="tabChanged($event)" [(selectedIndex)]="tabIndex" mat-stretch-tabs>
      <mat-tab *ngIf="loginService.userHasPermissions('Apartment_Read')" [label]="'general.basicInformation' | translate">
        <ng-template matTabContent>
          <div class="card basic-info">
            <div class="row">
              <div class="col-sm-6">
                <xkey-basic-info-field [title]="'user.name'" [value]="currentUser.firstName + ' ' + currentUser.lastName"></xkey-basic-info-field>
                <xkey-basic-info-field [title]="'user.email'" [value]="currentUser.email"></xkey-basic-info-field>
                <xkey-basic-info-field [title]="'user.phone'" [value]="currentUser.phone"></xkey-basic-info-field>
              </div>
              <div class="col-sm-6">
                <xkey-basic-info-field (click)="openers.openCompany(currentUser.company)" [isClickable]="true" [title]="'user.company'" [value]="company.name"></xkey-basic-info-field>
                <xkey-basic-info-field [title]="'user.role'" [value]="currentUser.role?.name"></xkey-basic-info-field>
              </div>
            </div>

            <hr />

            <div *ngIf="settings" class="row">
              <h2>{{ 'user.settings' | translate }}</h2>
              <mat-slide-toggle (click)="updateSettings()" [(ngModel)]="settings.showMyCondominia" class="my-2">{{ 'user.showMyCondominia' | translate }}</mat-slide-toggle>
              <mat-slide-toggle (click)="updateSettings()" [(ngModel)]="settings.showMyKeys" class="my-2">{{ 'user.showMyKeys' | translate }}</mat-slide-toggle>
              <mat-slide-toggle (click)="updateSettings()" [(ngModel)]="settings.showMyCompanysKeys" class="my-2">{{ 'user.showMyCompanysKeys' | translate }}</mat-slide-toggle>
              <mat-slide-toggle (click)="updateSettings()" [(ngModel)]="settings.showLateKeys" class="my-2">{{ 'user.showLateKeys' | translate }}</mat-slide-toggle>
              <mat-slide-toggle (click)="updateSettings()" [(ngModel)]="settings.showMissingKeys" class="my-2">{{ 'user.showMissingKeys' | translate }}</mat-slide-toggle>
            </div>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab *ngIf="loginService.userHasPermissions('Condominium_Read')" [label]="'condominium.header' | translate">
        <xkey-condominium [user]="currentUser"></xkey-condominium>
      </mat-tab>
      <mat-tab *ngIf="loginService.userHasPermissions('Key_Read')" [label]="'key.header' | translate">
        <xkey-key [user]="currentUser"></xkey-key>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<ng-template #buttons>
  <button (click)="openModifyModal()" class="block-on-mobile" color="accent" mat-flat-button>
    <mat-icon>edit</mat-icon>
    {{ 'general.edit' | translate }}
  </button>
  <button (click)="openChangePasswordModal()" class="block-on-mobile" color="primary" mat-flat-button>
    <mat-icon>edit</mat-icon>
    {{ 'user.changePassword' | translate }}
  </button>
</ng-template>
