<ng-container>
  <div class="modal-header">
    <h2 class="modal-title" mat-dialog-title>{{ data.permission.id ? ('permission.edit' | translate) : ('permission.create' | translate) }}</h2>
  </div>
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="form">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'permission.name' | translate }}</mat-label>
        <input formControlName="name" matInput placeholder="{{ 'permission.name' | translate }}" />
        <mat-error *ngIf="f.name.errors && f.name.errors.required">{{ 'permission.nameIsRequired' | translate }}</mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button [mat-dialog-close]="false" color="primary" mat-flat-button>{{ 'general.close' | translate }}</button>
    <button (click)="save()" color="accent" mat-flat-button>{{ 'general.save' | translate }}</button>
  </mat-dialog-actions>
</ng-container>
