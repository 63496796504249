<div [hidden]="fromSearch && users.length === 0" [ngClass]="{ 'from-search': fromSearch && users.length > 0 }">
  <div *ngIf="!company" class="page-header page-header-users">
    <h2>
      <mat-icon>account_circle</mat-icon>
      {{ 'user.header' | translate }}
    </h2>
  </div>
  <div class="card main-card">
    <div class="datatable mt-2">
      <mat-form-field *ngIf="!fromSearch" [floatLabel]="'always'" appearance="outline" class="full-width-search mt-2">
        <mat-label>{{ 'general.search' | translate }}</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input (keyup)="applyFilter($event)" matInput />
      </mat-form-field>

      <ng-template #desktopTable>
        <mat-table [dataSource]="dataSource" [matSortDirection]="'asc'" matSort matSortActive="fullName" matSortDisableClear>
          <ng-container matColumnDef="fullName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'user.name' | translate }}</mat-header-cell>
            <mat-cell (click)="openers.openUser(row)" *matCellDef="let row" class="link">{{ row.fullName }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="phone">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'user.phone' | translate }}</mat-header-cell>
            <mat-cell (click)="openers.openUser(row)" *matCellDef="let row" class="link">{{ row.phone }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'user.email' | translate }}</mat-header-cell>
            <mat-cell (click)="openers.openUser(row)" *matCellDef="let row" class="link">{{ row.email }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="company">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'user.company' | translate }}</mat-header-cell>
            <mat-cell (click)="openers.openCompany(row)" *matCellDef="let row" class="link">{{ row.company.name }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="active">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'user.active' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <mat-icon>{{ row.active ? 'check' : 'clear' }}</mat-icon>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="open">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell (click)="openers.openUser(row)" *matCellDef="let row">
              <mat-icon>open_in_new</mat-icon>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsDesktop"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsDesktop"></mat-row>
        </mat-table>
      </ng-template>

      <ng-template #mobileTable>
        <mat-table [dataSource]="dataSource" [matSortDirection]="'asc'" matSort matSortActive="fullName" matSortDisableClear multiTemplateDataRows>
          <ng-container matColumnDef="arrow">
            <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
            <mat-cell *matCellDef="let row">
              <mat-icon>{{ expandedElement && expandedElement.id === row.id ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}</mat-icon>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="fullName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'user.name' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row.fullName }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="open">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell (click)="openers.openUser(row)" *matCellDef="let row">
              <mat-icon>open_in_new</mat-icon>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td *matCellDef="let element" [attr.colspan]="displayedColumnsMobile.length" mat-cell>
              <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'" class="element-detail">
                <xkey-basic-info-field [title]="'user.email'" [value]="element.email"></xkey-basic-info-field>
                <xkey-basic-info-field [title]="'user.phone'" [value]="element.phone"></xkey-basic-info-field>
                <xkey-basic-info-field (click)="openers.openCompany(element.company)" [isClickable]="true" [title]="'user.company'" [value]="element.company?.name"></xkey-basic-info-field>
              </div>
            </td>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsMobile"></mat-header-row>
          <mat-row (click)="expandedElement = expandedElement === element ? null : element" *matRowDef="let element; columns: displayedColumnsMobile" [class.expanded-row]="expandedElement === element" class="element-row"></mat-row>

          <tr
            *matRowDef="let row; columns: ['expandedDetail']"
            [ngClass]="{
              'expanded-detail': expandedElement && expandedElement.id == row.id,
              'closed-detail': !(expandedElement && expandedElement.id == row.id)
            }"
            class="detail-row"
            mat-row
          ></tr>
        </mat-table>
      </ng-template>

      <ng-container *ngIf="handsetObserverService.isHandSet$ | async">
        <ng-template [ngTemplateOutlet]="mobileTable"></ng-template>
      </ng-container>

      <ng-container *ngIf="!(handsetObserverService.isHandSet$ | async)">
        <ng-template [ngTemplateOutlet]="desktopTable"></ng-template>
      </ng-container>

      <div *ngIf="users.length === 0" class="no-results">
        {{ 'general.noResults' | translate }}
      </div>

      <mat-paginator #paginator [length]="dataSource.data.length" [pageIndex]="0" [pageSizeOptions]="[25, 50, 100, 250]" [pageSize]="50"> </mat-paginator>
    </div>
  </div>
</div>
