import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../user/services/user.service';
import { UserSettings } from '../../../../models/userSettings';
import { NgxMasonryComponent, NgxMasonryOptions } from 'ngx-masonry';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '../../../ui/services/loading.service';
import { LoginService } from '../../../auth/services/login.service';
import { SnackService } from '../../../ui/services/snack.service';

@Component({
  selector: 'xkey-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  @ViewChild(NgxMasonryComponent) masonry: NgxMasonryComponent;
  public masonryOptions: NgxMasonryOptions = {
    horizontalOrder: true,
  };

  userSettings: UserSettings = new UserSettings();

  constructor(
    private userService: UserService,
    private translate: TranslateService,
    private snackService: SnackService,
    private loadingService: LoadingService,
    public loginService: LoginService
  ) {}

  @HostListener('click')
  clicked() {
    this.reloadLayout();
  }

  ngOnInit() {
    this.userService.getCurrentUserSettings().subscribe({
      next: (settings: UserSettings) => {
        this.userSettings = settings;
      },
      error: (error) => {
        console.error(error);
        this.snackService.showGeneralError();
      },
    });

    this.loadingService.isLoading$.subscribe((isLoading: boolean) => {
      if (!isLoading && this.masonry) {
        this.reloadLayout();
      }
    });
  }

  reloadLayout() {
    setTimeout(() => {
      this.masonry.reloadItems();
      this.masonry.layout();
    }, 200);
  }
}
