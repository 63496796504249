import { Component, OnInit, ViewChild } from '@angular/core';
import { RoleService } from '../../services/role.service';
import { Role } from '../../../../models/role';
import { PermissionService } from '../../services/permission.service';
import { LoginService } from '../../../auth/services/login.service';
import { ConfirmModalComponent } from '../../../ui/components/confirm-modal/confirm-modal.component';
import { Permission } from '../../../../models/permission';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { lastValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HandsetObserverService } from '../../../ui/services/handset-observer.service';
import { AnimationTriggerMetadata } from '../../../../helpers/AnimationTriggerMetadata';
import { RoleModalComponent } from '../role-modal/role-modal.component';
import { DialogService } from '../../../ui/services/dialog.service';

@Component({
  selector: 'xkey-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss'],
  animations: [AnimationTriggerMetadata()],
})
export class RoleComponent implements OnInit {
  roles: Role[] = [];
  dataSource = new MatTableDataSource<Role>([]);
  displayedColumnsDesktop = ['name', 'permissions', 'actions'];
  displayedColumnsMobile = ['arrow', 'name'];
  expandedElement: Role | null;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private roleService: RoleService,
    private permissionService: PermissionService,
    public handsetObserverService: HandsetObserverService,
    private translate: TranslateService,
    public loginService: LoginService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.getRoles();
  }

  async openCreateModifyModal(role?: Role) {
    const permissions: Permission[] = await lastValueFrom(this.permissionService.getPermissions());
    this.dialogService.showDialog(RoleModalComponent, { permissions, role }, () => {
      this.getRoles();
    });
  }

  openDeleteModal(roleId: number) {
    this.dialogService.showDialog(ConfirmModalComponent, { modalClass: 'modal-control-panel', title: this.translate.instant('role.deleteRole') }, () => {
      this.roleService.deleteRole(roleId).subscribe(() => {
        this.getRoles();
      });
    });
  }

  applyFilter($event: KeyboardEvent) {
    const filterValue = ($event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getPermissions(permissions: Permission[]) {
    return permissions.map((p) => p.name).join(', ');
  }

  private getRoles() {
    this.roleService.getRoles().subscribe((roles: [Role]) => {
      this.roles = roles;

      this.dataSource = new MatTableDataSource(this.roles);
      this.dataSource.filter = '';
      this.dataSource.paginator = this.paginator;
      this.dataSource.filterPredicate = (data: any, filter) => {
        const dataStr = JSON.stringify(data).toLowerCase();
        return dataStr.indexOf(filter) !== -1;
      };
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'permissions': {
            return this.getPermissions(item.permissions);
          }
          default:
            return item[property];
        }
      };
      this.dataSource.sort = this.sort;
    });
  }
}
