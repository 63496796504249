import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class SnackService {
  constructor(
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  public showMessage(message: string) {
    this.snackBar.open(this.translate.instant(message), this.translate.instant('general.close'), {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  public showGeneralError() {
    this.snackBar.open(this.translate.instant('general.error'), this.translate.instant('general.close'), {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}
