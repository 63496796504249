import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { XTranslateModule } from '../x-translate/translate.module';
import { BasicInfoFieldComponent } from './components/basic-info-field/basic-info-field.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { GoBackButtonComponent } from './components/go-back-button/go-back-button.component';
import { MapComponent } from './components/map/map.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HandsetObserverService } from './services/handset-observer.service';
import { OpenersService } from './services/openers.service';
import { NominatimService } from './services/nominatim.service';
import { LoadingService } from './services/loading.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingInterceptor } from './interceptors/load.interceptor';
import { RoutingModule } from '../routing/routing.module';
import { VersionService } from './services/version.service';
import { SnackService } from './services/snack.service';
import { DialogService } from './services/dialog.service';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { environment } from '../../../environments/environment';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.domain.replace('https://', '').replace('http://', ''),
  },
  position: 'bottom',
  theme: 'edgeless',
  palette: {
    popup: {
      background: '#025174',
      text: '#FAF8F6',
    },
    button: {
      background: '#607D8B',
      text: '#FAF8F6',
    },
  },
  type: 'info',
  content: {
    message: 'Sivusto käyttää evästeitä parhaan käyttökokemuksen saavuttamiseksi.',
    dismiss: 'Selvä!',
    deny: 'Kiellä',
    link: 'Lue lisää',
    href: '/tietosuojaseloste',
    policy: 'Evästeiden käyttö',
  },
};

@NgModule({
  declarations: [BasicInfoFieldComponent, ConfirmModalComponent, GoBackButtonComponent, MapComponent, NavbarComponent],
  imports: [CommonModule, SharedModule, XTranslateModule, RoutingModule, NgcCookieConsentModule.forRoot(cookieConfig), NgOptimizedImage],
  providers: [HandsetObserverService, OpenersService, NominatimService, LoadingService, VersionService, SnackService, DialogService, { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true }],
  exports: [GoBackButtonComponent, BasicInfoFieldComponent, MapComponent, NavbarComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UiModule {}
