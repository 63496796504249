import { Injectable } from '@angular/core';
import { GeneralService } from '../../../services/general.service';
import { Observable } from 'rxjs';
import { Customisations } from '../../../models/customisations';

@Injectable({
  providedIn: 'root',
})
export class CustomisationsService {
  constructor(private general: GeneralService) {}

  getCustomisations(id?: number): Observable<Customisations> {
    return this.general.get(`customisations`);
  }
}
